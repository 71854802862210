import React from "react";
import { motion } from 'framer-motion';
import {
  isSafari,
  isFirefox,
  isIE,
  isOpera,
  isChrome
} from "react-device-detect";

let easing = [0.1575, 0.85, 0.42, 0.96];

const getRandomDuration = () => {
  return Math.floor(Math.random() * 3) + 2
}

const getRandomDelay = () => {
  return Math.floor(Math.random() * 6) + 2
}

const getSlideTopAnimation = (duration) => {
  return {
    enter: { x: 0, y: 0, opacity: 1, transition: { duration: duration, ease: easing } },
    exit: { x: 0, y: 50, opacity: 0, transition: { duration: duration, ease: easing } }
  }
}

const lightsAnimation = (getRandomDuration, getRandomDelay) => {
  const duration = getRandomDuration();
  const delay = getRandomDelay();

  return {
    enter: { fill: "#E1A169", opacity: 1, transition: { duration: duration, ease: easing, delay: delay } },
    exit: { fill: "#3A5070", opacity: 1, transition: { duration: duration, ease: easing, delay: delay } }
  }
}

const fadeIn = () => {
  return {
    enter: { x: 0, y: 0, opacity: 1, transition: { duration: 1, ease: easing } },
    exit: { x: 0, y: 0, opacity: 0, transition: { duration: 1, ease: easing } }
  }
}

const Background = () => {
  return (
    <motion.svg initial="exit" animate="enter" exit="exit" className="fullscreen-svg" preserveAspectRatio="xMinYMin slice" viewBox="0 0 1928 1080" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
        <polygon id="path-1" points="1928 0 0 0 0 1080 1928 1080"></polygon>
        <linearGradient x1="32.9947109%" y1="64.361779%" x2="67.7567953%" y2="34.9904885%" id="linearGradient-3">
            <stop stop-color="#25054D" offset="0%"></stop>
            <stop stop-color="#45108A" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="139.312853%" y1="4.34938415%" x2="44.4826914%" y2="52.6861044%" id="linearGradient-4">
            <stop stop-color="#FFBA78" offset="3.53535%"></stop>
            <stop stop-color="#FFC987" offset="39.5%"></stop>
            <stop stop-color="#FEECA8" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="139.321902%" y1="4.34766302%" x2="44.4855807%" y2="52.6877538%" id="linearGradient-5">
            <stop stop-color="#FFBA78" offset="3.53535%"></stop>
            <stop stop-color="#FFC987" offset="39.5%"></stop>
            <stop stop-color="#FEECA8" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="139.312853%" y1="4.35359286%" x2="44.4826914%" y2="52.6874047%" id="linearGradient-6">
            <stop stop-color="#FFBA78" offset="3.53535%"></stop>
            <stop stop-color="#FFC987" offset="39.5%"></stop>
            <stop stop-color="#FEECA8" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="139.312853%" y1="4.34938415%" x2="44.4826914%" y2="52.6893942%" id="linearGradient-7">
            <stop stop-color="#FFBA78" offset="3.53535%"></stop>
            <stop stop-color="#FFC987" offset="39.5%"></stop>
            <stop stop-color="#FEECA8" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="32.9945737%" y1="64.3616566%" x2="67.7567278%" y2="34.9902317%" id="linearGradient-8">
            <stop stop-color="#25054D" offset="0%"></stop>
            <stop stop-color="#45108A" offset="100%"></stop>
        </linearGradient>
    </defs>
    <motion.g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1"></use>
        </mask>
        <g id="bg-inner"></g>
        <polygon id="bg" fill="#1D2F53" fill-rule="nonzero" mask="url(#mask-2)" points="1928 0 0 0 0 1080.00266 1928 1080.00266"></polygon>
        <motion.g variants={getSlideTopAnimation(Math.floor(Math.random() * 2) + 1.1)} id="block-12" mask="url(#mask-2)" fill-rule="nonzero">
            <g transform="translate(1332.983400, 30.391000)" id="Path">
                <polygon fill="#12233D" points="139.37 662.849 280.74 581.567 280.74 137.069 139.37 218.35"></polygon>
                <polygon fill="url(#linearGradient-3)" points="1.13686838e-13 581.567 139.47 662.849 139.47 218.35 1.13686838e-13 137.169"></polygon>
                <polygon fill="#2A3B56" points="1.13686838e-13 581.567 139.47 662.849 139.47 218.35 1.13686838e-13 137.169"></polygon>
                <polygon fill="#3A5070" points="3.41060513e-13 137.169 139.47 218.35 280.74 137.069 141.17 55.6873"></polygon>
                <polygon fill="#12233D" points="139.57 200.854 248.84 138.068 248.84 104.976 139.57 167.862"></polygon>
                <polygon fill="#2A3B56" points="31.89 138.069 139.67 200.854 139.67 167.862 31.89 105.076"></polygon>
                <polygon fill="#3A5070" points="31.89 105.076 139.67 167.862 248.84 104.976 140.97 42.1904"></polygon>
                <polygon fill="#12233D" points="139.77 150.066 216.65 105.876 216.65 73.883 139.77 118.073"></polygon>
                <polygon fill="#2A3B56" points="64.08 105.876 139.87 150.066 139.87 118.073 64.08 73.983"></polygon>
                <polygon fill="#3A5070" points="64.08 73.983 139.87 118.073 216.65 73.883 140.77 29.7932"></polygon>
                <polygon fill="#12233D" points="140.07 100.677 188.06 73.083 188.06 41.3905 140.07 69.0842"></polygon>
                <polygon fill="#2A3B56" points="92.68 73.083 140.07 100.677 140.07 69.0842 92.68 41.4905"></polygon>
                <polygon fill="#3A5070" points="92.68 41.4905 140.07 69.0842 188.06 41.3905 140.67 13.7968"></polygon>
                <polygon fill="#12233D" points="80.78 660.449 107.48 645.753 107.48 239.445 80.78 254.142"></polygon>
                <polygon fill="#2A3B56" points="12.9 620.859 80.88 660.449 80.88 254.142 12.9 214.551"></polygon>
                <polygon fill="#3A5070" points="12.9 214.551 80.88 254.142 107.47 239.445 39.49 199.854"></polygon>
                <polygon fill="#2A3B56" points="199.95 660.449 173.26 645.753 173.26 239.445 199.95 254.142"></polygon>
                <polygon fill="#12233D" points="267.84 620.858 199.85 660.449 199.85 254.142 267.84 214.551"></polygon>
                <polygon fill="#3A5070" points="267.84 214.551 199.85 254.142 173.26 239.445 241.24 199.854"></polygon>
                <polygon fill="#1D2F52" points="250.14 240.845 259.34 235.546 259.34 625.557 250.14 630.856"></polygon>
                <polygon fill="#1D2F52" points="230.75 252.342 239.84 247.044 239.84 636.955 230.75 642.353"></polygon>
                <polygon fill="#1D2F52" points="211.25 263.74 220.45 258.441 220.45 648.452 211.25 653.751"></polygon>
                <polygon fill="#3A5070" points="30.59 240.845 21.49 235.546 21.49 625.557 30.59 630.856"></polygon>
                <polygon fill="#3A5070" points="50.09 252.342 40.89 247.044 40.89 636.955 50.09 642.353"></polygon>
                <polygon fill="#3A5070" points="69.48 263.74 60.39 258.441 60.39 648.452 69.48 653.751"></polygon>
                <polygon fill="#12233D" points="140.07 -3.90798505e-14 140.07 50.0886 155.66 41.0906"></polygon>
                <polygon fill="#2A3B56" points="140.07 -3.90798505e-14 140.07 50.0886 124.77 41.0906"></polygon>
            </g>
        </motion.g>
        <motion.g variants={getSlideTopAnimation(Math.floor(Math.random() * 2) + 1.1)} id="block-14" mask="url(#mask-2)" fill-rule="nonzero">
            <g transform="translate(594.051400, 73.881000)" id="Path">
                <polygon fill="#12233D" points="64.685 512.183 206.053 430.802 206.053 68.084 64.685 149.366"></polygon>
                <polygon fill="#2A3B56" points="-5.68434189e-14 475.092 64.785 512.183 64.785 149.366 -5.68434189e-14 112.374"></polygon>
                <polygon fill="#3A5070" points="-5.68434189e-14 112.374 64.785 149.366 206.053 68.084 141.167 30.993"></polygon>
                <polygon fill="#12233D" points="132.57 565.571 290.234 474.892 290.234 41.291 132.57 131.97"></polygon>
                <polygon fill="#2A3B56" points="60.486 524.181 132.669 565.571 132.669 131.97 60.486 90.679"></polygon>
                <polygon fill="#3A5070" points="60.486 90.679 132.669 131.97 290.234 41.291 217.85 1.42108547e-14"></polygon>
                <polygon fill="#12233D" points="208.552 596.264 349.92 514.982 349.92 152.165 208.552 233.447"></polygon>
                <polygon fill="#2A3B56" points="143.867 559.172 208.652 596.264 208.652 233.447 143.867 196.455"></polygon>
                <polygon fill="#3A5070" points="143.867 196.455 208.652 233.447 349.92 152.165 285.035 115.074"></polygon>
                <polygon fill="#1D2F52" points="244.144 243.145 236.946 247.344 236.946 239.045 244.144 234.846"></polygon>
                <polygon fill="#1D2F52" points="257.741 235.346 250.543 239.445 250.543 231.147 257.741 227.048"></polygon>
                <polygon fill="#1D2F52" points="271.338 227.448 264.039 231.647 264.039 223.349 271.338 219.15"></polygon>
                <polygon fill="#1D2F52" points="284.835 219.65 277.636 223.849 277.636 215.551 284.835 211.352"></polygon>
                <polygon fill="#1D2F52" points="298.432 211.852 291.233 215.951 291.233 207.653 298.432 203.554"></polygon>
                <polygon fill="#1D2F52" points="312.028 203.954 304.73 208.153 304.73 199.855 312.028 195.655"></polygon>
                <polygon fill="#1D2F52" points="325.525 196.155 318.327 200.354 318.327 192.056 325.525 187.857"></polygon>
                <polygon fill="#1D2F52" points="244.144 260.641 236.946 264.84 236.946 256.541 244.144 252.342"></polygon>
                <polygon fill="#1D2F52" points="257.741 252.842 250.543 257.041 250.543 248.743 257.741 244.544"></polygon>
                <polygon fill="#1D2F52" points="271.338 245.044 264.039 249.143 264.039 240.845 271.338 236.746"></polygon>
                <polygon fill="#1D2F52" points="284.835 237.146 277.636 241.345 277.636 233.047 284.835 228.848"></polygon>
                <polygon fill="#1D2F52" points="298.432 229.348 291.233 233.547 291.233 225.249 298.432 221.05"></polygon>
                <polygon fill="#1D2F52" points="312.028 221.55 304.73 225.649 304.73 217.35 312.028 213.251"></polygon>
                <polygon fill="#1D2F52" points="325.525 213.651 318.327 217.85 318.327 209.552 325.525 205.353"></polygon>
                <polygon fill="#1D2F52" points="244.144 278.237 236.946 282.336 236.946 274.038 244.144 269.938"></polygon>
                <polygon fill="#1D2F52" points="257.741 270.338 250.543 274.537 250.543 266.239 257.741 262.04"></polygon>
                <polygon fill="#1D2F52" points="271.338 262.54 264.039 266.739 264.039 258.441 271.338 254.242"></polygon>
                <polygon fill="#1D2F52" points="284.835 254.742 277.636 258.841 277.636 250.643 284.835 246.444"></polygon>
                <polygon fill="#1D2F52" points="298.432 246.844 291.233 251.043 291.233 242.745 298.432 238.646"></polygon>
                <polygon fill="#1D2F52" points="312.028 239.046 304.73 243.245 304.73 234.946 312.028 230.747"></polygon>
                <polygon fill="#1D2F52" points="325.525 231.247 318.327 235.346 318.327 227.148 325.525 222.949"></polygon>
                <polygon fill="#1D2F52" points="244.144 295.733 236.946 299.932 236.946 291.634 244.144 287.434"></polygon>
                <polygon fill="#1D2F52" points="257.741 287.934 250.543 292.033 250.543 283.835 257.741 279.636"></polygon>
                <polygon fill="#1D2F52" points="271.338 280.036 264.039 284.235 264.039 275.937 271.338 271.838"></polygon>
                <polygon fill="#1D2F52" points="284.835 272.238 277.636 276.437 277.636 268.139 284.835 263.94"></polygon>
                <polygon fill="#1D2F52" points="298.432 264.44 291.233 268.539 291.233 260.341 298.432 256.142"></polygon>
                <polygon fill="#1D2F52" points="312.028 256.542 304.73 260.741 304.73 252.442 312.028 248.343"></polygon>
                <polygon fill="#1D2F52" points="325.525 248.743 318.327 252.942 318.327 244.644 325.525 240.445"></polygon>
                <polygon fill="#1D2F52" points="244.144 313.229 236.946 317.428 236.946 309.13 244.144 305.03"></polygon>
                <polygon fill="#1D2F52" points="257.741 305.43 250.543 309.629 250.543 301.331 257.741 297.132"></polygon>
                <polygon fill="#1D2F52" points="271.338 297.632 264.039 301.731 264.039 293.533 271.338 289.334"></polygon>
                <polygon fill="#1D2F52" points="284.835 289.734 277.636 293.933 277.636 285.635 284.835 281.536"></polygon>
                <polygon fill="#1D2F52" points="298.432 281.936 291.233 286.135 291.233 277.837 298.432 273.638"></polygon>
                <polygon fill="#1D2F52" points="312.028 274.138 304.73 278.337 304.73 270.038 312.028 265.839"></polygon>
                <polygon fill="#1D2F52" points="325.525 266.339 318.327 270.438 318.327 262.14 325.525 258.041"></polygon>
                <polygon fill="#1D2F52" points="244.144 330.825 236.946 335.024 236.946 326.725 244.144 322.526"></polygon>
                <polygon fill="#1D2F52" points="257.741 323.026 250.543 327.125 250.543 318.827 257.741 314.728"></polygon>
                <polygon fill="#1D2F52" points="271.338 315.128 264.039 319.327 264.039 311.029 271.338 306.83"></polygon>
                <polygon fill="#1D2F52" points="284.835 307.33 277.636 311.529 277.636 303.231 284.835 299.032"></polygon>
                <polygon fill="#1D2F52" points="298.432 299.532 291.233 303.631 291.233 295.333 298.432 291.234"></polygon>
                <polygon fill="#1D2F52" points="312.028 291.634 304.73 295.833 304.73 287.534 312.028 283.335"></polygon>
                <polygon fill="#1D2F52" points="325.525 283.835 318.327 288.034 318.327 279.736 325.525 275.537"></polygon>
                <polygon fill="#1D2F52" points="244.144 348.321 236.946 352.52 236.946 344.221 244.144 340.022"></polygon>
                <polygon fill="#1D2F52" points="257.741 340.522 250.543 344.721 250.543 336.423 257.741 332.224"></polygon>
                <polygon fill="#1D2F52" points="271.338 332.724 264.039 336.823 264.039 328.525 271.338 324.426"></polygon>
                <polygon fill="#1D2F52" points="284.835 324.826 277.636 329.025 277.636 320.727 284.835 316.528"></polygon>
                <polygon fill="#1D2F52" points="298.432 317.028 291.233 321.227 291.233 312.929 298.432 308.73"></polygon>
                <polygon fill="#1D2F52" points="312.028 309.229 304.73 313.328 304.73 305.03 312.028 300.931"></polygon>
                <polygon fill="#1D2F52" points="325.525 301.331 318.327 305.53 318.327 297.232 325.525 293.033"></polygon>
                <polygon fill="#1D2F52" points="244.144 365.917 236.946 370.016 236.946 361.718 244.144 357.619"></polygon>
                <polygon fill="#1D2F52" points="257.741 358.018 250.543 362.217 250.543 353.919 257.741 349.72"></polygon>
                <polygon fill="#1D2F52" points="271.338 350.22 264.039 354.419 264.039 346.121 271.338 341.922"></polygon>
                <polygon fill="#1D2F52" points="284.835 342.422 277.636 346.521 277.636 338.223 284.835 334.124"></polygon>
                <polygon fill="#1D2F52" points="298.432 334.524 291.233 338.723 291.233 330.425 298.432 326.226"></polygon>
                <polygon fill="#1D2F52" points="312.028 326.726 304.73 330.925 304.73 322.627 312.028 318.427"></polygon>
                <polygon fill="#1D2F52" points="325.525 318.927 318.327 323.026 318.327 314.828 325.525 310.629"></polygon>
                <polygon fill="#1D2F52" points="244.144 383.413 236.946 387.612 236.946 379.314 244.144 375.114"></polygon>
                <polygon fill="#1D2F52" points="257.741 375.614 250.543 379.713 250.543 371.515 257.741 367.316"></polygon>
                <polygon fill="#1D2F52" points="271.338 367.716 264.039 371.915 264.039 363.617 271.338 359.518"></polygon>
                <polygon fill="#1D2F52" points="284.835 359.918 277.636 364.117 277.636 355.819 284.835 351.62"></polygon>
                <polygon fill="#1D2F52" points="298.432 352.12 291.233 356.219 291.233 348.021 298.432 343.822"></polygon>
                <polygon fill="#1D2F52" points="312.028 344.222 304.73 348.421 304.73 340.123 312.028 336.024"></polygon>
                <polygon fill="#1D2F52" points="325.525 336.423 318.327 340.623 318.327 332.324 325.525 328.125"></polygon>
                <polygon fill="#1D2F52" points="244.144 400.909 236.946 405.108 236.946 396.81 244.144 392.71"></polygon>
                <polygon fill="#1D2F52" points="257.741 393.11 250.543 397.309 250.543 389.011 257.741 384.812"></polygon>
                <polygon fill="#1D2F52" points="271.338 385.312 264.039 389.411 264.039 381.213 271.338 377.014"></polygon>
                <polygon fill="#1D2F52" points="284.835 377.414 277.636 381.613 277.636 373.315 284.835 369.216"></polygon>
                <polygon fill="#1D2F52" points="298.432 369.616 291.233 373.815 291.233 365.517 298.432 361.318"></polygon>
                <polygon fill="#1D2F52" points="312.028 361.818 304.73 366.017 304.73 357.719 312.028 353.52"></polygon>
                <polygon fill="#1D2F52" points="325.525 353.919 318.327 358.118 318.327 349.82 325.525 345.721"></polygon>
                <polygon fill="#1D2F52" points="244.144 418.505 236.946 422.704 236.946 414.405 244.144 410.206"></polygon>
                <polygon fill="#1D2F52" points="257.741 410.606 250.543 414.805 250.543 406.507 257.741 402.408"></polygon>
                <polygon fill="#1D2F52" points="271.338 402.808 264.039 407.007 264.039 398.709 271.338 394.51"></polygon>
                <polygon fill="#1D2F52" points="284.835 395.01 277.636 399.209 277.636 390.911 284.835 386.712"></polygon>
                <polygon fill="#1D2F52" points="298.432 387.212 291.233 391.311 291.233 383.013 298.432 378.914"></polygon>
                <polygon fill="#1D2F52" points="312.028 379.313 304.73 383.513 304.73 375.214 312.028 371.015"></polygon>
                <polygon fill="#1D2F52" points="325.525 371.515 318.327 375.714 318.327 367.416 325.525 363.217"></polygon>
                <polygon fill="#1D2F52" points="244.144 436 236.946 440.2 236.946 431.901 244.144 427.702"></polygon>
                <polygon fill="#1D2F52" points="257.741 428.202 250.543 432.401 250.543 424.103 257.741 419.904"></polygon>
                <polygon fill="#1D2F52" points="271.338 420.404 264.039 424.503 264.039 416.205 271.338 412.106"></polygon>
                <polygon fill="#1D2F52" points="284.835 412.506 277.636 416.705 277.636 408.407 284.835 404.208"></polygon>
                <polygon fill="#1D2F52" points="298.432 404.708 291.233 408.907 291.233 400.609 298.432 396.41"></polygon>
                <polygon fill="#1D2F52" points="312.028 396.909 304.73 401.008 304.73 392.71 312.028 388.611"></polygon>
                <polygon fill="#1D2F52" points="325.525 389.011 318.327 393.21 318.327 384.912 325.525 380.713"></polygon>
                <polygon fill="#1D2F52" points="244.144 453.597 236.946 457.696 236.946 449.397 244.144 445.298"></polygon>
                <polygon fill="#1D2F52" points="257.741 445.698 250.543 449.897 250.543 441.599 257.741 437.4"></polygon>
                <polygon fill="#1D2F52" points="271.338 437.9 264.039 442.099 264.039 433.801 271.338 429.602"></polygon>
                <polygon fill="#1D2F52" points="284.835 430.102 277.636 434.201 277.636 425.903 284.835 421.804"></polygon>
                <polygon fill="#1D2F52" points="298.432 422.204 291.233 426.403 291.233 418.105 298.432 413.906"></polygon>
                <polygon fill="#1D2F52" points="312.028 414.406 304.73 418.605 304.73 410.306 312.028 406.107"></polygon>
                <polygon fill="#1D2F52" points="325.525 406.607 318.327 410.706 318.327 402.508 325.525 398.309"></polygon>
                <polygon fill="#1D2F52" points="244.144 471.093 236.946 475.292 236.946 466.994 244.144 462.795"></polygon>
                <polygon fill="#1D2F52" points="257.741 463.294 250.543 467.393 250.543 459.195 257.741 454.996"></polygon>
                <polygon fill="#1D2F52" points="271.338 455.396 264.039 459.595 264.039 451.297 271.338 447.098"></polygon>
                <polygon fill="#1D2F52" points="284.835 447.598 277.636 451.797 277.636 443.499 284.835 439.3"></polygon>
                <polygon fill="#1D2F52" points="298.432 439.8 291.233 443.899 291.233 435.701 298.432 431.502"></polygon>
                <polygon fill="#1D2F52" points="312.028 431.902 304.73 436.101 304.73 427.803 312.028 423.703"></polygon>
                <polygon fill="#1D2F52" points="325.525 424.103 318.327 428.302 318.327 420.004 325.525 415.805"></polygon>
                <polygon fill="#1D2F52" points="244.144 488.589 236.946 492.788 236.946 484.49 244.144 480.39"></polygon>
                <polygon fill="#1D2F52" points="257.741 480.791 250.543 484.989 250.543 476.691 257.741 472.492"></polygon>
                <polygon fill="#1D2F52" points="271.338 472.992 264.039 477.091 264.039 468.893 271.338 464.694"></polygon>
                <polygon fill="#1D2F52" points="284.835 465.094 277.636 469.293 277.636 460.995 284.835 456.896"></polygon>
                <polygon fill="#1D2F52" points="298.432 457.296 291.233 461.495 291.233 453.197 298.432 448.998"></polygon>
                <polygon fill="#1D2F52" points="312.028 449.498 304.73 453.597 304.73 445.399 312.028 441.2"></polygon>
                <polygon fill="#1D2F52" points="325.525 441.6 318.327 445.799 318.327 437.5 325.525 433.401"></polygon>
                <polygon fill="#1D2F52" points="244.144 506.185 236.946 510.284 236.946 502.086 244.144 497.886"></polygon>
                <polygon fill="#1D2F52" points="257.741 498.286 250.543 502.485 250.543 494.187 257.741 490.088"></polygon>
                <polygon fill="#1D2F52" points="271.338 490.488 264.039 494.687 264.039 486.389 271.338 482.19"></polygon>
                <polygon fill="#1D2F52" points="284.835 482.69 277.636 486.889 277.636 478.591 284.835 474.392"></polygon>
                <polygon fill="#1D2F52" points="298.432 474.892 291.233 478.991 291.233 470.692 298.432 466.593"></polygon>
                <polygon fill="#1D2F52" points="312.028 466.994 304.73 471.193 304.73 462.894 312.028 458.695"></polygon>
                <polygon fill="#1D2F52" points="325.525 459.195 318.327 463.394 318.327 455.096 325.525 450.897"></polygon>
                <polygon fill="#1D2F52" points="244.144 523.681 236.946 527.88 236.946 519.581 244.144 515.382"></polygon>
                <polygon fill="#1D2F52" points="257.741 515.882 250.543 520.081 250.543 511.783 257.741 507.584"></polygon>
                <polygon fill="#1D2F52" points="271.338 508.084 264.039 512.183 264.039 503.885 271.338 499.786"></polygon>
                <polygon fill="#1D2F52" points="284.835 500.186 277.636 504.385 277.636 496.087 284.835 491.888"></polygon>
                <polygon fill="#1D2F52" points="298.432 492.388 291.233 496.587 291.233 488.289 298.432 484.089"></polygon>
                <polygon fill="#1D2F52" points="312.028 484.589 304.73 488.689 304.73 480.39 312.028 476.291"></polygon>
                <polygon fill="#1D2F52" points="325.525 476.691 318.327 480.89 318.327 472.592 325.525 468.393"></polygon>
                <polygon fill="#1D2F52" points="244.144 541.277 236.946 545.376 236.946 537.078 244.144 532.978"></polygon>
                <polygon fill="#1D2F52" points="257.741 533.378 250.543 537.577 250.543 529.279 257.741 525.08"></polygon>
                <polygon fill="#1D2F52" points="271.338 525.58 264.039 529.779 264.039 521.481 271.338 517.282"></polygon>
                <polygon fill="#1D2F52" points="284.835 517.782 277.636 521.881 277.636 513.583 284.835 509.484"></polygon>
                <polygon fill="#1D2F52" points="298.432 509.884 291.233 514.083 291.233 505.784 298.432 501.585"></polygon>
                <polygon fill="#1D2F52" points="312.028 502.085 304.73 506.284 304.73 497.986 312.028 493.787"></polygon>
                <polygon fill="#1D2F52" points="325.525 494.287 318.327 498.386 318.327 490.088 325.525 485.989"></polygon>
                <polygon fill="#1D2F52" points="244.144 558.772 236.946 562.971 236.946 554.673 244.144 550.474"></polygon>
                <polygon fill="#1D2F52" points="257.741 550.974 250.543 555.073 250.543 546.775 257.741 542.676"></polygon>
                <polygon fill="#1D2F52" points="271.338 543.076 264.039 547.275 264.039 538.977 271.338 534.778"></polygon>
                <polygon fill="#1D2F52" points="284.835 535.278 277.636 539.477 277.636 531.179 284.835 526.98"></polygon>
                <polygon fill="#1D2F52" points="298.432 527.48 291.233 531.579 291.233 523.381 298.432 519.182"></polygon>
                <polygon fill="#1D2F52" points="312.028 519.581 304.73 523.78 304.73 515.482 312.028 511.383"></polygon>
                <polygon fill="#1D2F52" points="325.525 511.783 318.327 515.982 318.327 507.684 325.525 503.485"></polygon>
                <polygon fill="#3A5070" points="43.49 167.462 50.788 171.661 50.788 163.363 43.49 159.264"></polygon>
                <polygon fill="#3A5070" points="29.993 159.664 37.191 163.863 37.191 155.564 29.993 151.365"></polygon>
                <polygon fill="#3A5070" points="16.396 151.865 23.594 155.964 23.594 147.766 16.396 143.567"></polygon>
                <polygon fill="#3A5070" points="43.49 185.058 50.788 189.157 50.788 180.959 43.49 176.76"></polygon>
                <polygon fill="#3A5070" points="29.993 177.16 37.191 181.359 37.191 173.06 29.993 168.961"></polygon>
                <polygon fill="#3A5070" points="16.396 169.361 23.594 173.56 23.594 165.262 16.396 161.063"></polygon>
                <polygon fill="#3A5070" points="43.49 202.554 50.788 206.753 50.788 198.455 43.49 194.255"></polygon>
                <polygon fill="#3A5070" points="29.993 194.755 37.191 198.954 37.191 190.656 29.993 186.457"></polygon>
                <motion.polygon variants={lightsAnimation(getRandomDuration, getRandomDelay)} className="window zz" fill="#3A5070" points="16.396 186.957 23.594 191.056 23.594 182.758 16.396 178.659"></motion.polygon>
                <polygon fill="#3A5070" points="43.49 220.149 50.788 224.249 50.788 215.95 43.49 211.851"></polygon>
                <polygon fill="#3A5070" points="29.993 212.251 37.191 216.45 37.191 208.152 29.993 203.953"></polygon>
                <polygon fill="#3A5070" points="16.396 204.453 23.594 208.652 23.594 200.354 16.396 196.155"></polygon>
                <polygon fill="#3A5070" points="43.49 237.646 50.788 241.845 50.788 233.547 43.49 229.347"></polygon>
                <motion.polygon variants={lightsAnimation(getRandomDuration, getRandomDelay)} className="window" fill="#3A5070" points="29.993 229.847 37.191 233.947 37.191 225.648 29.993 221.549"></motion.polygon>
                <polygon fill="#3A5070" points="16.396 221.949 23.594 226.148 23.594 217.85 16.396 213.651"></polygon>
                <polygon fill="#3A5070" points="43.49 255.142 50.788 259.341 50.788 251.043 43.49 246.944"></polygon>
                <polygon fill="#3A5070" points="29.993 247.344 37.191 251.543 37.191 243.245 29.993 239.046"></polygon>
                <polygon fill="#3A5070" points="16.396 239.545 23.594 243.644 23.594 235.446 16.396 231.247"></polygon>
                <polygon fill="#3A5070" points="43.49 272.738 50.788 276.837 50.788 268.639 43.49 264.44"></polygon>
                <polygon fill="#3A5070" points="29.993 264.84 37.191 269.039 37.191 260.741 29.993 256.642"></polygon>
                <polygon fill="#3A5070" points="16.396 257.041 23.594 261.24 23.594 252.942 16.396 248.743"></polygon>
                <polygon fill="#3A5070" points="43.49 290.234 50.788 294.433 50.788 286.135 43.49 281.936"></polygon>
                <polygon fill="#3A5070" points="29.993 282.435 37.191 286.635 37.191 278.336 29.993 274.137"></polygon>
                <polygon fill="#3A5070" points="16.396 274.637 23.594 278.736 23.594 270.438 16.396 266.339"></polygon>
                <motion.polygon variants={lightsAnimation(getRandomDuration, getRandomDelay)} className="window" fill="#3A5070" points="43.49 307.83 50.788 311.929 50.788 303.631 43.49 299.532"></motion.polygon>
                <polygon fill="#3A5070" points="29.993 299.932 37.191 304.131 37.191 295.832 29.993 291.633"></polygon>
                <polygon fill="#3A5070" points="16.396 292.133 23.594 296.332 23.594 288.034 16.396 283.835"></polygon>
                <polygon fill="#3A5070" points="43.49 325.326 50.788 329.525 50.788 321.227 43.49 317.028"></polygon>
                <polygon fill="#3A5070" points="29.993 317.527 37.191 321.627 37.191 313.328 29.993 309.229"></polygon>
                <polygon fill="#3A5070" points="16.396 309.629 23.594 313.828 23.594 305.53 16.396 301.331"></polygon>
                <polygon fill="#3A5070" points="43.49 342.822 50.788 347.021 50.788 338.723 43.49 334.524"></polygon>
                <polygon fill="#3A5070" points="29.993 335.024 37.191 339.223 37.191 330.925 29.993 326.726"></polygon>
                <polygon fill="#3A5070" points="16.396 327.225 23.594 331.324 23.594 323.126 16.396 318.927"></polygon>
                <polygon fill="#3A5070" points="43.49 360.418 50.788 364.517 50.788 356.319 43.49 352.119"></polygon>
                <polygon fill="#3A5070" points="29.993 352.52 37.191 356.719 37.191 348.42 29.993 344.321"></polygon>
                <polygon fill="#3A5070" points="16.396 344.721 23.594 348.92 23.594 340.622 16.396 336.423"></polygon>
                <polygon fill="#3A5070" points="43.49 377.914 50.788 382.113 50.788 373.815 43.49 369.616"></polygon>
                <polygon fill="#3A5070" points="29.993 370.116 37.191 374.315 37.191 366.016 29.993 361.817"></polygon>
                <polygon fill="#3A5070" points="16.396 362.317 23.594 366.416 23.594 358.118 16.396 354.019"></polygon>
                <polygon fill="#3A5070" points="43.49 395.51 50.788 399.609 50.788 391.311 43.49 387.212"></polygon>
                <polygon fill="#3A5070" points="29.993 387.612 37.191 391.811 37.191 383.513 29.993 379.313"></polygon>
                <polygon fill="#3A5070" points="16.396 379.813 23.594 384.012 23.594 375.714 16.396 371.515"></polygon>
                <polygon fill="#3A5070" points="43.49 413.006 50.788 417.205 50.788 408.907 43.49 404.708"></polygon>
                <polygon fill="#3A5070" points="29.993 405.208 37.191 409.307 37.191 401.009 29.993 396.91"></polygon>
                <polygon fill="#3A5070" points="16.396 397.309 23.594 401.508 23.594 393.21 16.396 389.011"></polygon>
                <polygon fill="#3A5070" points="43.49 430.502 50.788 434.701 50.788 426.403 43.49 422.204"></polygon>
                <polygon fill="#3A5070" points="29.993 422.704 37.191 426.903 37.191 418.605 29.993 414.405"></polygon>
                <polygon fill="#3A5070" points="16.396 414.905 23.594 419.004 23.594 410.806 16.396 406.607"></polygon>
                <polygon fill="#3A5070" points="43.49 448.098 50.788 452.197 50.788 443.999 43.49 439.8"></polygon>
                <polygon fill="#3A5070" points="29.993 440.2 37.191 444.399 37.191 436.101 29.993 432.001"></polygon>
                <polygon fill="#3A5070" points="16.396 432.401 23.594 436.6 23.594 428.302 16.396 424.103"></polygon>
                <polygon fill="#3A5070" points="43.49 465.594 50.788 469.793 50.788 461.494 43.49 457.295"></polygon>
                <polygon fill="#3A5070" points="29.993 457.795 37.191 461.994 37.191 453.696 29.993 449.497"></polygon>
                <polygon fill="#3A5070" points="16.396 449.897 23.594 454.096 23.594 445.798 16.396 441.699"></polygon>
                <polygon fill="#3A5070" points="43.49 483.19 50.788 487.289 50.788 478.991 43.49 474.892"></polygon>
                <polygon fill="#3A5070" points="29.993 475.291 37.191 479.49 37.191 471.192 29.993 466.993"></polygon>
                <polygon fill="#3A5070" points="16.396 467.493 23.594 471.692 23.594 463.394 16.396 459.195"></polygon>
                <polygon fill="#3A5070" points="185.357 248.543 192.656 252.642 192.656 244.444 185.357 240.245"></polygon>
                <polygon fill="#3A5070" points="171.861 240.645 179.059 244.844 179.059 236.546 171.861 232.447"></polygon>
                <polygon fill="#3A5070" points="158.264 232.847 165.462 237.046 165.462 228.748 158.264 224.549"></polygon>
                <polygon fill="#3A5070" points="185.357 266.039 192.656 270.238 192.656 261.94 185.357 257.741"></polygon>
                <polygon fill="#3A5070" points="171.861 258.241 179.059 262.44 179.059 254.142 171.861 249.943"></polygon>
                <polygon fill="#3A5070" points="158.264 250.443 165.462 254.542 165.462 246.244 158.264 242.145"></polygon>
                <polygon fill="#3A5070" points="185.357 283.635 192.656 287.734 192.656 279.436 185.357 275.337"></polygon>
                <polygon fill="#3A5070" points="171.861 275.737 179.059 279.936 179.059 271.638 171.861 267.439"></polygon>
                <polygon fill="#3A5070" points="158.264 267.939 165.462 272.138 165.462 263.84 158.264 259.641"></polygon>
                <polygon fill="#3A5070" points="185.357 301.131 192.656 305.33 192.656 297.032 185.357 292.833"></polygon>
                <polygon fill="#3A5070" points="171.861 293.333 179.059 297.432 179.059 289.134 171.861 285.035"></polygon>
                <polygon fill="#3A5070" points="158.264 285.435 165.462 289.634 165.462 281.336 158.264 277.137"></polygon>
                <polygon fill="#3A5070" points="185.357 318.627 192.656 322.826 192.656 314.528 185.357 310.329"></polygon>
                <polygon fill="#3A5070" points="171.861 310.829 179.059 315.028 179.059 306.73 171.861 302.531"></polygon>
                <polygon fill="#3A5070" points="158.264 303.031 165.462 307.13 165.462 298.932 158.264 294.733"></polygon>
                <polygon fill="#3A5070" points="185.357 336.223 192.656 340.322 192.656 332.124 185.357 327.925"></polygon>
                <polygon fill="#3A5070" points="171.861 328.325 179.059 332.524 179.059 324.226 171.861 320.127"></polygon>
                <polygon fill="#3A5070" points="158.264 320.527 165.462 324.726 165.462 316.428 158.264 312.229"></polygon>
                <polygon fill="#3A5070" points="185.357 353.719 192.656 357.918 192.656 349.62 185.357 345.421"></polygon>
                <polygon fill="#3A5070" points="171.861 345.921 179.059 350.12 179.059 341.822 171.861 337.623"></polygon>
                <polygon fill="#3A5070" points="158.264 338.023 165.462 342.222 165.462 333.924 158.264 329.825"></polygon>
                <polygon fill="#3A5070" points="185.357 371.315 192.656 375.414 192.656 367.116 185.357 363.017"></polygon>
                <polygon fill="#3A5070" points="171.861 363.417 179.059 367.616 179.059 359.318 171.861 355.119"></polygon>
                <polygon fill="#3A5070" points="158.264 355.619 165.462 359.818 165.462 351.52 158.264 347.321"></polygon>
                <polygon fill="#3A5070" points="185.357 388.811 192.656 393.01 192.656 384.712 185.357 380.513"></polygon>
                <polygon fill="#3A5070" points="171.861 381.013 179.059 385.112 179.059 376.814 171.861 372.715"></polygon>
                <polygon fill="#3A5070" points="158.264 373.115 165.462 377.314 165.462 369.016 158.264 364.817"></polygon>
                <polygon fill="#3A5070" points="185.357 406.307 192.656 410.506 192.656 402.208 185.357 398.009"></polygon>
                <polygon fill="#3A5070" points="171.861 398.509 179.059 402.708 179.059 394.41 171.861 390.211"></polygon>
                <polygon fill="#3A5070" points="158.264 390.711 165.462 394.81 165.462 386.612 158.264 382.413"></polygon>
                <polygon fill="#3A5070" points="185.357 423.903 192.656 428.002 192.656 419.804 185.357 415.605"></polygon>
                <polygon fill="#3A5070" points="171.861 416.005 179.059 420.204 179.059 411.906 171.861 407.807"></polygon>
                <polygon fill="#3A5070" points="158.264 408.207 165.462 412.406 165.462 404.108 158.264 399.909"></polygon>
                <polygon fill="#3A5070" points="185.357 441.399 192.656 445.598 192.656 437.3 185.357 433.101"></polygon>
                <polygon fill="#3A5070" points="171.861 433.601 179.059 437.7 179.059 429.502 171.861 425.303"></polygon>
                <polygon fill="#3A5070" points="158.264 425.703 165.462 429.902 165.462 421.604 158.264 417.504"></polygon>
                <polygon fill="#3A5070" points="185.357 458.995 192.656 463.094 192.656 454.796 185.357 450.697"></polygon>
                <polygon fill="#3A5070" points="171.861 451.097 179.059 455.296 179.059 446.998 171.861 442.799"></polygon>
                <polygon fill="#3A5070" points="158.264 443.299 165.462 447.498 165.462 439.2 158.264 435.001"></polygon>
                <polygon fill="#3A5070" points="185.357 476.491 192.656 480.69 192.656 472.392 185.357 468.193"></polygon>
                <polygon fill="#3A5070" points="171.861 468.693 179.059 472.792 179.059 464.494 171.861 460.395"></polygon>
                <polygon fill="#3A5070" points="158.264 460.795 165.462 464.994 165.462 456.696 158.264 452.497"></polygon>
                <polygon fill="#3A5070" points="185.357 493.987 192.656 498.186 192.656 489.888 185.357 485.689"></polygon>
                <polygon fill="#3A5070" points="171.861 486.189 179.059 490.388 179.059 482.09 171.861 477.891"></polygon>
                <polygon fill="#3A5070" points="158.264 478.391 165.462 482.49 165.462 474.192 158.264 470.093"></polygon>
                <polygon fill="#3A5070" points="185.357 511.583 192.656 515.682 192.656 507.484 185.357 503.285"></polygon>
                <polygon fill="#3A5070" points="171.861 503.685 179.059 507.884 179.059 499.586 171.861 495.487"></polygon>
                <polygon fill="#3A5070" points="158.264 495.887 165.462 500.086 165.462 491.788 158.264 487.589"></polygon>
                <polygon fill="#3A5070" points="185.357 529.079 192.656 533.278 192.656 524.98 185.357 520.781"></polygon>
                <polygon fill="#3A5070" points="171.861 521.281 179.059 525.38 179.059 517.182 171.861 512.983"></polygon>
                <polygon fill="#3A5070" points="158.264 513.383 165.462 517.582 165.462 509.284 158.264 505.185"></polygon>
                <polygon fill="#3A5070" points="185.357 546.575 192.656 550.774 192.656 542.476 185.357 538.377"></polygon>
                <polygon fill="#3A5070" points="171.861 538.777 179.059 542.976 179.059 534.678 171.861 530.479"></polygon>
                <polygon fill="#3A5070" points="158.264 530.979 165.462 535.178 165.462 526.88 158.264 522.681"></polygon>
                <polygon fill="#3A5070" points="185.357 564.171 192.656 568.37 192.656 560.072 185.357 555.873"></polygon>
                <polygon fill="#3A5070" points="171.861 556.373 179.059 560.472 179.059 552.174 171.861 548.075"></polygon>
                <polygon fill="#3A5070" points="158.264 548.475 165.462 552.674 165.462 544.376 158.264 540.177"></polygon>
                <polygon fill="#3A5070" points="104.076 547.475 111.274 551.574 111.274 145.967 104.076 141.768"></polygon>
                <polygon fill="#3A5070" points="90.479 539.577 97.677 543.776 97.677 138.069 90.479 133.969"></polygon>
                <polygon fill="#3A5070" points="76.882 531.779 84.18 535.978 84.18 130.27 76.882 126.071"></polygon>
            </g>
        </motion.g>
        <motion.g variants={fadeIn()} id="roads" mask="url(#mask-2)">
            <g transform="translate(0.933400, 377.810000)">
                <path d="M1303.06,363.519 L901.947,131.072 L0.352844,656.05 L0.352844,703.84 L34.2451,703.84 L495.34,435.702 L958.035,703.84 L1074.11,703.84 L953.036,633.66 L1303.06,430.704 L1774.45,703.84 L1890.52,703.84 L1361.04,397.111 L1927.0666,67.19 L1927.0666,0.69 L1303.06,363.519 Z M895.049,600.065 L766.578,525.582 L987.828,394.412 L893.849,340.824 L673.199,471.594 L553.227,402.11 L902.747,198.857 L1244.97,397.111 L895.049,600.065 Z" id="Shape" fill="#172843" fill-rule="nonzero"></path>
                <g id="Group" opacity="0.7" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(-0.000000, 16.190000)">
                    <path d="M1303.06,357.327 L901.947,124.98 L0.352844,649.96 L0.352844,687.65 L16.8491,687.65 L495.34,409.515 L975.431,687.65 L1056.71,687.65 L935.64,617.47 L1303.06,404.416 L1791.84,687.55 L1873.23,687.55 L1343.75,380.821 L1917.92,47.897 L1917.92,0.808 L1303.06,357.327 Z M895.049,593.973 L749.282,509.492 L970.432,378.422 L893.849,334.732 L673.199,465.502 L535.831,385.92 L902.747,172.569 L1262.37,380.921 L895.049,593.973 Z" id="Shape" fill="#2A3C56" fill-rule="nonzero"></path>
                    <polygon id="Path" fill="#2A3C56" fill-rule="nonzero" points="749.282 509.492 970.432 378.422 893.849 334.732 673.199 465.502"></polygon>
                </g>
            </g>
        </motion.g>
        <motion.g variants={getSlideTopAnimation(Math.floor(Math.random() * 2) + 1.1)} id="block-15" mask="url(#mask-2)" fill-rule="nonzero">
            <g transform="translate(1634.513400, 160.761000)">
                <polygon id="Path" fill="#12233D" points="109.08 289.434 219.85 225.749 219.85 63.686 109.08 127.271"></polygon>
                <polygon id="Path" fill="#2A3B56" points="1.13686838e-13 225.749 109.18 289.434 109.18 127.271 1.13686838e-13 63.686"></polygon>
                <polygon id="Path" fill="#3A5070" points="1.13686838e-13 63.686 109.18 127.271 219.85 63.686 110.58 -1.42108547e-14"></polygon>
                <path d="M204.85,91.579 L192.56,99.078 L192.56,110.075 L204.85,102.477 L204.85,91.579 Z M182.36,104.476 L170.06,111.975 L170.06,122.972 L182.36,115.374 L182.36,104.476 Z M125.17,137.969 L125.17,148.966 L137.47,141.368 L137.47,130.37 L125.17,137.969 Z M159.96,117.473 L147.67,124.972 L147.67,135.969 L159.96,128.371 L159.96,117.473 Z" id="Shape" fill="#1D2F52"></path>
                <polygon id="Path" fill="#1D2F52" points="204.85 110.475 192.56 117.973 192.56 128.971 204.85 121.373"></polygon>
                <polygon id="Path" fill="#1D2F52" points="182.36 123.372 170.06 130.87 170.06 141.868 182.36 134.27"></polygon>
                <polygon id="Path" fill="#1D2F52" points="125.17 156.765 125.17 167.762 137.47 160.164 137.47 149.166"></polygon>
                <motion.polygon variants={lightsAnimation(getRandomDuration, getRandomDelay)} className="window" id="Path" fill="#3A5070" points="159.96 136.369 147.67 143.867 147.67 154.865 159.96 147.267"></motion.polygon>
                <path d="M204.85,129.371 L192.56,136.869 L192.56,147.866 L204.85,140.268 L204.85,129.371 Z M182.36,142.268 L170.06,149.766 L170.06,160.764 L182.36,153.165 L182.36,142.268 Z M125.17,175.66 L125.17,186.658 L137.47,179.059 L137.47,168.062 L125.17,175.66 Z M159.96,155.265 L147.67,162.763 L147.67,173.761 L159.96,166.162 L159.96,155.265 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M204.85,148.266 L192.56,155.765 L192.56,166.762 L204.85,159.164 L204.85,148.266 Z M182.36,161.164 L170.06,168.662 L170.06,179.659 L182.36,172.061 L182.36,161.164 Z M125.17,194.556 L125.17,205.553 L137.47,197.955 L137.47,186.958 L125.17,194.556 Z M159.96,174.061 L147.67,181.559 L147.67,192.556 L159.96,184.958 L159.96,174.061 Z" id="Shape" fill="#1D2F52"></path>
                <polygon id="Path" fill="#1D2F52" points="204.85 167.162 192.56 174.66 192.56 185.658 204.85 178.06"></polygon>
                <motion.polygon variants={lightsAnimation(getRandomDuration, getRandomDelay)} className="window" id="Path" fill="#3A5070" points="182.36 180.059 170.06 187.557 170.06 198.555 182.36 190.957"></motion.polygon>
                <polygon id="Path" fill="#1D2F52" points="125.17 213.452 125.17 224.449 137.47 216.851 137.47 205.853"></polygon>
                <polygon id="Path" fill="#1D2F52" points="159.96 192.956 147.67 200.454 147.67 211.452 159.96 203.854"></polygon>
                <path d="M204.85,186.058 L192.56,193.556 L192.56,204.554 L204.85,196.955 L204.85,186.058 Z M182.36,198.955 L170.06,206.453 L170.06,217.451 L182.36,209.852 L182.36,198.955 Z M125.17,232.347 L125.17,243.345 L137.47,235.747 L137.47,224.749 L125.17,232.347 Z M159.96,211.852 L147.67,219.35 L147.67,230.348 L159.96,222.749 L159.96,211.852 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M204.85,204.953 L192.56,212.452 L192.56,223.449 L204.85,215.851 L204.85,204.953 Z M182.36,217.85 L170.06,225.349 L170.06,236.346 L182.36,228.748 L182.36,217.85 Z M125.17,251.243 L125.17,262.24 L137.47,254.642 L137.47,243.645 L125.17,251.243 Z M159.96,230.748 L147.67,238.246 L147.67,249.243 L159.96,241.645 L159.96,230.748 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M83.58,130.371 L83.58,141.368 L95.88,148.966 L95.88,137.969 L83.58,130.371 Z M61.09,128.371 L73.38,135.969 L73.38,124.972 L61.09,117.473 L61.09,128.371 Z M16.2,102.577 L28.49,110.175 L28.49,99.178 L16.2,91.679 L16.2,102.577 Z M38.69,115.474 L50.99,123.072 L50.99,112.075 L38.69,104.576 L38.69,115.474 Z" id="Shape" fill="#3A5070"></path>
                <path d="M83.58,149.266 L83.58,160.264 L95.88,167.862 L95.88,156.864 L83.58,149.266 Z M61.09,147.267 L73.38,154.865 L73.38,143.867 L61.09,136.369 L61.09,147.267 Z M16.2,121.473 L28.49,129.071 L28.49,118.073 L16.2,110.575 L16.2,121.473 Z M38.69,134.37 L50.99,141.968 L50.99,130.97 L38.69,123.472 L38.69,134.37 Z" id="Shape" fill="#3A5070"></path>
                <path d="M83.58,168.162 L83.58,179.159 L95.88,186.758 L95.88,175.76 L83.58,168.162 Z M61.09,166.162 L73.38,173.761 L73.38,162.763 L61.09,155.265 L61.09,166.162 Z M16.2,140.268 L28.49,147.867 L28.49,136.869 L16.2,129.371 L16.2,140.268 Z M38.69,153.265 L50.99,160.864 L50.99,149.866 L38.69,142.368 L38.69,153.265 Z" id="Shape" fill="#3A5070"></path>
                <path d="M83.58,187.058 L83.58,198.055 L95.88,205.653 L95.88,194.656 L83.58,187.058 Z M61.09,185.058 L73.38,192.656 L73.38,181.659 L61.09,174.161 L61.09,185.058 Z M16.2,159.164 L28.49,166.762 L28.49,155.765 L16.2,148.266 L16.2,159.164 Z M38.69,172.161 L50.99,179.759 L50.99,168.762 L38.69,161.264 L38.69,172.161 Z" id="Shape" fill="#3A5070"></path>
                <path d="M83.58,205.953 L83.58,216.951 L95.88,224.549 L95.88,213.552 L83.58,205.953 Z M61.09,203.954 L73.38,211.552 L73.38,200.554 L61.09,193.056 L61.09,203.954 Z M16.2,178.06 L28.49,185.658 L28.49,174.66 L16.2,167.162 L16.2,178.06 Z M38.69,191.057 L50.99,198.655 L50.99,187.657 L38.69,180.159 L38.69,191.057 Z" id="Shape" fill="#3A5070"></path>
                <path d="M83.58,224.849 L83.58,235.846 L95.88,243.445 L95.88,232.447 L83.58,224.849 Z M61.09,222.849 L73.38,230.448 L73.38,219.45 L61.09,211.952 L61.09,222.849 Z M16.2,196.955 L28.49,204.554 L28.49,193.556 L16.2,186.058 L16.2,196.955 Z M38.69,209.952 L50.99,217.551 L50.99,206.553 L38.69,199.055 L38.69,209.952 Z" id="Shape" fill="#3A5070"></path>
                <path d="M83.58,243.745 L83.58,254.742 L95.88,262.34 L95.88,251.343 L83.58,243.745 Z M61.09,241.745 L73.38,249.343 L73.38,238.346 L61.09,230.848 L61.09,241.745 Z M16.2,215.851 L28.49,223.449 L28.49,212.452 L16.2,204.953 L16.2,215.851 Z M38.69,228.848 L50.99,236.446 L50.99,225.449 L38.69,217.951 L38.69,228.848 Z" id="Shape" fill="#3A5070"></path>
            </g>
        </motion.g>
        <motion.g variants={getSlideTopAnimation(Math.floor(Math.random() * 2) + 1.1)} id="block-19" mask="url(#mask-2)" fill-rule="nonzero">
            <g transform="translate(1870.000000, 332.000000)">
                <polygon id="Path" fill="#2A3B56" points="1.13686838e-13 225.749 109.18 289.434 109.18 127.271 1.13686838e-13 63.686"></polygon>
                <polygon id="Path" fill="#3A5070" points="1.13686838e-13 63.686 109.18 127.271 219.85 63.686 110.58 -1.42108547e-14"></polygon>
                <path d="M83.58,130.371 L83.58,141.368 L95.88,148.966 L95.88,137.969 L83.58,130.371 Z M61.09,128.371 L73.38,135.969 L73.38,124.972 L61.09,117.473 L61.09,128.371 Z M16.2,102.577 L28.49,110.175 L28.49,99.178 L16.2,91.679 L16.2,102.577 Z M38.69,115.474 L50.99,123.072 L50.99,112.075 L38.69,104.576 L38.69,115.474 Z" id="Shape" fill="#3A5070"></path>
                <path d="M83.58,149.266 L83.58,160.264 L95.88,167.862 L95.88,156.864 L83.58,149.266 Z M61.09,147.267 L73.38,154.865 L73.38,143.867 L61.09,136.369 L61.09,147.267 Z M16.2,121.473 L28.49,129.071 L28.49,118.073 L16.2,110.575 L16.2,121.473 Z M38.69,134.37 L50.99,141.968 L50.99,130.97 L38.69,123.472 L38.69,134.37 Z" id="Shape" fill="#3A5070"></path>
                <path d="M83.58,168.162 L83.58,179.159 L95.88,186.758 L95.88,175.76 L83.58,168.162 Z M61.09,166.162 L73.38,173.761 L73.38,162.763 L61.09,155.265 L61.09,166.162 Z M16.2,140.268 L28.49,147.867 L28.49,136.869 L16.2,129.371 L16.2,140.268 Z M38.69,153.265 L50.99,160.864 L50.99,149.866 L38.69,142.368 L38.69,153.265 Z" id="Shape" fill="#3A5070"></path>
                <path d="M83.58,187.058 L83.58,198.055 L95.88,205.653 L95.88,194.656 L83.58,187.058 Z M61.09,185.058 L73.38,192.656 L73.38,181.659 L61.09,174.161 L61.09,185.058 Z M16.2,159.164 L28.49,166.762 L28.49,155.765 L16.2,148.266 L16.2,159.164 Z M38.69,172.161 L50.99,179.759 L50.99,168.762 L38.69,161.264 L38.69,172.161 Z" id="Shape" fill="#3A5070"></path>
                <path d="M83.58,205.953 L83.58,216.951 L95.88,224.549 L95.88,213.552 L83.58,205.953 Z M61.09,203.954 L73.38,211.552 L73.38,200.554 L61.09,193.056 L61.09,203.954 Z M16.2,178.06 L28.49,185.658 L28.49,174.66 L16.2,167.162 L16.2,178.06 Z M38.69,191.057 L50.99,198.655 L50.99,187.657 L38.69,180.159 L38.69,191.057 Z" id="Shape" fill="#3A5070"></path>
                <path d="M83.58,224.849 L83.58,235.846 L95.88,243.445 L95.88,232.447 L83.58,224.849 Z M61.09,222.849 L73.38,230.448 L73.38,219.45 L61.09,211.952 L61.09,222.849 Z M16.2,196.955 L28.49,204.554 L28.49,193.556 L16.2,186.058 L16.2,196.955 Z M38.69,209.952 L50.99,217.551 L50.99,206.553 L38.69,199.055 L38.69,209.952 Z" id="Shape" fill="#3A5070"></path>
                <path d="M83.58,243.745 L83.58,254.742 L95.88,262.34 L95.88,251.343 L83.58,243.745 Z M61.09,241.745 L73.38,249.343 L73.38,238.346 L61.09,230.848 L61.09,241.745 Z M16.2,215.851 L28.49,223.449 L28.49,212.452 L16.2,204.953 L16.2,215.851 Z M38.69,228.848 L50.99,236.446 L50.99,225.449 L38.69,217.951 L38.69,228.848 Z" id="Shape" fill="#3A5070"></path>
            </g>
        </motion.g>
        <motion.g variants={getSlideTopAnimation(Math.floor(Math.random() * 2) + 1.1)} id="block-12" mask="url(#mask-2)" fill-rule="nonzero">
            <g transform="translate(1264.000000, 293.000000)" id="Path">
                <polygon fill="#12233D" points="125.87 399.609 340.42 276.137 340.42 73.484 125.87 196.955"></polygon>
                <polygon fill="#2A3B56" points="0 326.126 125.97 399.609 125.97 196.955 0 123.572"></polygon>
                <polygon fill="#3A5070" points="1.13686838e-13 123.572 125.97 196.955 340.42 73.483 214.25 0"></polygon>
                <polygon fill="#12233D" points="100.97 201.054 125.17 214.951 340.42 89.58 340.42 98.278 125.17 223.549 100.97 209.752"></polygon>
                <polygon fill="#12233D" points="100.97 209.052 125.17 222.949 340.42 97.678 340.42 106.376 125.17 231.647 100.97 217.75"></polygon>
                <polygon fill="#12233D" points="100.97 217.151 125.17 231.048 340.42 105.776 340.42 114.374 125.17 239.746 100.97 225.849"></polygon>
                <polygon fill="#12233D" points="100.97 225.249 125.17 239.046 340.42 113.774 340.42 122.472 125.17 247.744 100.97 233.947"></polygon>
                <polygon fill="#12233D" points="100.97 233.247 125.17 247.144 340.42 121.873 340.42 130.571 125.17 255.842 100.97 241.945"></polygon>
                <polygon fill="#12233D" points="100.97 241.345 125.17 255.242 340.42 129.871 340.42 138.569 125.17 263.94 100.97 250.043"></polygon>
                <polygon fill="#12233D" points="100.97 249.443 125.17 263.24 340.42 137.969 340.42 146.667 125.17 271.938 100.97 258.041"></polygon>
                <polygon fill="#12233D" points="100.97 257.441 125.17 271.338 340.42 146.067 340.42 154.765 125.17 280.036 100.97 266.139"></polygon>
                <polygon fill="#12233D" points="100.97 265.54 125.17 279.436 340.42 154.065 340.42 162.763 125.17 288.134 100.97 274.238"></polygon>
                <polygon fill="#12233D" points="100.97 273.638 125.17 287.435 340.42 162.163 340.42 170.861 125.17 296.133 100.97 282.236"></polygon>
                <polygon fill="#12233D" points="100.97 281.636 125.17 295.533 340.42 170.261 340.42 178.959 125.17 304.231 100.97 290.334"></polygon>
                <polygon fill="#12233D" points="100.97 289.734 125.17 303.631 340.42 178.26 340.42 186.958 125.17 312.229 100.97 298.432"></polygon>
                <polygon fill="#12233D" points="100.97 297.832 125.17 311.629 340.42 186.358 340.42 195.056 125.17 320.327 100.97 306.43"></polygon>
                <polygon fill="#12233D" points="100.97 305.831 125.17 319.727 340.42 194.456 340.42 203.054 125.17 328.425 100.97 314.529"></polygon>
                <polygon fill="#12233D" points="100.97 313.928 125.17 327.825 340.42 202.454 340.42 211.152 125.17 336.423 100.97 322.626"></polygon>
                <polygon fill="#12233D" points="100.97 321.927 125.17 335.824 340.42 210.552 340.42 219.25 125.17 344.522 100.97 330.625"></polygon>
                <polygon fill="#12233D" points="100.97 330.025 125.17 343.922 340.42 218.65 340.42 227.248 125.17 352.62 100.97 338.723"></polygon>
                <polygon fill="#12233D" points="100.97 338.123 125.17 352.02 340.42 226.649 340.42 235.347 125.17 360.618 100.97 346.821"></polygon>
                <polygon fill="#12233D" points="100.97 346.121 125.17 360.018 340.42 234.747 340.42 243.445 125.17 368.716 100.97 354.819"></polygon>
                <polygon fill="#12233D" points="100.97 354.22 125.17 368.116 340.42 242.845 340.42 251.443 125.17 376.814 100.97 362.917"></polygon>
                <polygon fill="#12233D" points="100.97 362.318 125.17 376.114 340.42 250.843 340.42 259.541 125.17 384.812 100.97 371.016"></polygon>
                <polygon fill="#12233D" points="312.02 72.284 214.05 15.097 30.19 120.773 128.27 177.96"></polygon>
                <polygon fill="#1D2F52" points="214.15 26.494 214.15 15.097 214.05 15.097 30.19 120.773 40.09 126.471"></polygon>
                <polygon fill="#12233D" points="214.05 26.494 302.13 77.983 312.02 72.284 214.05 15.097"></polygon>
                <polygon fill="#3A5070" points="40.59 126.271 127.87 177.26 301.63 77.283 214.35 26.294"></polygon>
            </g>
        </motion.g>
        <motion.g variants={getSlideTopAnimation(Math.floor(Math.random() * 2) + 1.1)} id="block-9" mask="url(#mask-2)" fill-rule="nonzero">
            <g transform="translate(1040.253400, 125.469000)">
                <polygon id="Path" fill="#12233D" points="135.17 507.185 272.23 428.402 272.23 81.782 135.17 160.664"></polygon>
                <polygon id="Path" fill="#2A3B56" points="4.54747351e-13 428.403 135.17 507.185 135.17 160.664 4.54747351e-13 81.882"></polygon>
                <polygon id="Path" fill="#3A5070" points="0 81.882 135.17 160.664 272.23 81.782 136.86 3"></polygon>
                <path d="M106.57,160.364 L106.57,171.361 L118.87,178.959 L118.87,167.962 L106.57,160.364 Z M84.18,158.364 L96.47,165.962 L96.47,154.965 L84.18,147.467 L84.18,158.364 Z M16.79,119.573 L29.09,127.171 L29.09,116.174 L16.79,108.675 L16.79,119.573 Z M39.29,132.47 L51.58,140.068 L51.58,129.071 L39.29,121.573 L39.29,132.47 Z M61.68,145.367 L73.98,152.965 L73.98,141.968 L61.68,134.47 L61.68,145.367 Z" id="Shape" fill="#3A5070"></path>
                <path d="M106.57,183.359 L106.57,194.356 L118.87,201.954 L118.87,190.957 L106.57,183.359 Z M84.18,181.359 L96.47,188.957 L96.47,177.96 L84.18,170.461 L84.18,181.359 Z M16.79,142.568 L29.09,150.166 L29.09,139.169 L16.79,131.67 L16.79,142.568 Z M39.29,155.565 L51.58,163.163 L51.58,152.166 L39.29,144.667 L39.29,155.565 Z M61.68,168.462 L73.98,176.06 L73.98,165.063 L61.68,157.564 L61.68,168.462 Z" id="Shape" fill="#3A5070"></path>
                <path d="M106.57,206.353 L106.57,217.351 L118.87,224.949 L118.87,213.952 L106.57,206.353 Z M84.18,204.454 L96.47,212.052 L96.47,201.054 L84.18,193.556 L84.18,204.454 Z M16.79,165.663 L29.09,173.261 L29.09,162.263 L16.79,154.765 L16.79,165.663 Z M39.29,178.56 L51.58,186.158 L51.58,175.16 L39.29,167.662 L39.29,178.56 Z M61.68,191.457 L73.98,199.055 L73.98,188.057 L61.68,180.559 L61.68,191.457 Z" id="Shape" fill="#3A5070"></path>
                <path d="M106.57,229.448 L106.57,240.445 L118.87,248.044 L118.87,237.046 L106.57,229.448 Z M84.18,227.448 L96.47,235.047 L96.47,224.049 L84.18,216.551 L84.18,227.448 Z M16.79,188.657 L29.09,196.255 L29.09,185.258 L16.79,177.76 L16.79,188.657 Z M39.29,201.554 L51.58,209.152 L51.58,198.155 L39.29,190.657 L39.29,201.554 Z M61.68,214.551 L73.98,222.15 L73.98,211.152 L61.68,203.654 L61.68,214.551 Z" id="Shape" fill="#3A5070"></path>
                <path d="M106.57,252.443 L106.57,263.44 L118.87,271.038 L118.87,260.041 L106.57,252.443 Z M84.18,250.543 L96.47,258.141 L96.47,247.144 L84.18,239.646 L84.18,250.543 Z M16.79,211.752 L29.09,219.35 L29.09,208.353 L16.79,200.854 L16.79,211.752 Z M39.29,224.649 L51.58,232.247 L51.58,221.25 L39.29,213.752 L39.29,224.649 Z M61.68,237.546 L73.98,245.144 L73.98,234.147 L61.68,226.649 L61.68,237.546 Z" id="Shape" fill="#3A5070"></path>
                <path d="M106.57,275.537 L106.57,286.535 L118.87,294.133 L118.87,283.136 L106.57,275.537 Z M84.18,273.538 L96.47,281.136 L96.47,270.139 L84.18,262.64 L84.18,273.538 Z M16.79,234.747 L29.09,242.345 L29.09,231.347 L16.79,223.849 L16.79,234.747 Z M39.29,247.644 L51.58,255.242 L51.58,244.244 L39.29,236.746 L39.29,247.644 Z M61.68,260.641 L73.98,268.239 L73.98,257.241 L61.68,249.743 L61.68,260.641 Z" id="Shape" fill="#3A5070"></path>
                <path d="M106.57,298.532 L106.57,309.53 L118.87,317.128 L118.87,306.13 L106.57,298.532 Z M84.18,296.633 L96.47,304.231 L96.47,293.233 L84.18,285.735 L84.18,296.633 Z M16.79,257.741 L29.09,265.34 L29.09,254.342 L16.79,246.844 L16.79,257.741 Z M39.29,270.739 L51.58,278.337 L51.58,267.339 L39.29,259.841 L39.29,270.739 Z M61.68,283.636 L73.98,291.234 L73.98,280.236 L61.68,272.738 L61.68,283.636 Z" id="Shape" fill="#3A5070"></path>
                <path d="M106.57,321.627 L106.57,332.624 L118.87,340.223 L118.87,329.225 L106.57,321.627 Z M84.18,319.627 L96.47,327.226 L96.47,316.228 L84.18,308.73 L84.18,319.627 Z M16.79,280.836 L29.09,288.434 L29.09,277.437 L16.79,269.939 L16.79,280.836 Z M39.29,293.733 L51.58,301.332 L51.58,290.334 L39.29,282.836 L39.29,293.733 Z M61.68,306.73 L73.98,314.329 L73.98,303.331 L61.68,295.833 L61.68,306.73 Z" id="Shape" fill="#3A5070"></path>
                <path d="M106.57,344.622 L106.57,355.619 L118.87,363.217 L118.87,352.22 L106.57,344.622 Z M84.18,342.622 L96.47,350.22 L96.47,339.223 L84.18,331.725 L84.18,342.622 Z M16.79,303.831 L29.09,311.429 L29.09,300.432 L16.79,292.933 L16.79,303.831 Z M39.29,316.828 L51.58,324.426 L51.58,313.429 L39.29,305.93 L39.29,316.828 Z M61.68,329.725 L73.98,337.323 L73.98,326.326 L61.68,318.828 L61.68,329.725 Z" id="Shape" fill="#3A5070"></path>
                <path d="M106.57,367.716 L106.57,378.714 L118.87,386.312 L118.87,375.315 L106.57,367.716 Z M84.18,365.717 L96.47,373.315 L96.47,362.318 L84.18,354.819 L84.18,365.717 Z M16.79,326.926 L29.09,334.524 L29.09,323.526 L16.79,316.028 L16.79,326.926 Z M39.29,339.823 L51.58,347.421 L51.58,336.424 L39.29,328.925 L39.29,339.823 Z M61.68,352.82 L73.98,360.418 L73.98,349.421 L61.68,341.922 L61.68,352.82 Z" id="Shape" fill="#3A5070"></path>
                <path d="M106.57,390.711 L106.57,401.709 L118.87,409.307 L118.87,398.309 L106.57,390.711 Z M84.18,388.712 L96.47,396.31 L96.47,385.312 L84.18,377.814 L84.18,388.712 Z M16.79,349.92 L29.09,357.519 L29.09,346.521 L16.79,339.023 L16.79,349.92 Z M39.29,362.917 L51.58,370.516 L51.58,359.518 L39.29,352.02 L39.29,362.917 Z M61.68,375.814 L73.98,383.413 L73.98,372.415 L61.68,364.917 L61.68,375.814 Z" id="Shape" fill="#3A5070"></path>
                <path d="M106.57,413.806 L106.57,424.803 L118.87,432.402 L118.87,421.404 L106.57,413.806 Z M84.18,411.806 L96.47,419.405 L96.47,408.407 L84.18,400.909 L84.18,411.806 Z M16.79,373.015 L29.09,380.613 L29.09,369.616 L16.79,362.118 L16.79,373.015 Z M39.29,385.912 L51.58,393.51 L51.58,382.513 L39.29,375.015 L39.29,385.912 Z M61.68,398.809 L73.98,406.407 L73.98,395.41 L61.68,387.912 L61.68,398.809 Z" id="Shape" fill="#3A5070"></path>
                <path d="M106.57,436.801 L106.57,447.798 L118.87,455.397 L118.87,444.399 L106.57,436.801 Z M84.18,434.801 L96.47,442.4 L96.47,431.402 L84.18,423.904 L84.18,434.801 Z M16.79,396.01 L29.09,403.608 L29.09,392.611 L16.79,385.113 L16.79,396.01 Z M39.29,409.007 L51.58,416.605 L51.58,405.608 L39.29,398.11 L39.29,409.007 Z M61.68,421.904 L73.98,429.502 L73.98,418.505 L61.68,411.007 L61.68,421.904 Z" id="Shape" fill="#3A5070"></path>
                <path d="M106.57,459.795 L106.57,470.793 L118.87,478.391 L118.87,467.394 L106.57,459.795 Z M84.18,457.896 L96.47,465.494 L96.47,454.497 L84.18,446.998 L84.18,457.896 Z M16.79,419.105 L29.09,426.703 L29.09,415.706 L16.79,408.207 L16.79,419.105 Z M39.29,432.002 L51.58,439.6 L51.58,428.603 L39.29,421.104 L39.29,432.002 Z M61.68,444.899 L73.98,452.497 L73.98,441.5 L61.68,434.001 L61.68,444.899 Z" id="Shape" fill="#3A5070"></path>
                <path d="M255.94,108.575 L243.64,116.074 L243.64,127.071 L255.94,119.473 L255.94,108.575 Z M233.44,121.473 L221.15,128.971 L221.15,139.968 L233.44,132.37 L233.44,121.473 Z M211.05,134.47 L198.75,141.968 L198.75,152.965 L211.05,145.367 L211.05,134.47 Z M153.86,167.862 L153.86,178.859 L166.16,171.261 L166.16,160.264 L153.86,167.862 Z M188.55,147.367 L176.26,154.865 L176.26,165.862 L188.55,158.264 L188.55,147.367 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M255.94,131.57 L243.64,139.069 L243.64,150.066 L255.94,142.468 L255.94,131.57 Z M233.44,144.567 L221.15,152.066 L221.15,163.063 L233.44,155.465 L233.44,144.567 Z M211.05,157.464 L198.75,164.963 L198.75,175.96 L211.05,168.362 L211.05,157.464 Z M153.86,190.857 L153.86,201.854 L166.16,194.256 L166.16,183.258 L153.86,190.857 Z M188.55,170.461 L176.26,177.96 L176.26,188.957 L188.55,181.359 L188.55,170.461 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M255.94,154.665 L243.64,162.163 L243.64,173.161 L255.94,165.563 L255.94,154.665 Z M233.44,167.562 L221.15,175.06 L221.15,186.058 L233.44,178.46 L233.44,167.562 Z M211.05,180.559 L198.75,188.057 L198.75,199.055 L211.05,191.457 L211.05,180.559 Z M153.86,213.952 L153.86,224.949 L166.16,217.351 L166.16,206.353 L153.86,213.952 Z M188.55,193.456 L176.26,200.954 L176.26,211.952 L188.55,204.354 L188.55,193.456 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M255.94,177.66 L243.64,185.158 L243.64,196.155 L255.94,188.557 L255.94,177.66 Z M233.44,190.657 L221.15,198.155 L221.15,209.152 L233.44,201.554 L233.44,190.657 Z M211.05,203.554 L198.75,211.052 L198.75,222.05 L211.05,214.451 L211.05,203.554 Z M153.86,236.946 L153.86,247.944 L166.16,240.345 L166.16,229.348 L153.86,236.946 Z M188.55,216.551 L176.26,224.049 L176.26,235.047 L188.55,227.448 L188.55,216.551 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M255.94,200.754 L243.64,208.253 L243.64,219.25 L255.94,211.652 L255.94,200.754 Z M233.44,213.652 L221.15,221.15 L221.15,232.147 L233.44,224.549 L233.44,213.652 Z M211.05,226.649 L198.75,234.147 L198.75,245.144 L211.05,237.546 L211.05,226.649 Z M153.86,260.041 L153.86,271.038 L166.16,263.44 L166.16,252.443 L153.86,260.041 Z M188.55,239.546 L176.26,247.044 L176.26,258.041 L188.55,250.443 L188.55,239.546 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M255.94,223.749 L243.64,231.248 L243.64,242.245 L255.94,234.647 L255.94,223.749 Z M233.44,236.746 L221.15,244.245 L221.15,255.242 L233.44,247.644 L233.44,236.746 Z M211.05,249.643 L198.75,257.142 L198.75,268.139 L211.05,260.541 L211.05,249.643 Z M153.86,283.036 L153.86,294.033 L166.16,286.435 L166.16,275.438 L153.86,283.036 Z M188.55,262.54 L176.26,270.039 L176.26,281.036 L188.55,273.438 L188.55,262.54 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M255.94,246.844 L243.64,254.342 L243.64,265.34 L255.94,257.741 L255.94,246.844 Z M233.44,259.741 L221.15,267.239 L221.15,278.237 L233.44,270.639 L233.44,259.741 Z M211.05,272.638 L198.75,280.136 L198.75,291.134 L211.05,283.536 L211.05,272.638 Z M153.86,306.13 L153.86,317.128 L166.16,309.53 L166.16,298.532 L153.86,306.13 Z M188.55,285.635 L176.26,293.133 L176.26,304.131 L188.55,296.533 L188.55,285.635 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M255.94,269.839 L243.64,277.337 L243.64,288.335 L255.94,280.736 L255.94,269.839 Z M233.44,282.836 L221.15,290.334 L221.15,301.332 L233.44,293.733 L233.44,282.836 Z M211.05,295.733 L198.75,303.231 L198.75,314.229 L211.05,306.63 L211.05,295.733 Z M153.86,329.125 L153.86,340.123 L166.16,332.525 L166.16,321.527 L153.86,329.125 Z M188.55,308.63 L176.26,316.128 L176.26,327.126 L188.55,319.528 L188.55,308.63 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M255.94,292.934 L243.64,300.432 L243.64,311.429 L255.94,303.831 L255.94,292.934 Z M233.44,305.831 L221.15,313.329 L221.15,324.326 L233.44,316.728 L233.44,305.831 Z M211.05,318.728 L198.75,326.226 L198.75,337.223 L211.05,329.625 L211.05,318.728 Z M153.86,352.22 L153.86,363.218 L166.16,355.619 L166.16,344.622 L153.86,352.22 Z M188.55,331.725 L176.26,339.223 L176.26,350.22 L188.55,342.622 L188.55,331.725 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M255.94,315.928 L243.64,323.426 L243.64,334.424 L255.94,326.826 L255.94,315.928 Z M233.44,328.825 L221.15,336.323 L221.15,347.321 L233.44,339.723 L233.44,328.825 Z M211.05,341.822 L198.75,349.321 L198.75,360.318 L211.05,352.72 L211.05,341.822 Z M153.86,375.215 L153.86,386.212 L166.16,378.614 L166.16,367.616 L153.86,375.215 Z M188.55,354.719 L176.26,362.318 L176.26,373.315 L188.55,365.717 L188.55,354.719 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M255.94,339.023 L243.64,346.521 L243.64,357.519 L255.94,349.92 L255.94,339.023 Z M233.44,351.92 L221.15,359.418 L221.15,370.416 L233.44,362.817 L233.44,351.92 Z M211.05,364.817 L198.75,372.315 L198.75,383.313 L211.05,375.714 L211.05,364.817 Z M153.86,398.309 L153.86,409.307 L166.16,401.709 L166.16,390.711 L153.86,398.309 Z M188.55,377.814 L176.26,385.312 L176.26,396.31 L188.55,388.712 L188.55,377.814 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M255.94,362.018 L243.64,369.516 L243.64,380.513 L255.94,372.915 L255.94,362.018 Z M233.44,374.915 L221.15,382.413 L221.15,393.411 L233.44,385.812 L233.44,374.915 Z M211.05,387.912 L198.75,395.41 L198.75,406.408 L211.05,398.809 L211.05,387.912 Z M153.86,421.304 L153.86,432.302 L166.16,424.703 L166.16,413.706 L153.86,421.304 Z M188.55,400.809 L176.26,408.307 L176.26,419.305 L188.55,411.706 L188.55,400.809 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M255.94,385.012 L243.64,392.511 L243.64,403.508 L255.94,395.91 L255.94,385.012 Z M233.44,398.009 L221.15,405.508 L221.15,416.505 L233.44,408.907 L233.44,398.009 Z M211.05,410.906 L198.75,418.405 L198.75,429.402 L211.05,421.804 L211.05,410.906 Z M153.86,444.299 L153.86,455.296 L166.16,447.698 L166.16,436.701 L153.86,444.299 Z M188.55,423.903 L176.26,431.402 L176.26,442.399 L188.55,434.801 L188.55,423.903 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M255.94,408.107 L243.64,415.605 L243.64,426.603 L255.94,419.005 L255.94,408.107 Z M233.44,421.004 L221.15,428.503 L221.15,439.5 L233.44,431.902 L233.44,421.004 Z M211.05,434.001 L198.75,441.5 L198.75,452.497 L211.05,444.899 L211.05,434.001 Z M153.86,467.394 L153.86,478.391 L166.16,470.793 L166.16,459.795 L153.86,467.394 Z M188.55,446.898 L176.26,454.397 L176.26,465.394 L188.55,457.796 L188.55,446.898 Z" id="Shape" fill="#1D2F52"></path>
                <polygon id="Path" fill="#12233D" points="134.27 125.772 134.27 144.667 243.54 81.782 243.54 62.886"></polygon>
                <polygon id="Path" fill="#2A3B56" points="134.27 125.772 134.27 144.667 26.49 81.782 26.49 62.986"></polygon>
                <polygon id="Path" fill="#192945" opacity="0.5" points="134.27 125.772 134.27 130.37 243.54 67.585 243.54 62.886"></polygon>
                <polygon id="Path" fill="#3A5070" opacity="0.5" points="134.27 125.772 134.27 130.37 26.49 67.585 26.49 62.986"></polygon>
                <polygon id="Path" fill="#3A5070" points="26.49 62.986 134.27 125.772 243.54 62.886 135.67 1.42108547e-14"></polygon>
                <polygon id="Path" fill="#2A3B56" points="200.45 63.386 133.97 24.595 67.28 62.986 133.97 101.777"></polygon>
                <polygon id="Path" fill="#2A3B56" points="133.97 36.092 133.97 24.595 67.28 62.986 77.18 68.784"></polygon>
                <polygon id="Path" fill="#12233D" points="133.97 36.092 190.65 69.084 200.45 63.386 133.97 24.595"></polygon>
                <polygon id="Path" fill="#3A5070" points="77.58 68.485 133.47 101.077 190.15 68.485 134.17 35.892"></polygon>
            </g>
        </motion.g>
        <motion.g variants={getSlideTopAnimation(Math.floor(Math.random() * 2) + 1.1)} id="block-4" mask="url(#mask-2)" fill-rule="nonzero">
            <g transform="translate(104.762400, 254.440000)" id="Path">
                <polygon fill="#12233D" points="135.07 595.964 272.238 517.082 272.238 78.782 135.07 157.664"></polygon>
                <polygon fill="#2A3B56" points="2.84217094e-14 517.082 135.17 595.964 135.17 157.664 2.84217094e-14 78.882"></polygon>
                <polygon fill="#3A5070" points="2.84217094e-14 78.882 135.17 157.664 272.238 78.782 136.869 0"></polygon>
            </g>
        </motion.g>
        <motion.g variants={getSlideTopAnimation(Math.floor(Math.random() * 2) + 1.1)} id="block-13" mask="url(#mask-2)" fill-rule="nonzero">
            <g transform="translate(477.677400, 347.000000)">
                <polygon id="Path" fill="#12233D" points="135.069 331.525 365.817 196.955 365.817 78.882 135.069 213.352"></polygon>
                <polygon id="Path" fill="#2A3B56" points="-5.68434189e-14 252.643 135.169 331.525 135.169 213.351 -5.68434189e-14 134.669"></polygon>
                <polygon id="Path" fill="#3A5070" points="0 134.669 135.169 213.351 365.817 78.882 230.448 0"></polygon>
                <path d="M150.366,222.649 L150.366,233.647 L162.663,226.049 L162.663,215.051 L150.366,222.649 Z M252.443,163.363 L240.145,170.861 L240.145,181.859 L252.443,174.26 L252.443,163.363 Z M230.048,176.26 L217.75,183.758 L217.75,194.756 L230.048,187.157 L230.048,176.26 Z M207.553,189.257 L195.256,196.755 L195.256,207.753 L207.553,200.155 L207.553,189.257 Z M185.158,202.154 L172.861,209.652 L172.861,220.65 L185.158,213.052 L185.158,202.154 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M343.222,110.375 L330.925,117.873 L330.925,128.871 L343.222,121.273 L343.222,110.375 Z M320.827,123.272 L308.53,130.77 L308.53,141.768 L320.827,134.17 L320.827,123.272 Z M298.332,136.169 L286.035,143.667 L286.035,154.665 L298.332,147.067 L298.332,136.169 Z M275.937,149.166 L263.64,156.664 L263.64,167.662 L275.937,160.064 L275.937,149.166 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M150.366,245.144 L150.366,256.142 L162.663,248.544 L162.663,237.546 L150.366,245.144 Z M252.443,185.858 L240.145,193.356 L240.145,204.354 L252.443,196.755 L252.443,185.858 Z M230.048,198.755 L217.75,206.253 L217.75,217.251 L230.048,209.652 L230.048,198.755 Z M207.553,211.752 L195.256,219.25 L195.256,230.248 L207.553,222.65 L207.553,211.752 Z M185.158,224.649 L172.861,232.147 L172.861,243.145 L185.158,235.547 L185.158,224.649 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M343.222,132.87 L330.925,140.368 L330.925,151.366 L343.222,143.767 L343.222,132.87 Z M320.827,145.767 L308.53,153.265 L308.53,164.263 L320.827,156.664 L320.827,145.767 Z M298.332,158.664 L286.035,166.162 L286.035,177.16 L298.332,169.562 L298.332,158.664 Z M275.937,171.661 L263.64,179.159 L263.64,190.157 L275.937,182.559 L275.937,171.661 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M150.366,266.939 L150.366,277.937 L162.663,270.339 L162.663,259.341 L150.366,266.939 Z M252.443,207.653 L240.145,215.151 L240.145,226.149 L252.443,218.55 L252.443,207.653 Z M230.048,220.55 L217.75,228.048 L217.75,239.046 L230.048,231.447 L230.048,220.55 Z M207.553,233.547 L195.256,241.045 L195.256,252.043 L207.553,244.444 L207.553,233.547 Z M185.158,246.444 L172.861,253.942 L172.861,264.94 L185.158,257.341 L185.158,246.444 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M343.222,154.665 L330.925,162.163 L330.925,173.161 L343.222,165.562 L343.222,154.665 Z M320.827,167.562 L308.53,175.06 L308.53,186.058 L320.827,178.459 L320.827,167.562 Z M298.332,180.459 L286.035,187.957 L286.035,198.955 L298.332,191.357 L298.332,180.459 Z M275.937,193.456 L263.64,200.954 L263.64,211.952 L275.937,204.354 L275.937,193.456 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M150.366,287.235 L150.366,322.227 L162.663,314.629 L162.663,279.736 L150.366,287.235 Z M252.443,227.948 L240.145,235.447 L240.145,270.439 L252.443,262.84 L252.443,227.948 Z M230.048,240.945 L217.75,248.444 L217.75,283.436 L230.048,275.837 L230.048,240.945 Z M207.553,253.842 L195.256,261.341 L195.256,296.333 L207.553,288.734 L207.553,253.842 Z M185.158,266.839 L172.861,274.338 L172.861,309.33 L185.158,301.731 L185.158,266.839 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M343.222,174.96 L330.925,182.459 L330.925,217.451 L343.222,209.852 L343.222,174.96 Z M320.827,187.857 L308.53,195.356 L308.53,230.348 L320.827,222.749 L320.827,187.857 Z M298.332,200.854 L286.035,208.353 L286.035,243.345 L298.332,235.746 L298.332,200.854 Z M275.937,213.752 L263.64,221.25 L263.64,256.242 L275.937,248.644 L275.937,213.752 Z" id="Shape" fill="#1D2F52"></path>
                <polygon id="Path" fill="#12233D" points="328.125 80.382 230.248 23.195 36.692 134.669 134.669 191.956"></polygon>
                <polygon id="Path" fill="#1D2F52" points="230.248 34.692 230.248 23.195 36.692 134.669 46.589 140.468"></polygon>
                <polygon id="Path" fill="#12233D" points="230.248 34.692 318.328 86.081 328.126 80.382 230.248 23.195"></polygon>
                <polygon id="Path" fill="#3A5070" points="46.989 140.268 134.27 191.157 317.828 85.481 230.548 34.492"></polygon>
            </g>
        </motion.g>
        <motion.g variants={getSlideTopAnimation(Math.floor(Math.random() * 2) + 1.1)} id="block-2" mask="url(#mask-2)" fill-rule="nonzero">
            <g transform="translate(310.315400, 162.361000)" id="Path">
                <polygon fill="#12233D" points="135.07 589.465 272.138 510.683 272.138 78.782 135.07 157.664"></polygon>
                <polygon fill="#2A3B56" points="2.84217094e-14 510.683 135.17 589.465 135.17 157.664 2.84217094e-14 78.882"></polygon>
                <polygon fill="#3A5070" points="8.52651283e-14 78.882 135.17 157.664 272.138 78.782 136.869 0"></polygon>
                <polygon fill="#3A5070" points="118.073 169.661 15.697 110.075 15.697 120.072 118.073 179.659"></polygon>
                <polygon fill="#3A5070" points="118.073 195.055 15.697 135.369 15.697 145.367 118.073 204.953"></polygon>
                <polygon fill="#3A5070" points="118.073 220.349 15.697 160.763 15.697 170.661 118.073 230.347"></polygon>
                <polygon fill="#3A5070" points="118.073 245.744 15.697 186.057 15.697 196.055 118.073 255.641"></polygon>
                <motion.polygon variants={lightsAnimation(getRandomDuration, getRandomDelay)} className="window" fill="#3A5070" points="118.073 271.038 15.697 211.452 15.697 221.349 118.073 281.036"></motion.polygon>
                <polygon fill="#3A5070" points="118.073 296.332 15.697 236.746 15.697 246.743 118.073 306.33"></polygon>
                <polygon fill="#3A5070" points="118.073 321.726 15.697 262.14 15.697 272.038 118.073 331.624"></polygon>
                <polygon fill="#3A5070" points="118.073 347.021 15.697 287.434 15.697 297.432 118.073 357.018"></polygon>
                <polygon fill="#3A5070" points="118.073 372.415 15.697 312.728 15.697 322.726 118.073 382.313"></polygon>
                <polygon fill="#3A5070" points="118.073 397.709 15.697 338.123 15.697 348.02 118.073 407.707"></polygon>
                <polygon fill="#3A5070" points="118.073 423.103 15.697 363.417 15.697 373.415 118.073 433.001"></polygon>
                <polygon fill="#3A5070" points="118.073 448.398 15.697 388.811 15.697 398.709 118.073 458.395"></polygon>
                <polygon fill="#3A5070" points="118.073 473.692 15.697 414.105 15.697 424.103 118.073 483.689"></polygon>
                <polygon fill="#3A5070" points="118.073 499.086 15.697 439.5 15.697 449.397 118.073 508.984"></polygon>
                <polygon fill="#3A5070" points="118.073 524.38 15.697 464.794 15.697 474.691 118.073 534.378"></polygon>
                <polygon fill="#3A5070" points="118.073 549.774 15.697 490.088 15.697 500.086 118.073 559.672"></polygon>
                <polygon fill="#1D2F52" points="153.765 169.661 256.142 110.075 256.142 120.072 153.765 179.659"></polygon>
                <polygon fill="#1D2F52" points="153.765 195.055 256.142 135.369 256.142 145.367 153.765 204.953"></polygon>
                <polygon fill="#1D2F52" points="153.765 220.349 256.142 160.763 256.142 170.661 153.765 230.347"></polygon>
                <polygon fill="#1D2F52" points="153.765 245.744 256.142 186.057 256.142 196.055 153.765 255.641"></polygon>
                <polygon fill="#1D2F52" points="153.765 271.038 256.142 211.452 256.142 221.349 153.765 281.036"></polygon>
                <polygon fill="#1D2F52" points="153.765 296.332 256.142 236.746 256.142 246.743 153.765 306.33"></polygon>
                <polygon fill="#1D2F52" points="153.765 321.726 256.142 262.14 256.142 272.038 153.765 331.624"></polygon>
                <polygon fill="#1D2F52" points="153.765 347.021 256.142 287.434 256.142 297.432 153.765 357.018"></polygon>
                <polygon fill="#1D2F52" points="153.765 372.415 256.142 312.728 256.142 322.726 153.765 382.313"></polygon>
                <polygon fill="#1D2F52" points="153.765 397.709 256.142 338.123 256.142 348.02 153.765 407.707"></polygon>
                <polygon fill="#1D2F52" points="153.765 423.103 256.142 363.417 256.142 373.415 153.765 433.001"></polygon>
                <polygon fill="#1D2F52" points="153.765 448.398 256.142 388.811 256.142 398.709 153.765 458.395"></polygon>
                <polygon fill="#1D2F52" points="153.765 473.692 256.142 414.105 256.142 424.103 153.765 483.689"></polygon>
                <polygon fill="#1D2F52" points="153.765 499.086 256.142 439.5 256.142 449.397 153.765 508.984"></polygon>
                <polygon fill="#1D2F52" points="153.765 524.38 256.142 464.794 256.142 474.691 153.765 534.378"></polygon>
                <polygon fill="#1D2F52" points="153.765 549.774 256.142 490.088 256.142 500.086 153.765 559.672"></polygon>
                <polygon fill="#12233D" points="229.448 78.982 136.769 24.994 43.99 78.482 136.769 132.47"></polygon>
                <polygon fill="#1D2F52" points="136.869 40.99 136.869 25.094 136.769 24.994 43.99 78.482 57.787 86.48"></polygon>
                <polygon fill="#12233D" points="136.769 40.991 215.651 86.88 229.448 78.982 136.769 24.994"></polygon>
                <polygon fill="#3A5070" points="58.387 86.08 136.169 131.47 215.051 86.08 137.169 40.691"></polygon>
            </g>
        </motion.g>
        <motion.g variants={getSlideTopAnimation(Math.floor(Math.random() * 2) + 1.1)} id="block-11" mask="url(#mask-2)" fill-rule="nonzero">
            <g transform="translate(1520.000000, 355.000000)" id="Path">
                <polygon fill="#12233D" points="64.68 512.183 206.05 430.802 206.05 68.084 64.68 149.366"></polygon>
                <polygon fill="#2A3B56" points="3.41060513e-13 475.092 64.78 512.183 64.78 149.366 3.41060513e-13 112.374"></polygon>
                <polygon fill="#3A5070" points="1.13686838e-13 112.374 64.78 149.366 206.05 68.084 141.16 30.993"></polygon>
                <polygon fill="#12233D" points="132.57 565.571 290.23 474.892 290.23 41.29 132.57 131.97"></polygon>
                <polygon fill="#2A3B56" points="60.48 524.18 132.67 565.571 132.67 131.97 60.48 90.679"></polygon>
                <polygon fill="#3A5070" points="60.48 90.679 132.67 131.97 290.23 41.291 217.85 2.84217094e-14"></polygon>
                <polygon fill="#12233D" points="208.55 596.264 349.92 514.983 349.92 152.165 208.55 233.447"></polygon>
                <polygon fill="#2A3B56" points="143.86 559.173 208.65 596.264 208.65 233.447 143.86 196.455"></polygon>
                <polygon fill="#3A5070" points="143.86 196.455 208.65 233.447 349.92 152.165 285.03 115.074"></polygon>
                <polygon fill="#1D2F52" points="244.14 243.144 236.94 247.343 236.94 239.045 244.14 234.846"></polygon>
                <polygon fill="#1D2F52" points="257.74 235.346 250.54 239.445 250.54 231.147 257.74 227.048"></polygon>
                <polygon fill="#1D2F52" points="271.33 227.448 264.03 231.647 264.03 223.349 271.33 219.15"></polygon>
                <polygon fill="#1D2F52" points="284.83 219.65 277.63 223.849 277.63 215.551 284.83 211.352"></polygon>
                <polygon fill="#1D2F52" points="298.43 211.851 291.23 215.951 291.23 207.652 298.43 203.553"></polygon>
                <polygon fill="#1D2F52" points="312.02 203.953 304.73 208.152 304.73 199.854 312.02 195.655"></polygon>
                <polygon fill="#1D2F52" points="325.52 196.155 318.32 200.354 318.32 192.056 325.52 187.857"></polygon>
                <polygon fill="#1D2F52" points="244.14 260.64 236.94 264.839 236.94 256.541 244.14 252.342"></polygon>
                <polygon fill="#1D2F52" points="257.74 252.842 250.54 257.041 250.54 248.743 257.74 244.544"></polygon>
                <polygon fill="#1D2F52" points="271.33 245.044 264.03 249.143 264.03 240.845 271.33 236.746"></polygon>
                <polygon fill="#1D2F52" points="284.83 237.146 277.63 241.345 277.63 233.047 284.83 228.848"></polygon>
                <polygon fill="#1D2F52" points="298.43 229.347 291.23 233.546 291.23 225.248 298.43 221.049"></polygon>
                <polygon fill="#1D2F52" points="312.02 221.549 304.73 225.648 304.73 217.35 312.02 213.251"></polygon>
                <polygon fill="#1D2F52" points="325.52 213.651 318.32 217.85 318.32 209.552 325.52 205.353"></polygon>
                <polygon fill="#1D2F52" points="244.14 278.236 236.94 282.336 236.94 274.037 244.14 269.938"></polygon>
                <polygon fill="#1D2F52" points="257.74 270.338 250.54 274.537 250.54 266.239 257.74 262.04"></polygon>
                <polygon fill="#1D2F52" points="271.33 262.54 264.03 266.739 264.03 258.441 271.33 254.242"></polygon>
                <polygon fill="#1D2F52" points="284.83 254.742 277.63 258.841 277.63 250.643 284.83 246.444"></polygon>
                <polygon fill="#1D2F52" points="298.43 246.843 291.23 251.042 291.23 242.744 298.43 238.645"></polygon>
                <polygon fill="#1D2F52" points="312.02 239.045 304.73 243.244 304.73 234.946 312.02 230.747"></polygon>
                <polygon fill="#1D2F52" points="325.52 231.247 318.32 235.346 318.32 227.148 325.52 222.949"></polygon>
                <polygon fill="#1D2F52" points="244.14 295.732 236.94 299.931 236.94 291.633 244.14 287.434"></polygon>
                <polygon fill="#1D2F52" points="257.74 287.934 250.54 292.033 250.54 283.835 257.74 279.636"></polygon>
                <polygon fill="#1D2F52" points="271.33 280.036 264.03 284.235 264.03 275.937 271.33 271.838"></polygon>
                <polygon fill="#1D2F52" points="284.83 272.238 277.63 276.437 277.63 268.139 284.83 263.94"></polygon>
                <polygon fill="#1D2F52" points="298.43 264.439 291.23 268.538 291.23 260.34 298.43 256.141"></polygon>
                <polygon fill="#1D2F52" points="312.02 256.541 304.73 260.74 304.73 252.442 312.02 248.343"></polygon>
                <polygon fill="#1D2F52" points="325.52 248.743 318.32 252.942 318.32 244.644 325.52 240.445"></polygon>
                <polygon fill="#1D2F52" points="244.14 313.229 236.94 317.428 236.94 309.13 244.14 305.03"></polygon>
                <polygon fill="#1D2F52" points="257.74 305.43 250.54 309.629 250.54 301.331 257.74 297.132"></polygon>
                <polygon fill="#1D2F52" points="271.33 297.632 264.03 301.731 264.03 293.533 271.33 289.334"></polygon>
                <polygon fill="#1D2F52" points="284.83 289.734 277.63 293.933 277.63 285.635 284.83 281.536"></polygon>
                <polygon fill="#1D2F52" points="298.43 281.935 291.23 286.135 291.23 277.836 298.43 273.637"></polygon>
                <polygon fill="#1D2F52" points="312.02 274.137 304.73 278.336 304.73 270.038 312.02 265.839"></polygon>
                <polygon fill="#1D2F52" points="325.52 266.339 318.32 270.438 318.32 262.14 325.52 258.041"></polygon>
                <polygon fill="#1D2F52" points="244.14 330.824 236.94 335.023 236.94 326.725 244.14 322.526"></polygon>
                <polygon fill="#1D2F52" points="257.74 323.026 250.54 327.125 250.54 318.827 257.74 314.728"></polygon>
                <polygon fill="#1D2F52" points="271.33 315.128 264.03 319.327 264.03 311.029 271.33 306.83"></polygon>
                <polygon fill="#1D2F52" points="284.83 307.33 277.63 311.529 277.63 303.231 284.83 299.032"></polygon>
                <polygon fill="#1D2F52" points="298.43 299.532 291.23 303.631 291.23 295.333 298.43 291.233"></polygon>
                <polygon fill="#1D2F52" points="312.02 291.633 304.73 295.832 304.73 287.534 312.02 283.335"></polygon>
                <polygon fill="#1D2F52" points="325.52 283.835 318.32 288.034 318.32 279.736 325.52 275.537"></polygon>
                <polygon fill="#1D2F52" points="244.14 348.32 236.94 352.519 236.94 344.221 244.14 340.022"></polygon>
                <polygon fill="#1D2F52" points="257.74 340.522 250.54 344.721 250.54 336.423 257.74 332.224"></polygon>
                <polygon fill="#1D2F52" points="271.33 332.724 264.03 336.823 264.03 328.525 271.33 324.426"></polygon>
                <polygon fill="#1D2F52" points="284.83 324.826 277.63 329.025 277.63 320.727 284.83 316.528"></polygon>
                <polygon fill="#1D2F52" points="298.43 317.028 291.23 321.227 291.23 312.929 298.43 308.73"></polygon>
                <polygon fill="#1D2F52" points="312.02 309.229 304.73 313.328 304.73 305.03 312.02 300.931"></polygon>
                <polygon fill="#1D2F52" points="325.52 301.331 318.32 305.53 318.32 297.232 325.52 293.033"></polygon>
                <polygon fill="#1D2F52" points="244.14 365.916 236.94 370.015 236.94 361.717 244.14 357.618"></polygon>
                <polygon fill="#1D2F52" points="257.74 358.018 250.54 362.217 250.54 353.919 257.74 349.72"></polygon>
                <polygon fill="#1D2F52" points="271.33 350.22 264.03 354.419 264.03 346.121 271.33 341.922"></polygon>
                <polygon fill="#1D2F52" points="284.83 342.422 277.63 346.521 277.63 338.223 284.83 334.124"></polygon>
                <polygon fill="#1D2F52" points="298.43 334.523 291.23 338.723 291.23 330.424 298.43 326.225"></polygon>
                <polygon fill="#1D2F52" points="312.02 326.725 304.73 330.924 304.73 322.626 312.02 318.427"></polygon>
                <polygon fill="#1D2F52" points="325.52 318.927 318.32 323.026 318.32 314.828 325.52 310.629"></polygon>
                <polygon fill="#1D2F52" points="244.14 383.412 236.94 387.611 236.94 379.313 244.14 375.114"></polygon>
                <polygon fill="#1D2F52" points="257.74 375.614 250.54 379.713 250.54 371.515 257.74 367.316"></polygon>
                <polygon fill="#1D2F52" points="271.33 367.716 264.03 371.915 264.03 363.617 271.33 359.518"></polygon>
                <polygon fill="#1D2F52" points="284.83 359.918 277.63 364.117 277.63 355.819 284.83 351.62"></polygon>
                <polygon fill="#1D2F52" points="298.43 352.119 291.23 356.218 291.23 348.02 298.43 343.821"></polygon>
                <polygon fill="#1D2F52" points="312.02 344.221 304.73 348.42 304.73 340.122 312.02 336.023"></polygon>
                <polygon fill="#1D2F52" points="325.52 336.423 318.32 340.622 318.32 332.324 325.52 328.125"></polygon>
                <polygon fill="#1D2F52" points="244.14 400.908 236.94 405.107 236.94 396.809 244.14 392.71"></polygon>
                <polygon fill="#1D2F52" points="257.74 393.11 250.54 397.309 250.54 389.011 257.74 384.812"></polygon>
                <polygon fill="#1D2F52" points="271.33 385.312 264.03 389.411 264.03 381.213 271.33 377.014"></polygon>
                <polygon fill="#1D2F52" points="284.83 377.414 277.63 381.613 277.63 373.315 284.83 369.216"></polygon>
                <polygon fill="#1D2F52" points="298.43 369.616 291.23 373.815 291.23 365.517 298.43 361.318"></polygon>
                <polygon fill="#1D2F52" points="312.02 361.817 304.73 366.016 304.73 357.718 312.02 353.519"></polygon>
                <polygon fill="#1D2F52" points="325.52 353.919 318.32 358.118 318.32 349.82 325.52 345.721"></polygon>
                <polygon fill="#1D2F52" points="244.14 418.504 236.94 422.703 236.94 414.405 244.14 410.206"></polygon>
                <polygon fill="#1D2F52" points="257.74 410.606 250.54 414.805 250.54 406.507 257.74 402.408"></polygon>
                <polygon fill="#1D2F52" points="271.33 402.808 264.03 407.007 264.03 398.709 271.33 394.51"></polygon>
                <polygon fill="#1D2F52" points="284.83 395.01 277.63 399.209 277.63 390.911 284.83 386.712"></polygon>
                <polygon fill="#1D2F52" points="298.43 387.211 291.23 391.31 291.23 383.012 298.43 378.913"></polygon>
                <polygon fill="#1D2F52" points="312.02 379.314 304.73 383.513 304.73 375.214 312.02 371.015"></polygon>
                <polygon fill="#1D2F52" points="325.52 371.515 318.32 375.714 318.32 367.416 325.52 363.217"></polygon>
                <polygon fill="#1D2F52" points="244.14 436 236.94 440.199 236.94 431.901 244.14 427.702"></polygon>
                <polygon fill="#1D2F52" points="257.74 428.202 250.54 432.401 250.54 424.103 257.74 419.904"></polygon>
                <polygon fill="#1D2F52" points="271.33 420.404 264.03 424.503 264.03 416.205 271.33 412.106"></polygon>
                <polygon fill="#1D2F52" points="284.83 412.506 277.63 416.705 277.63 408.407 284.83 404.208"></polygon>
                <polygon fill="#1D2F52" points="298.43 404.707 291.23 408.906 291.23 400.608 298.43 396.409"></polygon>
                <polygon fill="#1D2F52" points="312.02 396.909 304.73 401.008 304.73 392.71 312.02 388.611"></polygon>
                <polygon fill="#1D2F52" points="325.52 389.011 318.32 393.21 318.32 384.912 325.52 380.713"></polygon>
                <polygon fill="#1D2F52" points="244.14 453.596 236.94 457.695 236.94 449.397 244.14 445.298"></polygon>
                <polygon fill="#1D2F52" points="257.74 445.698 250.54 449.897 250.54 441.599 257.74 437.4"></polygon>
                <polygon fill="#1D2F52" points="271.33 437.9 264.03 442.099 264.03 433.801 271.33 429.602"></polygon>
                <polygon fill="#1D2F52" points="284.83 430.102 277.63 434.201 277.63 425.903 284.83 421.804"></polygon>
                <polygon fill="#1D2F52" points="298.43 422.204 291.23 426.403 291.23 418.104 298.43 413.905"></polygon>
                <polygon fill="#1D2F52" points="312.02 414.405 304.73 418.604 304.73 410.306 312.02 406.107"></polygon>
                <polygon fill="#1D2F52" points="325.52 406.607 318.32 410.706 318.32 402.508 325.52 398.309"></polygon>
                <polygon fill="#1D2F52" points="244.14 471.092 236.94 475.291 236.94 466.993 244.14 462.794"></polygon>
                <polygon fill="#1D2F52" points="257.74 463.294 250.54 467.393 250.54 459.195 257.74 454.996"></polygon>
                <polygon fill="#1D2F52" points="271.33 455.396 264.03 459.595 264.03 451.297 271.33 447.098"></polygon>
                <polygon fill="#1D2F52" points="284.83 447.598 277.63 451.797 277.63 443.499 284.83 439.3"></polygon>
                <polygon fill="#1D2F52" points="298.43 439.8 291.23 443.899 291.23 435.701 298.43 431.501"></polygon>
                <polygon fill="#1D2F52" points="312.02 431.902 304.73 436.101 304.73 427.802 312.02 423.703"></polygon>
                <polygon fill="#1D2F52" points="325.52 424.103 318.32 428.302 318.32 420.004 325.52 415.805"></polygon>
                <polygon fill="#1D2F52" points="244.14 488.588 236.94 492.787 236.94 484.489 244.14 480.39"></polygon>
                <polygon fill="#1D2F52" points="257.74 480.79 250.54 484.989 250.54 476.691 257.74 472.492"></polygon>
                <polygon fill="#1D2F52" points="271.33 472.992 264.03 477.091 264.03 468.893 271.33 464.694"></polygon>
                <polygon fill="#1D2F52" points="284.83 465.094 277.63 469.293 277.63 460.995 284.83 456.896"></polygon>
                <polygon fill="#1D2F52" points="298.43 457.296 291.23 461.495 291.23 453.197 298.43 448.997"></polygon>
                <polygon fill="#1D2F52" points="312.02 449.497 304.73 453.597 304.73 445.398 312.02 441.199"></polygon>
                <polygon fill="#1D2F52" points="325.52 441.599 318.32 445.798 318.32 437.5 325.52 433.401"></polygon>
                <polygon fill="#1D2F52" points="244.14 506.184 236.94 510.284 236.94 502.085 244.14 497.886"></polygon>
                <polygon fill="#1D2F52" points="257.74 498.286 250.54 502.485 250.54 494.187 257.74 490.088"></polygon>
                <polygon fill="#1D2F52" points="271.33 490.488 264.03 494.687 264.03 486.389 271.33 482.19"></polygon>
                <polygon fill="#1D2F52" points="284.83 482.69 277.63 486.889 277.63 478.591 284.83 474.392"></polygon>
                <polygon fill="#1D2F52" points="298.43 474.891 291.23 478.99 291.23 470.692 298.43 466.593"></polygon>
                <polygon fill="#1D2F52" points="312.02 466.994 304.73 471.193 304.73 462.895 312.02 458.695"></polygon>
                <polygon fill="#1D2F52" points="325.52 459.195 318.32 463.394 318.32 455.096 325.52 450.897"></polygon>
                <polygon fill="#1D2F52" points="244.14 523.68 236.94 527.879 236.94 519.581 244.14 515.382"></polygon>
                <polygon fill="#1D2F52" points="257.74 515.882 250.54 520.081 250.54 511.783 257.74 507.584"></polygon>
                <polygon fill="#1D2F52" points="271.33 508.084 264.03 512.183 264.03 503.885 271.33 499.786"></polygon>
                <polygon fill="#1D2F52" points="284.83 500.186 277.63 504.385 277.63 496.087 284.83 491.888"></polygon>
                <polygon fill="#1D2F52" points="298.43 492.387 291.23 496.587 291.23 488.288 298.43 484.089"></polygon>
                <polygon fill="#1D2F52" points="312.02 484.589 304.73 488.688 304.73 480.39 312.02 476.291"></polygon>
                <polygon fill="#1D2F52" points="325.52 476.691 318.32 480.89 318.32 472.592 325.52 468.393"></polygon>
                <polygon fill="#1D2F52" points="244.14 541.276 236.94 545.375 236.94 537.077 244.14 532.978"></polygon>
                <polygon fill="#1D2F52" points="257.74 533.378 250.54 537.577 250.54 529.279 257.74 525.08"></polygon>
                <polygon fill="#1D2F52" points="271.33 525.58 264.03 529.779 264.03 521.481 271.33 517.282"></polygon>
                <polygon fill="#1D2F52" points="284.83 517.782 277.63 521.881 277.63 513.583 284.83 509.484"></polygon>
                <polygon fill="#1D2F52" points="298.43 509.883 291.23 514.082 291.23 505.784 298.43 501.585"></polygon>
                <polygon fill="#1D2F52" points="312.02 502.085 304.73 506.284 304.73 497.986 312.02 493.787"></polygon>
                <polygon fill="#1D2F52" points="325.52 494.287 318.32 498.386 318.32 490.088 325.52 485.989"></polygon>
                <polygon fill="#1D2F52" points="244.14 558.773 236.94 562.972 236.94 554.673 244.14 550.474"></polygon>
                <polygon fill="#1D2F52" points="257.74 550.974 250.54 555.073 250.54 546.775 257.74 542.676"></polygon>
                <polygon fill="#1D2F52" points="271.33 543.076 264.03 547.275 264.03 538.977 271.33 534.778"></polygon>
                <polygon fill="#1D2F52" points="284.83 535.278 277.63 539.477 277.63 531.179 284.83 526.98"></polygon>
                <polygon fill="#1D2F52" points="298.43 527.48 291.23 531.579 291.23 523.381 298.43 519.182"></polygon>
                <polygon fill="#1D2F52" points="312.02 519.581 304.73 523.78 304.73 515.482 312.02 511.383"></polygon>
                <polygon fill="#1D2F52" points="325.52 511.783 318.32 515.982 318.32 507.684 325.52 503.485"></polygon>
                <polygon fill="#3A5070" points="43.49 167.462 50.78 171.661 50.78 163.363 43.49 159.264"></polygon>
                <polygon fill="#3A5070" points="29.99 159.663 37.19 163.862 37.19 155.564 29.99 151.365"></polygon>
                <polygon fill="#3A5070" points="16.39 151.865 23.59 155.964 23.59 147.766 16.39 143.567"></polygon>
                <polygon fill="#3A5070" points="43.49 185.058 50.78 189.157 50.78 180.959 43.49 176.76"></polygon>
                <polygon fill="#3A5070" points="29.99 177.16 37.19 181.359 37.19 173.06 29.99 168.961"></polygon>
                <polygon fill="#3A5070" points="16.39 169.361 23.59 173.56 23.59 165.262 16.39 161.063"></polygon>
                <polygon fill="#3A5070" points="43.49 202.554 50.78 206.753 50.78 198.454 43.49 194.255"></polygon>
                <polygon fill="#3A5070" points="29.99 194.755 37.19 198.955 37.19 190.656 29.99 186.457"></polygon>
                <polygon fill="#3A5070" points="16.39 186.957 23.59 191.056 23.59 182.758 16.39 178.659"></polygon>
                <polygon fill="#3A5070" points="43.49 220.15 50.78 224.249 50.78 215.951 43.49 211.851"></polygon>
                <polygon fill="#3A5070" points="29.99 212.252 37.19 216.451 37.19 208.152 29.99 203.953"></polygon>
                <polygon fill="#3A5070" points="16.39 204.453 23.59 208.652 23.59 200.354 16.39 196.155"></polygon>
                <polygon fill="#3A5070" points="43.49 237.646 50.78 241.845 50.78 233.546 43.49 229.347"></polygon>
                <polygon fill="#3A5070" points="29.99 229.847 37.19 233.946 37.19 225.648 29.99 221.549"></polygon>
                <polygon fill="#3A5070" points="16.39 221.949 23.59 226.148 23.59 217.85 16.39 213.651"></polygon>
                <polygon fill="#3A5070" points="43.49 255.142 50.78 259.341 50.78 251.043 43.49 246.943"></polygon>
                <polygon fill="#3A5070" points="29.99 247.343 37.19 251.542 37.19 243.244 29.99 239.045"></polygon>
                <polygon fill="#3A5070" points="16.39 239.545 23.59 243.644 23.59 235.446 16.39 231.247"></polygon>
                <polygon fill="#3A5070" points="43.49 272.738 50.78 276.837 50.78 268.639 43.49 264.44"></polygon>
                <polygon fill="#3A5070" points="29.99 264.839 37.19 269.038 37.19 260.74 29.99 256.641"></polygon>
                <polygon fill="#3A5070" points="16.39 257.041 23.59 261.24 23.59 252.942 16.39 248.743"></polygon>
                <motion.polygon variants={lightsAnimation(getRandomDuration, getRandomDelay)} className="window" fill="#3A5070" points="43.49 290.234 50.78 294.433 50.78 286.135 43.49 281.936"></motion.polygon>
                <polygon fill="#3A5070" points="29.99 282.435 37.19 286.634 37.19 278.336 29.99 274.137"></polygon>
                <polygon fill="#3A5070" points="16.39 274.637 23.59 278.736 23.59 270.438 16.39 266.339"></polygon>
                <polygon fill="#3A5070" points="43.49 307.83 50.78 311.929 50.78 303.631 43.49 299.532"></polygon>
                <polygon fill="#3A5070" points="29.99 299.931 37.19 304.13 37.19 295.832 29.99 291.633"></polygon>
                <polygon fill="#3A5070" points="16.39 292.133 23.59 296.332 23.59 288.034 16.39 283.835"></polygon>
                <polygon fill="#3A5070" points="43.49 325.326 50.78 329.525 50.78 321.227 43.49 317.028"></polygon>
                <polygon fill="#3A5070" points="29.99 317.528 37.19 321.627 37.19 313.329 29.99 309.23"></polygon>
                <polygon fill="#3A5070" points="16.39 309.629 23.59 313.828 23.59 305.53 16.39 301.331"></polygon>
                <polygon fill="#3A5070" points="43.49 342.822 50.78 347.021 50.78 338.723 43.49 334.524"></polygon>
                <polygon fill="#3A5070" points="29.99 335.024 37.19 339.223 37.19 330.925 29.99 326.725"></polygon>
                <polygon fill="#3A5070" points="16.39 327.225 23.59 331.324 23.59 323.126 16.39 318.927"></polygon>
                <polygon fill="#3A5070" points="43.49 360.418 50.78 364.517 50.78 356.319 43.49 352.12"></polygon>
                <polygon fill="#3A5070" points="29.99 352.52 37.19 356.719 37.19 348.42 29.99 344.321"></polygon>
                <motion.polygon variants={lightsAnimation(getRandomDuration, getRandomDelay)} className="window" fill="#3A5070" points="16.39 344.721 23.59 348.92 23.59 340.622 16.39 336.423"></motion.polygon>
                <polygon fill="#3A5070" points="43.49 377.914 50.78 382.113 50.78 373.815 43.49 369.616"></polygon>
                <polygon fill="#3A5070" points="29.99 370.116 37.19 374.315 37.19 366.017 29.99 361.818"></polygon>
                <polygon fill="#3A5070" points="16.39 362.317 23.59 366.416 23.59 358.118 16.39 354.019"></polygon>
                <polygon fill="#3A5070" points="43.49 395.51 50.78 399.609 50.78 391.311 43.49 387.211"></polygon>
                <polygon fill="#3A5070" points="29.99 387.611 37.19 391.811 37.19 383.512 29.99 379.313"></polygon>
                <polygon fill="#3A5070" points="16.39 379.813 23.59 384.012 23.59 375.714 16.39 371.515"></polygon>
                <polygon fill="#3A5070" points="43.49 413.006 50.78 417.205 50.78 408.907 43.49 404.708"></polygon>
                <polygon fill="#3A5070" points="29.99 405.207 37.19 409.306 37.19 401.008 29.99 396.909"></polygon>
                <polygon fill="#3A5070" points="16.39 397.309 23.59 401.508 23.59 393.21 16.39 389.011"></polygon>
                <polygon fill="#3A5070" points="43.49 430.502 50.78 434.701 50.78 426.403 43.49 422.204"></polygon>
                <polygon fill="#3A5070" points="29.99 422.703 37.19 426.903 37.19 418.604 29.99 414.405"></polygon>
                <polygon fill="#3A5070" points="16.39 414.905 23.59 419.004 23.59 410.806 16.39 406.607"></polygon>
                <polygon fill="#3A5070" points="43.49 448.098 50.78 452.197 50.78 443.998 43.49 439.799"></polygon>
                <polygon fill="#3A5070" points="29.99 440.199 37.19 444.398 37.19 436.1 29.99 432.001"></polygon>
                <polygon fill="#3A5070" points="16.39 432.401 23.59 436.6 23.59 428.302 16.39 424.103"></polygon>
                <polygon fill="#3A5070" points="43.49 465.594 50.78 469.793 50.78 461.495 43.49 457.296"></polygon>
                <polygon fill="#3A5070" points="29.99 457.795 37.19 461.994 37.19 453.696 29.99 449.497"></polygon>
                <polygon fill="#3A5070" points="16.39 449.897 23.59 454.096 23.59 445.798 16.39 441.699"></polygon>
                <polygon fill="#3A5070" points="43.49 483.19 50.78 487.289 50.78 478.991 43.49 474.891"></polygon>
                <polygon fill="#3A5070" points="29.99 475.291 37.19 479.49 37.19 471.192 29.99 466.993"></polygon>
                <polygon fill="#3A5070" points="16.39 467.493 23.59 471.692 23.59 463.394 16.39 459.195"></polygon>
                <polygon fill="#3A5070" points="185.35 248.543 192.65 252.642 192.65 244.444 185.35 240.245"></polygon>
                <polygon fill="#3A5070" points="171.86 240.645 179.05 244.844 179.05 236.546 171.86 232.447"></polygon>
                <polygon fill="#3A5070" points="158.26 232.847 165.46 237.046 165.46 228.748 158.26 224.549"></polygon>
                <polygon fill="#3A5070" points="185.35 266.039 192.65 270.238 192.65 261.94 185.35 257.741"></polygon>
                <polygon fill="#3A5070" points="171.86 258.241 179.05 262.44 179.05 254.142 171.86 249.943"></polygon>
                <polygon fill="#3A5070" points="158.26 250.443 165.46 254.542 165.46 246.244 158.26 242.145"></polygon>
                <polygon fill="#3A5070" points="185.35 283.635 192.65 287.734 192.65 279.436 185.35 275.337"></polygon>
                <polygon fill="#3A5070" points="171.86 275.737 179.05 279.936 179.05 271.638 171.86 267.439"></polygon>
                <polygon fill="#3A5070" points="158.26 267.939 165.46 272.138 165.46 263.84 158.26 259.641"></polygon>
                <polygon fill="#3A5070" points="185.35 301.131 192.65 305.33 192.65 297.032 185.35 292.833"></polygon>
                <polygon fill="#3A5070" points="171.86 293.333 179.05 297.432 179.05 289.134 171.86 285.035"></polygon>
                <polygon fill="#3A5070" points="158.26 285.435 165.46 289.634 165.46 281.336 158.26 277.137"></polygon>
                <polygon fill="#3A5070" points="185.35 318.627 192.65 322.827 192.65 314.528 185.35 310.329"></polygon>
                <polygon fill="#3A5070" points="171.86 310.829 179.05 315.028 179.05 306.73 171.86 302.531"></polygon>
                <polygon fill="#3A5070" points="158.26 303.031 165.46 307.13 165.46 298.932 158.26 294.733"></polygon>
                <polygon fill="#3A5070" points="185.35 336.223 192.65 340.322 192.65 332.124 185.35 327.925"></polygon>
                <polygon fill="#3A5070" points="171.86 328.325 179.05 332.524 179.05 324.226 171.86 320.127"></polygon>
                <polygon fill="#3A5070" points="158.26 320.527 165.46 324.726 165.46 316.428 158.26 312.229"></polygon>
                <polygon fill="#3A5070" points="185.35 353.719 192.65 357.918 192.65 349.62 185.35 345.421"></polygon>
                <polygon fill="#3A5070" points="171.86 345.921 179.05 350.12 179.05 341.822 171.86 337.623"></polygon>
                <polygon fill="#3A5070" points="158.26 338.023 165.46 342.222 165.46 333.924 158.26 329.825"></polygon>
                <polygon fill="#3A5070" points="185.35 371.315 192.65 375.414 192.65 367.116 185.35 363.017"></polygon>
                <polygon fill="#3A5070" points="171.86 363.417 179.05 367.616 179.05 359.318 171.86 355.119"></polygon>
                <polygon fill="#3A5070" points="158.26 355.619 165.46 359.818 165.46 351.52 158.26 347.321"></polygon>
                <polygon fill="#3A5070" points="185.35 388.811 192.65 393.01 192.65 384.712 185.35 380.513"></polygon>
                <polygon fill="#3A5070" points="171.86 381.013 179.05 385.112 179.05 376.814 171.86 372.715"></polygon>
                <polygon fill="#3A5070" points="158.26 373.115 165.46 377.314 165.46 369.016 158.26 364.817"></polygon>
                <polygon fill="#3A5070" points="185.35 406.307 192.65 410.506 192.65 402.208 185.35 398.009"></polygon>
                <polygon fill="#3A5070" points="171.86 398.509 179.05 402.708 179.05 394.41 171.86 390.211"></polygon>
                <polygon fill="#3A5070" points="158.26 390.711 165.46 394.81 165.46 386.612 158.26 382.413"></polygon>
                <polygon fill="#3A5070" points="185.35 423.903 192.65 428.002 192.65 419.804 185.35 415.605"></polygon>
                <polygon fill="#3A5070" points="171.86 416.005 179.05 420.204 179.05 411.906 171.86 407.807"></polygon>
                <polygon fill="#3A5070" points="158.26 408.207 165.46 412.406 165.46 404.108 158.26 399.909"></polygon>
                <polygon fill="#3A5070" points="185.35 441.399 192.65 445.598 192.65 437.3 185.35 433.101"></polygon>
                <polygon fill="#3A5070" points="171.86 433.601 179.05 437.7 179.05 429.502 171.86 425.303"></polygon>
                <polygon fill="#3A5070" points="158.26 425.703 165.46 429.902 165.46 421.604 158.26 417.505"></polygon>
                <polygon fill="#3A5070" points="185.35 458.995 192.65 463.094 192.65 454.796 185.35 450.697"></polygon>
                <polygon fill="#3A5070" points="171.86 451.097 179.05 455.296 179.05 446.998 171.86 442.799"></polygon>
                <polygon fill="#3A5070" points="158.26 443.299 165.46 447.498 165.46 439.2 158.26 435.001"></polygon>
                <polygon fill="#3A5070" points="185.35 476.491 192.65 480.691 192.65 472.392 185.35 468.193"></polygon>
                <polygon fill="#3A5070" points="171.86 468.693 179.05 472.792 179.05 464.494 171.86 460.395"></polygon>
                <polygon fill="#3A5070" points="158.26 460.795 165.46 464.994 165.46 456.696 158.26 452.497"></polygon>
                <polygon fill="#3A5070" points="185.35 493.987 192.65 498.186 192.65 489.888 185.35 485.689"></polygon>
                <polygon fill="#3A5070" points="171.86 486.189 179.05 490.388 179.05 482.09 171.86 477.891"></polygon>
                <polygon fill="#3A5070" points="158.26 478.391 165.46 482.49 165.46 474.192 158.26 470.093"></polygon>
                <polygon fill="#3A5070" points="185.35 511.583 192.65 515.682 192.65 507.484 185.35 503.285"></polygon>
                <polygon fill="#3A5070" points="171.86 503.685 179.05 507.884 179.05 499.586 171.86 495.487"></polygon>
                <polygon fill="#3A5070" points="158.26 495.887 165.46 500.086 165.46 491.788 158.26 487.589"></polygon>
                <polygon fill="#3A5070" points="185.35 529.079 192.65 533.278 192.65 524.98 185.35 520.781"></polygon>
                <polygon fill="#3A5070" points="171.86 521.281 179.05 525.38 179.05 517.182 171.86 512.983"></polygon>
                <polygon fill="#3A5070" points="158.26 513.383 165.46 517.582 165.46 509.284 158.26 505.185"></polygon>
                <polygon fill="#3A5070" points="185.35 546.575 192.65 550.774 192.65 542.476 185.35 538.377"></polygon>
                <polygon fill="#3A5070" points="171.86 538.777 179.05 542.976 179.05 534.678 171.86 530.479"></polygon>
                <polygon fill="#3A5070" points="158.26 530.979 165.46 535.178 165.46 526.88 158.26 522.681"></polygon>
                <polygon fill="#3A5070" points="185.35 564.171 192.65 568.37 192.65 560.072 185.35 555.873"></polygon>
                <polygon fill="#3A5070" points="171.86 556.373 179.05 560.472 179.05 552.174 171.86 548.075"></polygon>
                <polygon fill="#3A5070" points="158.26 548.475 165.46 552.674 165.46 544.376 158.26 540.177"></polygon>
                <polygon fill="#3A5070" points="104.07 547.475 111.27 551.574 111.27 145.967 104.07 141.767"></polygon>
                <polygon fill="#3A5070" points="90.47 539.577 97.67 543.776 97.67 138.068 90.47 133.969"></polygon>
                <polygon fill="#3A5070" points="76.88 531.779 84.18 535.978 84.18 130.27 76.88 126.071"></polygon>
            </g>
        </motion.g>
        <motion.g variants={fadeIn()} id="trees" mask="url(#mask-2)" fill="#12233D" fill-rule="nonzero">
            <g transform="translate(160.250400, 536.975000)" id="Path">
                <path d="M1615.433,444.399 C1622.443,444.399 1628.133,441.087 1628.133,437.001 C1628.133,432.915 1622.443,429.602 1615.433,429.602 C1608.423,429.602 1602.733,432.915 1602.733,437.001 C1602.733,441.087 1608.423,444.399 1615.433,444.399 Z"></path>
                <path d="M1619.433,394.51 L1611.533,394.51 L1611.533,435.401 C1611.533,436.701 1613.333,437.701 1615.433,437.701 C1617.633,437.701 1619.333,436.701 1619.333,435.401 L1619.333,394.51 L1619.433,394.51 Z"></path>
                <path d="M1615.433,407.707 C1629.183,407.707 1640.323,394.592 1640.323,378.414 C1640.323,362.236 1629.183,349.121 1615.433,349.121 C1601.683,349.121 1590.533,362.236 1590.533,378.414 C1590.533,394.592 1601.683,407.707 1615.433,407.707 Z"></path>
                <path d="M1677.013,465.895 C1677.013,469.995 1671.323,473.295 1664.323,473.295 C1657.323,473.295 1651.623,469.995 1651.623,465.895 C1651.623,461.795 1657.323,458.496 1664.323,458.496 C1671.323,458.496 1677.013,461.795 1677.013,465.895 Z"></path>
                <path d="M1668.223,423.404 L1660.323,423.404 L1660.323,464.295 C1660.323,465.595 1662.123,466.595 1664.223,466.595 C1666.423,466.595 1668.123,465.595 1668.123,464.295 L1668.123,423.404 L1668.223,423.404 Z"></path>
                <path d="M1664.323,436.601 C1678.073,436.601 1689.213,423.486 1689.213,407.308 C1689.213,391.129 1678.073,378.014 1664.323,378.014 C1650.573,378.014 1639.423,391.129 1639.423,407.308 C1639.423,423.486 1650.573,436.601 1664.323,436.601 Z"></path>
                <path d="M1725.903,494.885 C1725.903,498.985 1720.203,502.285 1713.213,502.285 C1706.213,502.285 1700.513,498.985 1700.513,494.885 C1700.513,490.785 1706.213,487.485 1713.213,487.485 C1720.203,487.485 1725.903,490.785 1725.903,494.885 Z"></path>
                <path d="M1717.103,452.397 L1709.213,452.397 L1709.213,493.285 C1709.213,494.585 1711.013,495.585 1713.113,495.585 C1715.313,495.585 1717.003,494.585 1717.003,493.285 L1717.003,452.397 L1717.103,452.397 Z"></path>
                <path d="M1713.213,465.595 C1726.953,465.595 1738.103,452.479 1738.103,436.301 C1738.103,420.123 1726.953,407.008 1713.213,407.008 C1699.463,407.008 1688.313,420.123 1688.313,436.301 C1688.313,452.479 1699.463,465.595 1713.213,465.595 Z"></path>
                <path d="M85.88,467.895 C85.88,471.995 80.181,475.295 73.183,475.295 C66.185,475.295 60.486,471.995 60.486,467.895 C60.486,463.795 66.185,460.495 73.183,460.495 C80.181,460.495 85.88,463.795 85.88,467.895 Z"></path>
                <path d="M77.182,425.404 L69.284,425.404 L69.284,466.295 C69.284,467.595 71.083,468.595 73.183,468.595 C75.382,468.595 77.082,467.595 77.082,466.295 L77.082,425.404 L77.182,425.404 Z"></path>
                <path d="M73.183,438.6 C86.932,438.6 98.077,425.485 98.077,409.307 C98.077,393.129 86.932,380.014 73.183,380.014 C59.434,380.014 48.289,393.129 48.289,409.307 C48.289,425.485 59.434,438.6 73.183,438.6 Z"></path>
                <path d="M37.591,496.685 C37.591,500.785 31.892,504.085 24.894,504.085 C17.896,504.085 12.197,500.785 12.197,496.685 C12.197,492.585 17.896,489.285 24.894,489.285 C31.892,489.285 37.591,492.585 37.591,496.685 Z"></path>
                <path d="M28.793,454.197 L20.895,454.197 L20.895,495.085 C20.895,496.385 22.695,497.385 24.794,497.385 C26.994,497.385 28.693,496.385 28.693,495.085 L28.693,454.197 L28.793,454.197 Z"></path>
                <path d="M24.894,467.395 C38.643,467.395 49.788,454.279 49.788,438.101 C49.788,421.922 38.643,408.807 24.894,408.807 C11.145,408.807 1.42108547e-14,421.922 1.42108547e-14,438.101 C1.42108547e-14,454.279 11.145,467.395 24.894,467.395 Z"></path>
                <path d="M749.629,95.279 C756.641,95.279 762.326,91.966 762.326,87.88 C762.326,83.794 756.641,80.482 749.629,80.482 C742.616,80.482 736.932,83.794 736.932,87.88 C736.932,91.966 742.616,95.279 749.629,95.279 Z"></path>
                <path d="M753.628,45.39 L745.73,45.39 L745.73,86.281 C745.73,87.58 747.529,88.58 749.629,88.58 C751.828,88.58 753.528,87.58 753.528,86.281 L753.528,45.39 L753.628,45.39 Z"></path>
                <path d="M749.629,58.587 C763.377,58.587 774.523,45.472 774.523,29.294 C774.523,13.116 763.377,0 749.629,0 C735.88,0 724.734,13.116 724.734,29.294 C724.734,45.472 735.88,58.587 749.629,58.587 Z"></path>
                <path d="M692.242,127.971 C699.254,127.971 704.939,124.659 704.939,120.573 C704.939,116.487 699.254,113.175 692.242,113.175 C685.229,113.175 679.545,116.487 679.545,120.573 C679.545,124.659 685.229,127.971 692.242,127.971 Z"></path>
                <path d="M696.141,78.082 L688.243,78.082 L688.243,118.973 C688.243,120.273 690.042,121.273 692.142,121.273 C694.341,121.273 696.041,120.273 696.041,118.973 L696.041,78.082 L696.141,78.082 Z"></path>
                <path d="M692.242,91.28 C705.991,91.28 717.136,78.165 717.136,61.986 C717.136,45.808 705.991,32.693 692.242,32.693 C678.493,32.693 667.347,45.808 667.347,61.986 C667.347,78.165 678.493,91.28 692.242,91.28 Z"></path>
                <path d="M647.452,153.265 C647.452,157.364 641.753,160.664 634.755,160.664 C627.756,160.664 622.058,157.364 622.058,153.265 C622.058,149.166 627.756,145.867 634.755,145.867 C641.753,145.867 647.452,149.166 647.452,153.265 Z"></path>
                <path d="M638.654,110.775 L630.756,110.775 L630.756,151.666 C630.756,152.966 632.555,153.965 634.655,153.965 C636.854,153.965 638.554,152.966 638.554,151.666 L638.554,110.775 L638.654,110.775 Z"></path>
                <path d="M634.755,123.972 C648.504,123.972 659.649,110.857 659.649,94.679 C659.649,78.501 648.504,65.385 634.755,65.385 C621.006,65.385 609.861,78.501 609.861,94.679 C609.861,110.857 621.006,123.972 634.755,123.972 Z"></path>
                <path d="M589.965,185.958 C589.965,190.057 584.266,193.356 577.268,193.356 C570.27,193.356 564.571,190.057 564.571,185.958 C564.571,181.859 570.27,178.56 577.268,178.56 C584.266,178.56 589.965,181.859 589.965,185.958 Z"></path>
                <path d="M581.267,143.468 L573.369,143.468 L573.369,184.358 C573.369,185.658 575.168,186.658 577.268,186.658 C579.467,186.658 581.167,185.658 581.167,184.358 L581.167,143.468 L581.267,143.468 Z"></path>
                <path d="M577.268,156.565 C591.017,156.565 602.162,143.45 602.162,127.271 C602.162,111.093 591.017,97.978 577.268,97.978 C563.519,97.978 552.374,111.093 552.374,127.271 C552.374,143.45 563.519,156.565 577.268,156.565 Z"></path>
                <path d="M532.578,218.651 C532.578,222.75 526.879,226.049 519.881,226.049 C512.883,226.049 507.184,222.75 507.184,218.651 C507.184,214.552 512.883,211.252 519.881,211.252 C526.879,211.252 532.578,214.552 532.578,218.651 Z"></path>
                <path d="M523.78,176.16 L515.882,176.16 L515.882,217.051 C515.882,218.351 517.682,219.35 519.781,219.35 C521.981,219.35 523.68,218.351 523.68,217.051 L523.68,176.16 L523.78,176.16 Z"></path>
                <path d="M519.881,189.257 C533.63,189.257 544.775,176.142 544.775,159.964 C544.775,143.786 533.63,130.671 519.881,130.671 C506.132,130.671 494.987,143.786 494.987,159.964 C494.987,176.142 506.132,189.257 519.881,189.257 Z"></path>
                <path d="M475.091,251.243 C475.091,255.342 469.393,258.641 462.394,258.641 C455.396,258.641 449.697,255.342 449.697,251.243 C449.697,247.144 455.396,243.845 462.394,243.845 C469.393,243.845 475.091,247.244 475.091,251.243 Z"></path>
                <path d="M466.293,208.853 L458.395,208.853 L458.395,249.743 C458.395,251.043 460.195,252.043 462.294,252.043 C464.494,252.043 466.193,251.043 466.193,249.743 L466.193,208.853 L466.293,208.853 Z"></path>
                <path d="M462.394,221.95 C476.143,221.95 487.288,208.835 487.288,192.657 C487.288,176.478 476.143,163.363 462.394,163.363 C448.645,163.363 437.5,176.478 437.5,192.657 C437.5,208.835 448.645,221.95 462.394,221.95 Z"></path>
                <path d="M1028.263,238.446 C1028.263,242.545 1022.563,245.844 1015.563,245.844 C1008.573,245.844 1002.873,242.545 1002.873,238.446 C1002.873,234.347 1008.573,231.048 1015.563,231.048 C1022.563,231.048 1028.263,234.347 1028.263,238.446 Z"></path>
                <path d="M1019.463,195.956 L1011.573,195.956 L1011.573,236.847 C1011.573,238.146 1013.373,239.146 1015.473,239.146 C1017.563,239.146 1019.363,238.146 1019.363,236.847 L1019.363,195.956 L1019.463,195.956 Z"></path>
                <path d="M1015.473,209.153 C1029.213,209.153 1040.363,196.038 1040.363,179.859 C1040.363,163.681 1029.213,150.566 1015.473,150.566 C1001.723,150.566 990.573,163.681 990.573,179.859 C990.573,196.038 1001.723,209.153 1015.473,209.153 Z"></path>
                <path d="M970.783,271.139 C970.783,275.238 965.083,278.537 958.083,278.537 C951.083,278.537 945.383,275.238 945.383,271.139 C945.383,267.04 951.083,263.741 958.083,263.741 C965.083,263.741 970.783,267.04 970.783,271.139 Z"></path>
                <path d="M961.983,228.649 L954.083,228.649 L954.083,269.539 C954.083,270.839 955.883,271.839 957.983,271.839 C960.083,271.839 961.883,270.839 961.883,269.539 L961.883,228.649 L961.983,228.649 Z"></path>
                <path d="M913.293,303.831 C913.293,307.93 907.593,311.23 900.593,311.23 C893.593,311.23 887.893,307.93 887.893,303.831 C887.893,299.732 893.593,296.433 900.593,296.433 C907.593,296.433 913.293,299.732 913.293,303.831 Z"></path>
                <path d="M904.493,261.341 L896.593,261.341 L896.593,302.232 C896.593,303.531 898.393,304.531 900.493,304.531 C902.693,304.531 904.393,303.531 904.393,302.232 L904.393,261.341 L904.493,261.341 Z"></path>
                <path d="M958.083,241.845 C971.833,241.845 982.973,228.73 982.973,212.552 C982.973,196.374 971.833,183.259 958.083,183.259 C944.333,183.259 933.183,196.374 933.183,212.552 C933.183,228.73 944.333,241.845 958.083,241.845 Z"></path>
                <path d="M900.593,274.538 C914.343,274.538 925.493,261.423 925.493,245.245 C925.493,229.066 914.343,215.951 900.593,215.951 C886.843,215.951 875.703,229.066 875.703,245.245 C875.703,261.423 886.843,274.538 900.593,274.538 Z"></path>
                <path d="M855.903,336.524 C855.903,340.623 850.203,343.922 843.203,343.922 C836.209,343.922 830.51,340.623 830.51,336.524 C830.51,332.425 836.209,329.126 843.203,329.126 C850.203,329.126 855.903,332.425 855.903,336.524 Z"></path>
                <path d="M847.103,294.034 L839.208,294.034 L839.208,334.924 C839.208,336.224 841.003,337.224 843.103,337.224 C845.303,337.224 847.003,336.224 847.003,334.924 L847.003,294.034 L847.103,294.034 Z"></path>
                <path d="M843.103,307.231 C856.853,307.231 868.003,294.116 868.003,277.937 C868.003,261.759 856.853,248.644 843.103,248.644 C829.359,248.644 818.213,261.759 818.213,277.937 C818.213,294.116 829.359,307.231 843.103,307.231 Z"></path>
                <path d="M798.417,369.216 C798.417,373.316 792.719,376.615 785.72,376.615 C778.722,376.615 773.023,373.316 773.023,369.216 C773.023,365.117 778.722,361.818 785.72,361.818 C792.719,361.818 798.417,365.117 798.417,369.216 Z"></path>
                <path d="M789.62,326.726 L781.721,326.726 L781.721,367.617 C781.721,368.917 783.521,369.916 785.62,369.916 C787.82,369.916 789.52,368.917 789.52,367.617 L789.52,326.726 L789.62,326.726 Z"></path>
                <path d="M785.721,339.923 C799.469,339.923 810.615,326.808 810.615,310.63 C810.615,294.452 799.469,281.337 785.721,281.337 C771.972,281.337 760.826,294.452 760.826,310.63 C760.826,326.808 771.972,339.923 785.721,339.923 Z"></path>
                <path d="M740.931,401.909 C740.931,406.008 735.232,409.307 728.234,409.307 C721.235,409.307 715.537,406.008 715.537,401.909 C715.537,397.81 721.235,394.511 728.234,394.511 C735.232,394.511 740.931,397.81 740.931,401.909 Z"></path>
                <path d="M732.133,359.419 L724.235,359.419 L724.235,400.309 C724.235,401.609 726.034,402.609 728.134,402.609 C730.333,402.609 732.033,401.609 732.033,400.309 L732.033,359.419 L732.133,359.419 Z"></path>
                <path d="M728.234,372.616 C741.982,372.616 753.128,359.501 753.128,343.322 C753.128,327.144 741.982,314.029 728.234,314.029 C714.485,314.029 703.339,327.144 703.339,343.322 C703.339,359.501 714.485,372.616 728.234,372.616 Z"></path>
                <path d="M917.593,500.385 C924.603,500.385 930.283,497.075 930.283,492.985 C930.283,488.905 924.603,485.585 917.593,485.585 C910.583,485.585 904.893,488.905 904.893,492.985 C904.893,497.075 910.583,500.385 917.593,500.385 Z"></path>
                <path d="M921.593,450.498 L913.693,450.498 L913.693,491.385 C913.693,492.685 915.493,493.685 917.593,493.685 C919.793,493.685 921.493,492.685 921.493,491.385 L921.493,450.498 L921.593,450.498 Z"></path>
                <path d="M917.593,463.695 C931.343,463.695 942.483,450.58 942.483,434.401 C942.483,418.223 931.343,405.108 917.593,405.108 C903.843,405.108 892.693,418.223 892.693,434.401 C892.693,450.58 903.843,463.695 917.593,463.695 Z"></path>
                <path d="M979.173,521.885 C979.173,525.985 973.473,529.275 966.483,529.275 C959.483,529.275 953.783,525.985 953.783,521.885 C953.783,517.785 959.483,514.485 966.483,514.485 C973.473,514.485 979.173,517.785 979.173,521.885 Z"></path>
                <path d="M970.383,479.395 L962.483,479.395 L962.483,520.285 C962.483,521.585 964.283,522.585 966.383,522.585 C968.583,522.585 970.283,521.585 970.283,520.285 L970.283,479.395 L970.383,479.395 Z"></path>
                <path d="M1019.263,508.385 L1011.373,508.385 L1011.373,549.275 C1011.373,550.575 1013.173,551.575 1015.273,551.575 C1017.463,551.575 1019.163,550.575 1019.163,549.275 L1019.163,508.385 L1019.263,508.385 Z"></path>
                <path d="M76.782,532.375 C83.795,532.375 89.479,529.065 89.479,524.985 C89.479,520.895 83.795,517.585 76.782,517.585 C69.77,517.585 64.085,520.895 64.085,524.985 C64.085,529.065 69.77,532.375 76.782,532.375 Z"></path>
                <path d="M80.781,482.495 L72.883,482.495 L72.883,523.385 C72.883,524.685 74.683,525.685 76.782,525.685 C78.882,525.685 80.681,524.685 80.681,523.385 L80.681,482.495 L80.781,482.495 Z"></path>
                <path d="M76.782,495.685 C90.531,495.685 101.676,482.575 101.676,466.395 C101.676,450.216 90.531,437.101 76.782,437.101 C63.033,437.101 51.888,450.216 51.888,466.395 C51.888,482.575 63.033,495.685 76.782,495.685 Z"></path>
                <path d="M129.57,511.385 L121.672,511.385 L121.672,552.275 C121.672,553.575 123.471,554.575 125.571,554.575 C127.77,554.575 129.47,553.575 129.47,552.275 L129.47,511.385 L129.57,511.385 Z"></path>
                <path d="M125.671,524.585 C139.42,524.585 150.565,511.465 150.565,495.285 C150.565,479.105 139.42,465.995 125.671,465.995 C111.922,465.995 100.777,479.105 100.777,495.285 C100.777,511.465 111.922,524.585 125.671,524.585 Z"></path>
                <path d="M178.459,540.375 L170.561,540.375 L170.561,581.265 C170.561,582.565 172.36,583.565 174.46,583.565 C176.659,583.565 178.359,582.565 178.359,581.265 L178.359,540.375 L178.459,540.375 Z"></path>
                <path d="M174.56,553.575 C188.309,553.575 199.454,540.455 199.454,524.285 C199.454,508.105 188.309,494.985 174.56,494.985 C160.811,494.985 149.665,508.105 149.665,524.285 C149.665,540.455 160.811,553.575 174.56,553.575 Z"></path>
            </g>
        </motion.g>
        <motion.g variants={getSlideTopAnimation(Math.floor(Math.random() * 2) + 1.1)} id="block-7" mask="url(#mask-2)" fill-rule="nonzero">
            <g transform="translate(777.809400, 251.041000)">
                <polygon id="Path" fill="#12233D" points="182.958 578.368 346.724 484.189 346.724 295.332 182.958 389.511"></polygon>
                <polygon id="Path" fill="#2A3B56" points="21.495 484.189 182.958 578.368 182.958 389.511 21.495 295.532"></polygon>
                <polygon id="Path" fill="#3A5070" points="21.495 295.532 182.958 389.511 346.724 295.332 185.058 201.254"></polygon>
                <polygon id="Path" fill="#12233D" points="120.572 583.867 120.572 593.564 164.562 567.57 164.562 557.873"></polygon>
                <polygon id="Path" fill="#2A3B56" points="120.672 583.867 120.672 593.564 0 523.18 0 513.583"></polygon>
                <polygon id="Path" fill="#3A5070" points="-5.68434189e-14 513.583 120.672 583.867 164.562 557.873 43.79 487.489"></polygon>
                <polygon id="Path" fill="#12233D" points="134.169 566.071 134.169 575.769 164.562 557.973 164.562 548.275"></polygon>
                <polygon id="Path" fill="#2A3B56" points="134.269 566.071 134.269 575.769 13.597 505.385 13.597 495.787"></polygon>
                <polygon id="Path" fill="#3A5070" points="13.597 495.787 134.269 566.071 164.562 548.275 43.79 477.991"></polygon>
                <path d="M157.764,545.975 L131.47,545.975 L131.47,547.675 L131.47,547.775 C131.47,551.974 137.368,555.473 144.567,555.473 C151.865,555.473 157.664,552.074 157.664,547.775 L157.664,547.675 L157.664,545.975 L157.764,545.975 Z" id="Path" fill="#2A3B56"></path>
                <path d="M157.764,546.075 C157.764,549.674 151.865,552.674 144.667,552.674 C137.368,552.674 131.57,549.774 131.57,546.075 C131.57,542.476 137.468,539.477 144.667,539.477 C151.865,539.477 157.764,542.476 157.764,546.075 Z" id="Path" fill="#12233D"></path>
                <path d="M154.864,542.676 L134.469,542.676 L134.469,544.775 L134.469,544.875 C134.469,548.175 139.068,550.874 144.667,550.874 C150.265,550.874 154.864,548.175 154.864,544.875 L154.864,544.775 L154.864,542.676 Z" id="Path" fill="#2A3B56"></path>
                <path d="M144.667,547.875 C150.299,547.875 154.864,545.592 154.864,542.776 C154.864,539.96 150.299,537.677 144.667,537.677 C139.035,537.677 134.469,539.96 134.469,542.776 C134.469,545.592 139.035,547.875 144.667,547.875 Z" id="Path" fill="#2A3B56"></path>
                <path d="M152.365,470.192 L136.868,470.192 L136.868,541.976 C136.868,544.475 140.368,546.475 144.567,546.475 C148.866,546.475 152.265,544.475 152.265,541.976 L152.265,541.876 L152.265,470.192 L152.365,470.192 Z" id="Path" fill="#3A5070"></path>
                <path d="M152.365,470.192 C152.365,472.292 148.866,474.092 144.667,474.092 C140.368,474.092 136.968,472.392 136.968,470.192 C136.968,468.093 140.468,466.293 144.667,466.293 C148.866,466.293 152.365,468.093 152.365,470.192 Z" id="Path" fill="url(#linearGradient-4)"></path>
                <path d="M154.864,469.193 L134.469,469.193 L134.469,471.292 L134.469,471.392 C134.469,474.691 139.068,477.391 144.667,477.391 C150.265,477.391 154.864,474.691 154.864,471.392 L154.864,471.292 L154.864,469.193 Z" id="Path" fill="#12233D"></path>
                <path d="M154.864,469.293 C154.864,472.092 150.265,474.391 144.667,474.391 C139.068,474.391 134.469,472.092 134.469,469.293 C134.469,466.493 139.068,464.194 144.667,464.194 C150.265,464.194 154.864,466.493 154.864,469.293 Z" id="Path" fill="#12233D"></path>
                <path d="M131.77,530.979 L105.476,530.979 L105.476,532.678 L105.476,532.778 C105.476,536.977 111.374,540.476 118.573,540.476 C125.871,540.476 131.67,537.077 131.67,532.778 L131.67,532.678 L131.67,530.979 L131.77,530.979 Z" id="Path" fill="#2A3B56"></path>
                <path d="M131.77,531.079 C131.77,534.678 125.871,537.677 118.673,537.677 C111.374,537.677 105.576,534.778 105.576,531.079 C105.576,527.479 111.474,524.48 118.673,524.48 C125.871,524.48 131.77,527.479 131.77,531.079 Z" id="Path" fill="#12233D"></path>
                <path d="M128.87,527.679 L108.475,527.679 L108.475,529.779 L108.475,529.879 C108.475,533.178 113.074,535.877 118.673,535.877 C124.271,535.877 128.87,533.178 128.87,529.879 L128.87,529.779 L128.87,527.679 Z" id="Path" fill="#2A3B56"></path>
                <path d="M118.673,532.878 C124.305,532.878 128.87,530.595 128.87,527.779 C128.87,524.963 124.305,522.68 118.673,522.68 C113.041,522.68 108.475,524.963 108.475,527.779 C108.475,530.595 113.041,532.878 118.673,532.878 Z" id="Path" fill="#2A3B56"></path>
                <path d="M126.371,455.196 L110.874,455.196 L110.874,526.979 C110.874,529.479 114.374,531.478 118.573,531.478 C122.872,531.478 126.271,529.479 126.271,526.979 L126.271,526.879 L126.271,455.196 L126.371,455.196 Z" id="Path" fill="#3A5070"></path>
                <path d="M126.371,455.196 C126.371,457.295 122.872,459.095 118.673,459.095 C114.374,459.095 110.975,457.395 110.975,455.196 C110.975,453.096 114.474,451.297 118.673,451.297 C122.872,451.297 126.371,453.096 126.371,455.196 Z" id="Path" fill="url(#linearGradient-5)"></path>
                <path d="M128.87,454.196 L108.475,454.196 L108.475,456.295 L108.475,456.395 C108.475,459.695 113.074,462.394 118.673,462.394 C124.271,462.394 128.87,459.695 128.87,456.395 L128.87,456.295 L128.87,454.196 Z" id="Path" fill="#12233D"></path>
                <path d="M128.87,454.296 C128.87,457.095 124.271,459.395 118.673,459.395 C113.074,459.395 108.475,457.095 108.475,454.296 C108.475,451.497 113.074,449.197 118.673,449.197 C124.271,449.197 128.87,451.497 128.87,454.296 Z" id="Path" fill="#12233D"></path>
                <path d="M105.776,515.982 L79.482,515.982 L79.482,517.682 L79.482,517.782 C79.482,521.981 85.38,525.48 92.579,525.48 C99.877,525.48 105.676,522.081 105.676,517.782 L105.676,517.682 L105.676,515.982 L105.776,515.982 Z" id="Path" fill="#2A3B56"></path>
                <path d="M105.776,516.082 C105.776,519.681 99.877,522.68 92.679,522.68 C85.38,522.68 79.582,519.781 79.582,516.082 C79.582,512.483 85.48,509.484 92.679,509.484 C99.877,509.484 105.776,512.483 105.776,516.082 Z" id="Path" fill="#12233D"></path>
                <path d="M102.876,512.683 L82.481,512.683 L82.481,514.782 L82.481,514.882 C82.481,518.181 87.08,520.881 92.679,520.881 C98.277,520.881 102.876,518.181 102.876,514.882 L102.876,514.782 L102.876,512.683 Z" id="Path" fill="#2A3B56"></path>
                <path d="M92.679,517.881 C98.311,517.881 102.876,515.599 102.876,512.783 C102.876,509.967 98.311,507.684 92.679,507.684 C87.047,507.684 82.481,509.967 82.481,512.783 C82.481,515.599 87.047,517.881 92.679,517.881 Z" id="Path" fill="#2A3B56"></path>
                <path d="M100.377,440.199 L84.88,440.199 L84.88,511.983 C84.88,514.482 88.38,516.482 92.579,516.482 C96.878,516.482 100.277,514.482 100.277,511.983 L100.277,511.883 L100.277,440.199 L100.377,440.199 Z" id="Path" fill="#3A5070"></path>
                <path d="M100.377,440.199 C100.377,442.299 96.878,444.098 92.679,444.098 C88.38,444.098 84.98,442.399 84.98,440.199 C84.98,438.1 88.48,436.3 92.679,436.3 C96.878,436.3 100.377,438.1 100.377,440.199 Z" id="Path" fill="url(#linearGradient-6)"></path>
                <path d="M102.876,439.199 L82.481,439.199 L82.481,441.299 L82.481,441.399 C82.481,444.698 87.08,447.398 92.679,447.398 C98.277,447.398 102.876,444.698 102.876,441.399 L102.876,441.299 L102.876,439.199 Z" id="Path" fill="#12233D"></path>
                <path d="M102.876,439.299 C102.876,442.099 98.277,444.398 92.679,444.398 C87.08,444.398 82.481,442.099 82.481,439.299 C82.481,436.5 87.08,434.201 92.679,434.201 C98.277,434.201 102.876,436.5 102.876,439.299 Z" id="Path" fill="#12233D"></path>
                <path d="M79.781,500.985 L53.488,500.985 L53.488,502.685 L53.488,502.785 C53.488,506.984 59.386,510.483 66.584,510.483 C73.883,510.483 79.682,507.084 79.682,502.785 L79.682,502.685 L79.682,500.985 L79.781,500.985 Z" id="Path" fill="#2A3B56"></path>
                <path d="M79.782,501.085 C79.782,504.685 73.883,507.684 66.685,507.684 C59.386,507.684 53.588,504.785 53.588,501.085 C53.588,497.486 59.486,494.487 66.685,494.487 C73.883,494.487 79.782,497.486 79.782,501.085 Z" id="Path" fill="#12233D"></path>
                <path d="M76.882,497.686 L56.487,497.686 L56.487,499.786 L56.487,499.886 C56.487,503.185 61.086,505.884 66.684,505.884 C72.283,505.884 76.882,503.185 76.882,499.886 L76.882,499.786 L76.882,497.686 Z" id="Path" fill="#2A3B56"></path>
                <path d="M66.684,502.885 C72.317,502.885 76.882,500.602 76.882,497.786 C76.882,494.97 72.317,492.687 66.684,492.687 C61.052,492.687 56.487,494.97 56.487,497.786 C56.487,500.602 61.052,502.885 66.684,502.885 Z" id="Path" fill="#2A3B56"></path>
                <path d="M74.383,425.203 L58.886,425.203 L58.886,496.986 C58.886,499.486 62.385,501.485 66.584,501.485 C70.883,501.485 74.283,499.486 74.283,496.986 L74.283,496.886 L74.283,425.203 L74.383,425.203 Z" id="Path" fill="#3A5070"></path>
                <path d="M74.383,425.203 C74.383,427.302 70.884,429.102 66.685,429.102 C62.386,429.102 58.986,427.402 58.986,425.203 C58.986,423.103 62.486,421.303 66.685,421.303 C70.884,421.303 74.383,423.103 74.383,425.203 Z" id="Path" fill="url(#linearGradient-7)"></path>
                <path d="M76.882,424.203 L56.487,424.203 L56.487,426.302 L56.487,426.402 C56.487,429.702 61.086,432.401 66.684,432.401 C72.283,432.401 76.882,429.702 76.882,426.402 L76.882,426.302 L76.882,424.203 Z" id="Path" fill="#12233D"></path>
                <path d="M76.882,424.303 C76.882,427.102 72.283,429.402 66.684,429.402 C61.086,429.402 56.487,427.102 56.487,424.303 C56.487,421.503 61.086,419.204 66.684,419.204 C72.283,419.204 76.882,421.503 76.882,424.303 Z" id="Path" fill="#12233D"></path>
                <path d="M54.787,485.989 L28.493,485.989 L28.493,487.688 L28.493,487.788 C28.493,491.987 34.392,495.487 41.59,495.487 C48.889,495.487 54.687,492.087 54.687,487.788 L54.687,487.688 L54.687,485.989 L54.787,485.989 Z" id="Path" fill="#2A3B56"></path>
                <path d="M54.787,486.089 C54.787,489.688 48.889,492.687 41.69,492.687 C34.392,492.687 28.593,489.788 28.593,486.089 C28.593,482.49 34.492,479.49 41.69,479.49 C48.889,479.49 54.787,482.49 54.787,486.089 Z" id="Path" fill="#12233D"></path>
                <path d="M51.888,482.69 L31.493,482.69 L31.493,484.789 L31.493,484.889 C31.493,488.188 36.091,490.888 41.69,490.888 C47.289,490.888 51.888,488.188 51.888,484.889 L51.888,484.789 L51.888,482.69 Z" id="Path" fill="#2A3B56"></path>
                <path d="M41.69,487.888 C47.322,487.888 51.888,485.606 51.888,482.79 C51.888,479.973 47.322,477.691 41.69,477.691 C36.058,477.691 31.493,479.973 31.493,482.79 C31.493,485.606 36.058,487.888 41.69,487.888 Z" id="Path" fill="#2A3B56"></path>
                <path d="M49.389,410.206 L33.892,410.206 L33.892,481.99 C33.892,484.489 37.391,486.489 41.59,486.489 C45.889,486.489 49.289,484.489 49.289,481.99 L49.289,481.89 L49.289,410.206 L49.389,410.206 Z" id="Path" fill="#3A5070"></path>
                <path d="M49.388,410.206 C49.388,412.306 45.889,414.105 41.69,414.105 C37.391,414.105 33.992,412.405 33.992,410.206 C33.992,408.107 37.491,406.307 41.69,406.307 C45.889,406.307 49.388,408.107 49.388,410.206 Z" id="Path" fill="url(#linearGradient-5)"></path>
                <path d="M51.888,409.206 L31.493,409.206 L31.493,411.306 L31.493,411.406 C31.493,414.705 36.091,417.405 41.69,417.405 C47.289,417.405 51.888,414.705 51.888,411.406 L51.888,411.306 L51.888,409.206 Z" id="Path" fill="#12233D"></path>
                <path d="M51.888,409.306 C51.888,412.106 47.289,414.405 41.69,414.405 C36.091,414.405 31.493,412.106 31.493,409.306 C31.493,406.507 36.091,404.208 41.69,404.208 C47.289,404.208 51.888,406.507 51.888,409.306 Z" id="Path" fill="#12233D"></path>
                <polygon id="Path" fill="#12233D" points="134.169 473.792 134.169 483.49 164.562 465.694 164.562 455.996"></polygon>
                <polygon id="Path" fill="#2A3B56" points="134.269 473.792 134.269 483.49 13.597 413.106 13.597 403.508"></polygon>
                <polygon id="Path" fill="#3A5070" points="13.597 403.508 134.269 473.792 164.562 455.996 43.79 385.612"></polygon>
                <polygon id="Path" fill="#12233D" points="182.958 383.712 337.824 294.733 337.824 217.85 182.958 306.93"></polygon>
                <polygon id="Path" fill="#1D2F52" points="30.393 294.733 183.058 383.712 183.058 306.93 30.393 218.05"></polygon>
                <polygon id="Path" fill="#12233D" points="182.958 373.515 346.724 279.436 346.724 194.755 182.958 288.934"></polygon>
                <polygon id="Path" fill="#2A3B56" points="21.495 279.436 182.958 373.515 182.958 288.934 21.495 194.855"></polygon>
                <polygon id="Path" fill="#3A5070" points="21.495 194.855 182.958 288.934 346.724 194.755 185.058 100.677"></polygon>
                <path d="M205.853,472.992 C202.754,474.891 200.854,478.291 200.854,481.99 L200.854,568.47 L211.951,561.672 L211.951,476.391 C211.951,473.292 208.452,471.292 205.853,472.992 Z M293.034,429.602 C293.034,426.502 289.634,424.603 286.934,426.203 C283.834,428.102 281.934,431.501 281.934,435.2 L281.934,521.681 L293.034,514.882 L293.034,429.602 Z M272.734,441.299 C272.734,438.2 269.334,436.3 266.634,437.9 C263.544,439.799 261.644,443.199 261.644,446.898 L261.644,533.378 L272.734,526.58 L272.734,441.299 Z M252.444,452.996 C252.444,449.897 249.044,447.998 246.344,449.597 C243.244,451.497 241.344,454.896 241.344,458.595 L241.344,545.075 L252.444,538.277 L252.444,452.996 Z M334.024,405.907 C334.024,402.808 330.624,400.908 327.924,402.508 C324.824,404.407 322.924,407.807 322.924,411.506 L322.924,497.986 L334.024,491.188 L334.024,405.907 Z M313.724,417.605 C313.724,414.505 310.324,412.606 307.634,414.205 C304.534,416.105 302.634,419.504 302.634,423.203 L302.634,509.683 L313.724,502.885 L313.724,417.605 Z M232.144,464.694 C232.144,461.594 228.744,459.695 226.044,461.294 C222.949,463.194 221.049,466.593 221.049,470.292 L221.049,556.773 L232.144,549.974 L232.144,464.694 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M205.853,394.11 C202.754,396.009 200.854,399.409 200.854,403.108 L200.854,445.998 L211.951,439.2 L211.951,397.509 C211.951,394.41 208.452,392.51 205.853,394.11 Z M293.034,350.82 C293.034,347.72 289.634,345.821 286.934,347.421 C283.834,349.32 281.934,352.719 281.934,356.418 L281.934,399.309 L293.034,392.51 L293.034,350.82 Z M272.734,362.517 C272.734,359.418 269.334,357.518 266.634,359.118 C263.544,361.017 261.644,364.417 261.644,368.116 L261.644,411.006 L272.734,404.208 L272.734,362.517 Z M252.444,374.214 C252.444,371.115 249.044,369.216 246.344,370.815 C243.244,372.715 241.344,376.114 241.344,379.813 L241.344,422.703 L252.444,415.905 L252.444,374.214 Z M334.024,327.125 C334.024,324.026 330.624,322.126 327.924,323.726 C324.824,325.626 322.924,329.025 322.924,332.724 L322.924,375.614 L334.024,368.816 L334.024,327.125 Z M313.724,338.822 C313.724,335.723 310.324,333.824 307.634,335.423 C304.534,337.323 302.634,340.722 302.634,344.421 L302.634,387.311 L313.724,380.513 L313.724,338.822 Z M232.144,385.912 C232.144,382.812 228.744,380.913 226.044,382.513 C222.949,384.412 221.049,387.811 221.049,391.51 L221.049,434.401 L232.144,427.602 L232.144,385.912 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M157.664,397.509 L157.664,439.2 L168.761,445.998 L168.761,403.108 C168.761,399.409 166.862,396.009 163.762,394.11 C161.063,392.51 157.664,394.41 157.664,397.509 Z M76.582,392.51 L87.68,399.309 L87.68,356.418 C87.68,352.719 85.78,349.32 82.681,347.421 C79.982,345.821 76.582,347.72 76.582,350.82 L76.582,392.51 Z M96.878,404.208 L107.975,411.006 L107.975,368.116 C107.975,364.417 106.076,361.017 102.976,359.118 C100.277,357.518 96.878,359.418 96.878,362.517 L96.878,404.208 Z M117.073,415.905 L128.171,422.703 L128.171,379.813 C128.171,376.114 126.271,372.715 123.172,370.815 C120.472,369.216 117.073,371.115 117.073,374.214 L117.073,415.905 Z M35.592,368.816 L46.689,375.614 L46.689,332.724 C46.689,329.025 44.79,325.626 41.69,323.726 C38.991,322.126 35.592,324.026 35.592,327.125 L35.592,368.816 Z M55.787,380.513 L66.884,387.311 L66.884,344.421 C66.884,340.722 64.985,337.323 61.886,335.423 C59.186,333.824 55.787,335.723 55.787,338.822 L55.787,380.513 Z M137.368,427.602 L148.466,434.401 L148.466,391.51 C148.466,387.811 146.566,384.412 143.467,382.513 C140.768,380.913 137.368,382.812 137.368,385.912 L137.368,427.602 Z" id="Shape" fill="#3A5070"></path>
                <path d="M205.853,295.033 C202.754,296.932 200.854,300.331 200.854,304.03 L200.854,346.921 L211.951,340.122 L211.951,298.432 C211.951,295.332 208.452,293.333 205.853,295.033 Z M293.034,251.642 C293.034,248.543 289.634,246.644 286.934,248.243 C283.834,250.143 281.934,253.542 281.934,257.241 L281.934,300.131 L293.034,293.333 L293.034,251.642 Z M272.734,263.34 C272.734,260.24 269.334,258.341 266.634,259.941 C263.544,261.84 261.644,265.239 261.644,268.938 L261.644,311.829 L272.734,305.03 L272.734,263.34 Z M252.444,275.037 C252.444,271.938 249.044,270.038 246.344,271.638 C243.244,273.537 241.344,276.937 241.344,280.636 L241.344,323.526 L252.444,316.728 L252.444,275.037 Z M334.024,227.948 C334.024,224.849 330.624,222.949 327.924,224.549 C324.824,226.448 322.924,229.847 322.924,233.547 L322.924,276.437 L334.024,269.638 L334.024,227.948 Z M313.724,239.645 C313.724,236.546 310.324,234.646 307.634,236.246 C304.534,238.145 302.634,241.545 302.634,245.244 L302.634,288.134 L313.724,281.336 L313.724,239.645 Z M232.144,286.734 C232.144,283.635 228.744,281.736 226.044,283.335 C222.949,285.235 221.049,288.634 221.049,292.333 L221.049,335.223 L232.144,328.425 L232.144,286.734 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M157.664,298.432 L157.664,340.122 L168.761,346.921 L168.761,304.03 C168.761,300.331 166.862,296.932 163.762,295.033 C161.063,293.333 157.664,295.332 157.664,298.432 Z M76.582,293.333 L87.68,300.131 L87.68,257.241 C87.68,253.542 85.78,250.143 82.681,248.243 C79.982,246.644 76.582,248.543 76.582,251.642 L76.582,293.333 Z M96.878,305.03 L107.975,311.829 L107.975,268.938 C107.975,265.239 106.076,261.84 102.976,259.941 C100.277,258.341 96.878,260.24 96.878,263.34 L96.878,305.03 Z M117.073,316.728 L128.171,323.526 L128.171,280.636 C128.171,276.937 126.271,273.537 123.172,271.638 C120.472,270.038 117.073,271.938 117.073,275.037 L117.073,316.728 Z M35.592,269.638 L46.689,276.437 L46.689,233.547 C46.689,229.847 44.79,226.448 41.69,224.549 C38.991,222.949 35.592,224.849 35.592,227.948 L35.592,269.638 Z M55.787,281.336 L66.884,288.134 L66.884,245.244 C66.884,241.545 64.985,238.145 61.886,236.246 C59.186,234.646 55.787,236.546 55.787,239.645 L55.787,281.336 Z M137.368,328.425 L148.466,335.223 L148.466,292.333 C148.466,288.634 146.566,285.235 143.467,283.335 C140.768,281.736 137.368,283.635 137.368,286.734 L137.368,328.425 Z" id="Shape" fill="#3A5070"></path>
                <polygon id="Path" fill="#12233D" points="182.958 283.135 337.824 194.155 337.824 117.273 182.958 206.253"></polygon>
                <polygon id="Path" fill="#1D2F52" points="30.393 194.156 183.058 283.135 183.058 206.253 30.393 117.373"></polygon>
                <polygon id="Path" fill="#12233D" points="182.958 272.938 346.724 178.759 346.724 94.178 182.958 188.357"></polygon>
                <polygon id="Path" fill="#2A3B56" points="21.495 178.759 182.958 272.937 182.958 188.357 21.495 94.278"></polygon>
                <polygon id="Path" fill="#3A5070" points="21.495 94.278 182.958 188.357 346.724 94.178 185.058 0"></polygon>
                <path d="M205.853,194.455 C202.754,196.355 200.854,199.754 200.854,203.453 L200.854,246.344 L211.951,239.545 L211.951,197.855 C211.951,194.655 208.452,192.756 205.853,194.455 Z M293.034,151.065 C293.034,147.966 289.634,146.067 286.934,147.666 C283.834,149.566 281.934,152.965 281.934,156.664 L281.934,199.554 L293.034,192.756 L293.034,151.065 Z M272.734,162.763 C272.734,159.663 269.334,157.764 266.634,159.363 C263.544,161.263 261.644,164.662 261.644,168.361 L261.644,211.252 L272.734,204.453 L272.734,162.763 Z M252.444,174.46 C252.444,171.361 249.044,169.461 246.344,171.061 C243.244,172.96 241.344,176.36 241.344,180.059 L241.344,222.949 L252.444,216.151 L252.444,174.46 Z M334.024,127.371 C334.024,124.271 330.624,122.372 327.924,123.972 C324.824,125.871 322.924,129.27 322.924,132.97 L322.924,175.86 L334.024,169.061 L334.024,127.371 Z M313.724,139.068 C313.724,135.969 310.324,134.069 307.634,135.669 C304.534,137.568 302.634,140.968 302.634,144.667 L302.634,187.557 L313.724,180.759 L313.724,139.068 Z M232.144,186.157 C232.144,183.058 228.744,181.159 226.044,182.758 C222.949,184.658 221.049,188.057 221.049,191.756 L221.049,234.646 L232.144,227.848 L232.144,186.157 Z" id="Shape" fill="#1D2F52"></path>
                <path d="M157.664,197.855 L157.664,239.545 L168.761,246.344 L168.761,203.453 C168.761,199.754 166.862,196.355 163.762,194.455 C161.063,192.756 157.664,194.655 157.664,197.855 Z M76.582,192.756 L87.68,199.554 L87.68,156.664 C87.68,152.965 85.78,149.566 82.681,147.666 C79.982,146.067 76.582,147.966 76.582,151.065 L76.582,192.756 Z M96.878,204.453 L107.975,211.252 L107.975,168.361 C107.975,164.662 106.076,161.263 102.976,159.363 C100.277,157.764 96.878,159.663 96.878,162.763 L96.878,204.453 Z M117.073,216.151 L128.171,222.949 L128.171,180.059 C128.171,176.36 126.271,172.96 123.172,171.061 C120.472,169.461 117.073,171.361 117.073,174.46 L117.073,216.151 Z M35.592,169.061 L46.689,175.86 L46.689,132.97 C46.689,129.27 44.79,125.871 41.69,123.972 C38.991,122.372 35.592,124.271 35.592,127.371 L35.592,169.061 Z M55.787,180.759 L66.884,187.557 L66.884,144.667 C66.884,140.968 64.985,137.568 61.886,135.669 C59.186,134.069 55.787,135.969 55.787,139.068 L55.787,180.759 Z M137.368,227.848 L148.466,234.646 L148.466,191.756 C148.466,188.057 146.566,184.658 143.467,182.758 C140.768,181.159 137.368,183.058 137.368,186.157 L137.368,227.848 Z" id="Shape" fill="#3A5070"></path>
                <polygon id="Path" fill="#12233D" points="183.158 144.967 183.158 163.862 292.434 100.977 292.434 82.081"></polygon>
                <polygon id="Path" fill="#2A3B56" points="183.258 144.967 183.258 163.862 75.383 101.077 75.383 82.181"></polygon>
                <polygon id="Path" fill="#1D2F52" points="183.158 144.967 183.158 149.666 292.434 86.78 292.434 82.081"></polygon>
                <polygon id="Path" fill="#1D2F52" points="183.258 144.967 183.258 149.666 75.383 86.78 75.383 82.181"></polygon>
                <polygon id="Path" fill="#3A5070" points="75.383 82.181 183.258 144.967 292.434 82.081 184.558 19.295"></polygon>
            </g>
        </motion.g>
        <motion.g variants={getSlideTopAnimation(Math.floor(Math.random() * 2) + 1.1)} id="block-5" mask="url(#mask-2)" fill-rule="nonzero">
            <g transform="translate(-59.000000, 344.519000)" id="Path">
                <polygon fill="#12233D" points="121.8722 620.859 245.6444 549.775 245.6444 106.076 121.8722 177.26"></polygon>
                <polygon fill="#2A3B56" points="2.13162821e-14 549.775 121.9722 620.859 121.9722 177.26 2.13162821e-14 106.176"></polygon>
                <polygon fill="#3A5070" points="0 106.176 121.9722 177.26 245.6444 106.076 123.4718 34.992"></polygon>
                <polygon fill="#1D2F52" points="149.366 197.555 142.1677 201.754 142.1677 193.456 149.366 189.257"></polygon>
                <polygon fill="#1D2F52" points="162.9624 189.757 155.7645 193.956 155.7645 185.658 162.9624 181.459"></polygon>
                <polygon fill="#1D2F52" points="176.5594 181.959 169.2614 186.058 169.2614 177.86 176.5594 173.661"></polygon>
                <polygon fill="#1D2F52" points="190.0564 174.061 182.8584 178.26 182.8584 169.962 190.0564 165.863"></polygon>
                <polygon fill="#1D2F52" points="203.6534 166.262 196.4554 170.461 196.4554 162.163 203.6534 157.964"></polygon>
                <polygon fill="#1D2F52" points="217.2504 158.464 209.9524 162.563 209.9524 154.365 217.2504 150.166"></polygon>
                <polygon fill="#1D2F52" points="230.7474 150.566 223.5494 154.765 223.5494 146.467 230.7474 142.368"></polygon>
                <polygon fill="#1D2F52" points="149.366 215.151 142.1677 219.25 142.1677 211.052 149.366 206.853"></polygon>
                <polygon fill="#1D2F52" points="162.9624 207.253 155.7645 211.452 155.7645 203.154 162.9624 199.055"></polygon>
                <polygon fill="#1D2F52" points="176.5594 199.455 169.2614 203.654 169.2614 195.356 176.5594 191.157"></polygon>
                <polygon fill="#1D2F52" points="190.0564 191.657 182.8584 195.756 182.8584 187.558 190.0564 183.359"></polygon>
                <polygon fill="#1D2F52" points="203.6534 183.759 196.4554 187.958 196.4554 179.66 203.6534 175.56"></polygon>
                <polygon fill="#1D2F52" points="217.2504 175.96 209.9524 180.159 209.9524 171.861 217.2504 167.662"></polygon>
                <polygon fill="#1D2F52" points="230.7474 168.162 223.5494 172.361 223.5494 164.063 230.7474 159.864"></polygon>
                <polygon fill="#1D2F52" points="149.366 232.647 142.1677 236.846 142.1677 228.548 149.366 224.349"></polygon>
                <polygon fill="#1D2F52" points="162.9624 224.849 155.7645 229.048 155.7645 220.75 162.9624 216.551"></polygon>
                <polygon fill="#1D2F52" points="176.5594 216.951 169.2614 221.15 169.2614 212.852 176.5594 208.753"></polygon>
                <polygon fill="#1D2F52" points="190.0564 209.153 182.8584 213.352 182.8584 205.054 190.0564 200.855"></polygon>
                <polygon fill="#1D2F52" points="203.6534 201.354 196.4554 205.553 196.4554 197.255 203.6534 193.056"></polygon>
                <polygon fill="#1D2F52" points="217.2504 193.556 209.9524 197.655 209.9524 189.357 217.2504 185.258"></polygon>
                <polygon fill="#1D2F52" points="230.7474 185.658 223.5494 189.857 223.5494 181.559 230.7474 177.36"></polygon>
                <polygon fill="#1D2F52" points="149.366 250.243 142.1677 254.343 142.1677 246.044 149.366 241.945"></polygon>
                <polygon fill="#1D2F52" points="162.9624 242.345 155.7645 246.544 155.7645 238.246 162.9624 234.047"></polygon>
                <polygon fill="#1D2F52" points="176.5594 234.547 169.2614 238.746 169.2614 230.448 176.5594 226.249"></polygon>
                <polygon fill="#1D2F52" points="190.0564 226.749 182.8584 230.848 182.8584 222.55 190.0564 218.451"></polygon>
                <polygon fill="#1D2F52" points="203.6534 218.85 196.4554 223.049 196.4554 214.751 203.6534 210.552"></polygon>
                <polygon fill="#1D2F52" points="217.2504 211.052 209.9524 215.251 209.9524 206.953 217.2504 202.754"></polygon>
                <polygon fill="#1D2F52" points="230.7474 203.254 223.5494 207.353 223.5494 199.055 230.7474 194.956"></polygon>
                <polygon fill="#1D2F52" points="149.366 267.739 142.1677 271.938 142.1677 263.64 149.366 259.441"></polygon>
                <polygon fill="#1D2F52" points="162.9624 259.941 155.7645 264.04 155.7645 255.742 162.9624 251.643"></polygon>
                <polygon fill="#1D2F52" points="176.5594 252.043 169.2614 256.242 169.2614 247.944 176.5594 243.745"></polygon>
                <polygon fill="#1D2F52" points="190.0564 244.245 182.8584 248.444 182.8584 240.146 190.0564 235.946"></polygon>
                <polygon fill="#1D2F52" points="203.6534 236.446 196.4554 240.545 196.4554 232.247 203.6534 228.148"></polygon>
                <polygon fill="#1D2F52" points="217.2504 228.548 209.9524 232.747 209.9524 224.449 217.2504 220.25"></polygon>
                <polygon fill="#1D2F52" points="230.7474 220.75 223.5494 224.949 223.5494 216.651 230.7474 212.452"></polygon>
                <polygon fill="#1D2F52" points="149.366 285.235 142.1677 289.434 142.1677 281.136 149.366 276.937"></polygon>
                <polygon fill="#1D2F52" points="162.9624 277.437 155.7645 281.636 155.7645 273.338 162.9624 269.139"></polygon>
                <polygon fill="#1D2F52" points="176.5594 269.639 169.2614 273.738 169.2614 265.54 176.5594 261.341"></polygon>
                <polygon fill="#1D2F52" points="190.0564 261.741 182.8584 265.94 182.8584 257.642 190.0564 253.443"></polygon>
                <polygon fill="#1D2F52" points="203.6534 253.942 196.4554 258.142 196.4554 249.843 203.6534 245.644"></polygon>
                <polygon fill="#1D2F52" points="217.2504 246.144 209.9524 250.243 209.9524 242.045 217.2504 237.846"></polygon>
                <polygon fill="#1D2F52" points="230.7474 238.246 223.5494 242.445 223.5494 234.147 230.7474 230.048"></polygon>
                <polygon fill="#1D2F52" points="149.366 302.831 142.1677 306.93 142.1677 298.732 149.366 294.533"></polygon>
                <polygon fill="#1D2F52" points="162.9624 294.933 155.7645 299.132 155.7645 290.834 162.9624 286.735"></polygon>
                <polygon fill="#1D2F52" points="176.5594 287.135 169.2614 291.334 169.2614 283.036 176.5594 278.837"></polygon>
                <polygon fill="#1D2F52" points="190.0564 279.337 182.8584 283.436 182.8584 275.238 190.0564 271.038"></polygon>
                <polygon fill="#1D2F52" points="203.6534 271.438 196.4554 275.637 196.4554 267.339 203.6534 263.24"></polygon>
                <polygon fill="#1D2F52" points="217.2504 263.64 209.9524 267.839 209.9524 259.541 217.2504 255.342"></polygon>
                <polygon fill="#1D2F52" points="230.7474 255.842 223.5494 259.941 223.5494 251.743 230.7474 247.544"></polygon>
                <polygon fill="#1D2F52" points="149.366 320.327 142.1677 324.526 142.1677 316.228 149.366 312.029"></polygon>
                <polygon fill="#1D2F52" points="162.9624 312.529 155.7645 316.628 155.7645 308.43 162.9624 304.231"></polygon>
                <polygon fill="#1D2F52" points="176.5594 304.631 169.2614 308.83 169.2614 300.532 176.5594 296.433"></polygon>
                <polygon fill="#1D2F52" points="190.0564 296.832 182.8584 301.032 182.8584 292.733 190.0564 288.534"></polygon>
                <polygon fill="#1D2F52" points="203.6534 289.034 196.4554 293.233 196.4554 284.935 203.6534 280.736"></polygon>
                <polygon fill="#1D2F52" points="217.2504 281.236 209.9524 285.335 209.9524 277.037 217.2504 272.938"></polygon>
                <polygon fill="#1D2F52" points="230.7474 273.338 223.5494 277.537 223.5494 269.239 230.7474 265.04"></polygon>
                <polygon fill="#1D2F52" points="149.366 337.923 142.1677 342.022 142.1677 333.724 149.366 329.625"></polygon>
                <polygon fill="#1D2F52" points="162.9624 330.025 155.7645 334.224 155.7645 325.926 162.9624 321.727"></polygon>
                <polygon fill="#1D2F52" points="176.5594 322.227 169.2614 326.426 169.2614 318.128 176.5594 313.929"></polygon>
                <polygon fill="#1D2F52" points="190.0564 314.429 182.8584 318.528 182.8584 310.23 190.0564 306.131"></polygon>
                <polygon fill="#1D2F52" points="203.6534 306.531 196.4554 310.73 196.4554 302.431 203.6534 298.232"></polygon>
                <polygon fill="#1D2F52" points="217.2504 298.732 209.9524 302.931 209.9524 294.633 217.2504 290.434"></polygon>
                <polygon fill="#1D2F52" points="230.7474 290.934 223.5494 295.033 223.5494 286.735 230.7474 282.636"></polygon>
                <polygon fill="#1D2F52" points="149.366 355.419 142.1677 359.618 142.1677 351.32 149.366 347.121"></polygon>
                <polygon fill="#1D2F52" points="162.9624 347.621 155.7645 351.72 155.7645 343.422 162.9624 339.323"></polygon>
                <polygon fill="#1D2F52" points="176.5594 339.723 169.2614 343.922 169.2614 335.624 176.5594 331.425"></polygon>
                <polygon fill="#1D2F52" points="190.0564 331.925 182.8584 336.124 182.8584 327.826 190.0564 323.627"></polygon>
                <polygon fill="#1D2F52" points="203.6534 324.126 196.4554 328.226 196.4554 319.927 203.6534 315.828"></polygon>
                <polygon fill="#1D2F52" points="217.2504 316.228 209.9524 320.427 209.9524 312.129 217.2504 307.93"></polygon>
                <polygon fill="#1D2F52" points="230.7474 308.43 223.5494 312.629 223.5494 304.331 230.7474 300.132"></polygon>
                <polygon fill="#1D2F52" points="149.366 372.915 142.1677 377.114 142.1677 368.816 149.366 364.617"></polygon>
                <polygon fill="#1D2F52" points="162.9624 365.117 155.7645 369.316 155.7645 361.018 162.9624 356.819"></polygon>
                <polygon fill="#1D2F52" points="176.5594 357.319 169.2614 361.418 169.2614 353.12 176.5594 349.021"></polygon>
                <polygon fill="#1D2F52" points="190.0564 349.421 182.8584 353.62 182.8584 345.322 190.0564 341.123"></polygon>
                <polygon fill="#1D2F52" points="203.6534 341.622 196.4554 345.821 196.4554 337.523 203.6534 333.324"></polygon>
                <polygon fill="#1D2F52" points="217.2504 333.824 209.9524 337.923 209.9524 329.725 217.2504 325.526"></polygon>
                <polygon fill="#1D2F52" points="230.7474 325.926 223.5494 330.125 223.5494 321.827 230.7474 317.728"></polygon>
                <polygon fill="#1D2F52" points="149.366 390.511 142.1677 394.611 142.1677 386.412 149.366 382.213"></polygon>
                <polygon fill="#1D2F52" points="162.9624 382.613 155.7645 386.812 155.7645 378.514 162.9624 374.415"></polygon>
                <polygon fill="#1D2F52" points="176.5594 374.815 169.2614 379.014 169.2614 370.716 176.5594 366.517"></polygon>
                <polygon fill="#1D2F52" points="190.0564 367.017 182.8584 371.116 182.8584 362.918 190.0564 358.719"></polygon>
                <polygon fill="#1D2F52" points="203.6534 359.118 196.4554 363.317 196.4554 355.019 203.6534 350.92"></polygon>
                <polygon fill="#1D2F52" points="217.2504 351.32 209.9524 355.519 209.9524 347.221 217.2504 343.022"></polygon>
                <polygon fill="#1D2F52" points="230.7474 343.522 223.5494 347.621 223.5494 339.423 230.7474 335.224"></polygon>
                <polygon fill="#1D2F52" points="149.366 408.007 142.1677 412.206 142.1677 403.908 149.366 399.709"></polygon>
                <polygon fill="#1D2F52" points="162.9624 400.209 155.7645 404.308 155.7645 396.11 162.9624 391.911"></polygon>
                <polygon fill="#1D2F52" points="176.5594 392.311 169.2614 396.51 169.2614 388.212 176.5594 384.113"></polygon>
                <polygon fill="#1D2F52" points="190.0564 384.513 182.8584 388.712 182.8584 380.414 190.0564 376.215"></polygon>
                <polygon fill="#1D2F52" points="203.6534 376.714 196.4554 380.813 196.4554 372.615 203.6534 368.416"></polygon>
                <polygon fill="#1D2F52" points="217.2504 368.816 209.9524 373.015 209.9524 364.717 217.2504 360.618"></polygon>
                <polygon fill="#1D2F52" points="230.7474 361.018 223.5494 365.217 223.5494 356.919 230.7474 352.72"></polygon>
                <polygon fill="#1D2F52" points="149.366 425.503 142.1677 429.702 142.1677 421.404 149.366 417.305"></polygon>
                <polygon fill="#1D2F52" points="162.9624 417.705 155.7645 421.904 155.7645 413.606 162.9624 409.407"></polygon>
                <polygon fill="#1D2F52" points="176.5594 409.907 169.2614 414.106 169.2614 405.808 176.5594 401.609"></polygon>
                <polygon fill="#1D2F52" points="190.0564 402.109 182.8584 406.208 182.8584 397.91 190.0564 393.81"></polygon>
                <polygon fill="#1D2F52" points="203.6534 394.21 196.4554 398.41 196.4554 390.111 203.6534 385.912"></polygon>
                <polygon fill="#1D2F52" points="217.2504 386.412 209.9524 390.611 209.9524 382.313 217.2504 378.114"></polygon>
                <polygon fill="#1D2F52" points="230.7474 378.614 223.5494 382.713 223.5494 374.415 230.7474 370.316"></polygon>
                <polygon fill="#1D2F52" points="149.366 443.099 142.1677 447.298 142.1677 439 149.366 434.801"></polygon>
                <polygon fill="#1D2F52" points="162.9624 435.301 155.7645 439.4 155.7645 431.102 162.9624 427.003"></polygon>
                <polygon fill="#1D2F52" points="176.5594 427.403 169.2614 431.602 169.2614 423.304 176.5594 419.105"></polygon>
                <polygon fill="#1D2F52" points="190.0564 419.605 182.8584 423.804 182.8584 415.506 190.0564 411.307"></polygon>
                <polygon fill="#1D2F52" points="203.6534 411.806 196.4554 415.906 196.4554 407.607 203.6534 403.508"></polygon>
                <polygon fill="#1D2F52" points="217.2504 403.908 209.9524 408.107 209.9524 399.809 217.2504 395.61"></polygon>
                <polygon fill="#1D2F52" points="230.7474 396.11 223.5494 400.309 223.5494 392.011 230.7474 387.812"></polygon>
                <polygon fill="#1D2F52" points="149.366 460.595 142.1677 464.794 142.1677 456.496 149.366 452.297"></polygon>
                <polygon fill="#1D2F52" points="162.9624 452.797 155.7645 456.996 155.7645 448.698 162.9624 444.499"></polygon>
                <polygon fill="#1D2F52" points="176.5594 444.999 169.2614 449.098 169.2614 440.8 176.5594 436.701"></polygon>
                <polygon fill="#1D2F52" points="190.0564 437.101 182.8584 441.3 182.8584 433.001 190.0564 428.802"></polygon>
                <polygon fill="#1D2F52" points="203.6534 429.302 196.4554 433.502 196.4554 425.203 203.6534 421.004"></polygon>
                <polygon fill="#1D2F52" points="217.2504 421.504 209.9524 425.603 209.9524 417.305 217.2504 413.206"></polygon>
                <polygon fill="#1D2F52" points="230.7474 413.606 223.5494 417.805 223.5494 409.507 230.7474 405.308"></polygon>
                <polygon fill="#1D2F52" points="149.366 478.191 142.1677 482.29 142.1677 473.992 149.366 469.893"></polygon>
                <polygon fill="#1D2F52" points="162.9624 470.293 155.7645 474.492 155.7645 466.194 162.9624 461.995"></polygon>
                <polygon fill="#1D2F52" points="176.5594 462.495 169.2614 466.694 169.2614 458.396 176.5594 454.197"></polygon>
                <polygon fill="#1D2F52" points="190.0564 454.697 182.8584 458.796 182.8584 450.598 190.0564 446.399"></polygon>
                <polygon fill="#1D2F52" points="203.6534 446.798 196.4554 450.997 196.4554 442.699 203.6534 438.6"></polygon>
                <polygon fill="#1D2F52" points="217.2504 439 209.9524 443.199 209.9524 434.901 217.2504 430.702"></polygon>
                <polygon fill="#1D2F52" points="230.7474 431.202 223.5494 435.301 223.5494 427.103 230.7474 422.904"></polygon>
                <polygon fill="#1D2F52" points="149.366 495.687 142.1677 499.886 142.1677 491.588 149.366 487.389"></polygon>
                <polygon fill="#1D2F52" points="162.9624 487.889 155.7645 491.988 155.7645 483.79 162.9624 479.591"></polygon>
                <polygon fill="#1D2F52" points="176.5594 479.991 169.2614 484.19 169.2614 475.892 176.5594 471.793"></polygon>
                <polygon fill="#1D2F52" points="190.0564 472.193 182.8584 476.392 182.8584 468.094 190.0564 463.895"></polygon>
                <polygon fill="#1D2F52" points="203.6534 464.394 196.4554 468.493 196.4554 460.295 203.6534 456.096"></polygon>
                <polygon fill="#1D2F52" points="217.2504 456.496 209.9524 460.695 209.9524 452.397 217.2504 448.298"></polygon>
                <polygon fill="#1D2F52" points="230.7474 448.698 223.5494 452.897 223.5494 444.599 230.7474 440.4"></polygon>
                <polygon fill="#1D2F52" points="149.366 513.183 142.1677 517.382 142.1677 509.084 149.366 504.985"></polygon>
                <polygon fill="#1D2F52" points="162.9624 505.385 155.7645 509.584 155.7645 501.286 162.9624 497.087"></polygon>
                <polygon fill="#1D2F52" points="176.5594 497.587 169.2614 501.786 169.2614 493.488 176.5594 489.288"></polygon>
                <polygon fill="#1D2F52" points="190.0564 489.689 182.8584 493.888 182.8584 485.59 190.0564 481.491"></polygon>
                <polygon fill="#1D2F52" points="203.6534 481.89 196.4554 486.089 196.4554 477.791 203.6534 473.592"></polygon>
                <polygon fill="#1D2F52" points="217.2504 474.092 209.9524 478.291 209.9524 469.993 217.2504 465.794"></polygon>
                <polygon fill="#1D2F52" points="230.7474 466.294 223.5494 470.393 223.5494 462.095 230.7474 457.996"></polygon>
                <polygon fill="#1D2F52" points="149.366 530.779 142.1677 534.878 142.1677 526.58 149.366 522.481"></polygon>
                <polygon fill="#1D2F52" points="162.9634 522.881 155.7646 527.08 155.7646 518.782 162.9634 514.583"></polygon>
                <polygon fill="#1D2F52" points="176.5594 515.083 169.2614 519.282 169.2614 510.984 176.5594 506.785"></polygon>
                <polygon fill="#1D2F52" points="190.0564 507.285 182.8584 511.384 182.8584 503.086 190.0564 498.986"></polygon>
                <polygon fill="#1D2F52" points="203.6534 499.387 196.4554 503.586 196.4554 495.287 203.6534 491.088"></polygon>
                <polygon fill="#1D2F52" points="217.2504 491.588 209.9524 495.787 209.9524 487.489 217.2504 483.29"></polygon>
                <polygon fill="#1D2F52" points="230.7474 483.79 223.5494 487.889 223.5494 479.591 230.7474 475.492"></polygon>
                <polygon fill="#1D2F52" points="149.366 548.275 142.1677 552.474 142.1677 544.176 149.366 539.977"></polygon>
                <polygon fill="#1D2F52" points="162.9624 540.477 155.7645 544.576 155.7645 536.278 162.9624 532.179"></polygon>
                <polygon fill="#1D2F52" points="176.5594 532.579 169.2614 536.778 169.2614 528.48 176.5594 524.281"></polygon>
                <polygon fill="#1D2F52" points="190.0564 524.781 182.8584 528.98 182.8584 520.682 190.0564 516.482"></polygon>
                <polygon fill="#1D2F52" points="203.6534 516.982 196.4554 521.082 196.4554 512.783 203.6534 508.684"></polygon>
                <polygon fill="#1D2F52" points="217.2504 509.084 209.9524 513.283 209.9524 504.985 217.2504 500.786"></polygon>
                <polygon fill="#1D2F52" points="230.7474 501.286 223.5494 505.485 223.5494 497.187 230.7474 492.988"></polygon>
                <polygon fill="#1D2F52" points="149.366 565.772 142.1677 569.971 142.1677 561.673 149.366 557.473"></polygon>
                <polygon fill="#1D2F52" points="162.9624 557.973 155.7645 562.172 155.7645 553.874 162.9624 549.675"></polygon>
                <polygon fill="#1D2F52" points="176.5594 550.175 169.2614 554.274 169.2614 546.076 176.5594 541.877"></polygon>
                <polygon fill="#1D2F52" points="190.0564 542.277 182.8584 546.476 182.8584 538.178 190.0564 534.079"></polygon>
                <polygon fill="#1D2F52" points="203.6534 534.479 196.4554 538.678 196.4554 530.38 203.6534 526.18"></polygon>
                <polygon fill="#1D2F52" points="217.2504 526.68 209.9524 530.779 209.9524 522.581 217.2504 518.382"></polygon>
                <polygon fill="#1D2F52" points="230.7474 518.782 223.5494 522.981 223.5494 514.683 230.7474 510.584"></polygon>
                <polygon fill="#1D2F52" points="149.366 583.367 142.1677 587.466 142.1677 579.268 149.366 575.069"></polygon>
                <polygon fill="#1D2F52" points="162.9624 575.469 155.7645 579.668 155.7645 571.37 162.9624 567.271"></polygon>
                <polygon fill="#1D2F52" points="176.5594 567.671 169.2614 571.87 169.2614 563.572 176.5594 559.373"></polygon>
                <polygon fill="#1D2F52" points="190.0564 559.873 182.8584 563.972 182.8584 555.774 190.0564 551.575"></polygon>
                <polygon fill="#1D2F52" points="203.6534 551.974 196.4554 556.173 196.4554 547.875 203.6534 543.776"></polygon>
                <polygon fill="#1D2F52" points="217.2504 544.176 209.9524 548.375 209.9524 540.077 217.2504 535.878"></polygon>
                <polygon fill="#1D2F52" points="230.7474 536.378 223.5494 540.477 223.5494 532.279 230.7474 528.08"></polygon>
                <polygon fill="#12233D" points="123.3719 150.066 200.1544 105.876 200.1544 73.884 123.3719 118.073"></polygon>
                <polygon fill="#2A3B56" points="47.6892 105.876 123.3719 150.066 123.3719 118.073 47.6892 73.983"></polygon>
                <polygon fill="#3A5070" points="47.6892 73.983 123.3719 118.073 200.1544 73.883 124.3717 29.694"></polygon>
                <polygon fill="#12233D" points="123.5718 100.677 171.5604 73.084 171.5604 41.391 123.5718 69.084"></polygon>
                <polygon fill="#2A3B56" points="76.2826 73.084 123.5719 100.677 123.5719 69.084 76.2826 41.491"></polygon>
                <polygon fill="#3A5070" points="76.2826 41.491 123.5719 69.085 171.5614 41.391 124.1717 13.797"></polygon>
                <polygon fill="#12233D" points="123.6718 -2.84217094e-14 123.6718 50.089 139.1683 41.091"></polygon>
                <polygon fill="#2A3B56" points="123.6718 -2.84217094e-14 123.6718 50.089 108.2753 41.091"></polygon>
                <polygon fill="#12233D" points="76.6177 626.175 103.6116 611.378 103.6116 200.572 76.6177 215.369"></polygon>
                <polygon fill="#2A3B56" points="7.9334 586.084 76.6177 626.175 76.6177 215.369 7.9334 175.378"></polygon>
                <polygon fill="#3A5070" points="7.9334 175.378 76.6177 215.369 103.6116 200.572 34.8273 160.481"></polygon>
                <polygon fill="#3A5070" points="65.92018 229.365 56.82225 224.067 56.82225 614.078 65.92018 619.377"></polygon>
            </g>
        </motion.g>
        <motion.g variants={getSlideTopAnimation(Math.floor(Math.random() * 2) + 1.1)} id="block-6" mask="url(#mask-2)" fill-rule="nonzero">
            <g transform="translate(396.996400, 586.564000)" id="Path">
                <polygon fill="#12233D" points="243.644 478.196 350.92 417.106 350.92 318.927 243.644 380.014"></polygon>
                <polygon fill="#202D43" points="-5.68434189e-14 336.924 243.744 478.196 243.744 380.014 -5.68434189e-14 238.746"></polygon>
                <polygon fill="#3A5070" points="-5.68434189e-14 238.746 243.744 380.014 350.92 318.928 107.075 177.56"></polygon>
                <polygon fill="#12233D" points="179.759 343.122 287.034 282.036 287.034 183.759 179.759 244.845"></polygon>
                <polygon fill="#202E45" points="17.196 249.344 179.859 343.122 179.859 244.845 17.196 151.266"></polygon>
                <polygon fill="#3A5070" points="17.196 151.266 179.859 244.844 287.034 183.758 124.271 90.08"></polygon>
                <polygon fill="#12233D" points="116.373 208.153 223.549 147.067 223.549 48.789 116.373 109.875"></polygon>
                <polygon fill="#2A3B56" points="30.993 159.364 116.373 208.153 116.373 109.875 30.993 61.186"></polygon>
                <polygon fill="#3A5070" points="30.993 61.186 116.373 109.875 223.549 48.789 138.068 0"></polygon>
                <polygon fill="#1D2F52" points="219.65 383.413 243.744 397.21 325.725 349.521 325.725 358.119 243.744 405.908 219.65 392.011"></polygon>
                <polygon fill="#1D2F52" points="219.65 391.011 243.744 404.908 325.725 357.119 325.725 365.817 243.744 413.506 219.65 399.709"></polygon>
                <polygon fill="#1D2F52" points="219.65 398.609 243.744 412.506 325.725 364.717 325.725 373.415 243.744 421.206 219.65 407.307"></polygon>
                <polygon fill="#1D2F52" points="219.65 406.208 243.744 420.106 325.725 372.316 325.725 381.014 243.744 428.806 219.65 414.906"></polygon>
                <polygon fill="#1D2F52" points="219.65 413.906 243.744 427.706 325.725 380.014 325.725 388.712 243.744 436.396 219.65 422.506"></polygon>
                <polygon fill="#1D2F52" points="219.65 421.506 243.744 435.406 325.725 387.612 325.725 396.31 243.744 444.096 219.65 430.206"></polygon>
                <polygon fill="#1D2F52" points="219.65 429.106 243.744 442.996 325.725 395.21 325.725 403.908 243.744 451.696 219.65 437.796"></polygon>
                <polygon fill="#1D2F52" points="219.65 436.796 243.744 450.596 325.725 402.909 325.725 411.507 243.744 459.296 219.65 445.396"></polygon>
                <polygon fill="#1D2F52" points="219.65 444.396 243.744 458.296 325.725 410.507 325.725 419.206 243.744 466.896 219.65 453.096"></polygon>
                <polygon fill="#1D2F52" points="155.264 247.244 179.359 261.141 261.34 213.452 261.34 222.05 179.359 269.839 155.264 255.942"></polygon>
                <polygon fill="#1D2F52" points="155.264 254.942 179.359 268.839 261.34 221.05 261.34 229.748 179.359 277.437 155.264 263.64"></polygon>
                <polygon fill="#1D2F52" points="155.264 262.541 179.359 276.437 261.34 228.648 261.34 237.346 179.359 285.135 155.264 271.239"></polygon>
                <polygon fill="#1D2F52" points="155.264 270.139 179.359 284.036 261.34 236.247 261.34 244.945 179.359 292.734 155.264 278.837"></polygon>
                <polygon fill="#1D2F52" points="155.264 277.837 179.359 291.634 261.34 243.945 261.34 252.643 179.359 300.332 155.264 286.435"></polygon>
                <polygon fill="#1D2F52" points="155.264 285.435 179.359 299.332 261.34 251.543 261.34 260.241 179.359 308.03 155.264 294.133"></polygon>
                <polygon fill="#1D2F52" points="155.264 293.033 179.359 306.93 261.34 259.141 261.34 267.839 179.359 315.628 155.264 301.731"></polygon>
                <polygon fill="#1D2F52" points="155.264 300.732 179.359 314.529 261.34 266.84 261.34 275.438 179.359 323.227 155.264 309.33"></polygon>
                <polygon fill="#1D2F52" points="155.264 308.33 179.359 322.227 261.34 274.438 261.34 283.136 179.359 330.825 155.264 317.028"></polygon>
                <polygon fill="#1D2F52" points="92.279 111.675 116.373 125.572 198.354 77.783 198.354 86.481 116.373 134.27 92.279 120.373"></polygon>
                <polygon fill="#1D2F52" points="92.279 119.373 116.373 133.17 198.355 85.481 198.355 94.179 116.373 141.868 92.279 127.971"></polygon>
                <polygon fill="#1D2F52" points="92.279 126.972 116.373 140.868 198.355 93.079 198.355 101.777 116.373 149.466 92.279 135.67"></polygon>
                <polygon fill="#1D2F52" points="92.279 134.57 116.373 148.467 198.355 100.677 198.355 109.375 116.373 157.165 92.279 143.268"></polygon>
                <polygon fill="#1D2F52" points="92.279 142.268 116.373 156.065 198.355 108.376 198.355 116.974 116.373 164.763 92.279 150.866"></polygon>
                <polygon fill="#1D2F52" points="92.279 149.866 116.373 163.763 198.355 115.974 198.355 124.672 116.373 172.361 92.279 158.564"></polygon>
                <polygon fill="#1D2F52" points="92.279 157.464 116.373 171.361 198.355 123.572 198.355 132.27 116.373 180.059 92.279 166.162"></polygon>
                <polygon fill="#1D2F52" points="92.279 165.063 116.373 178.96 198.355 131.17 198.355 139.869 116.373 187.658 92.279 173.761"></polygon>
                <polygon fill="#1D2F52" points="92.279 172.761 116.373 186.558 198.355 138.869 198.355 147.567 116.373 195.256 92.279 181.459"></polygon>
                <polygon fill="#3A5070" points="168.561 354.719 153.165 345.721 153.165 425.606 168.561 434.606"></polygon>
                <polygon fill="#3A5070" points="189.456 366.917 174.06 358.019 174.06 437.896 189.456 446.796"></polygon>
                <polygon fill="#3A5070" points="210.352 379.214 194.955 370.216 194.955 450.096 210.352 459.096"></polygon>
            </g>
        </motion.g>
        <motion.g variants={getSlideTopAnimation(Math.floor(Math.random() * 2) + 1.1)} id="block" mask="url(#mask-2)" fill-rule="nonzero">
            <g transform="translate(251.029400, 501.384000)">
                <polygon id="Path" fill="#1D2F52" points="131.27 571.566 264.44 495.086 264.44 76.482 131.27 153.065"></polygon>
                <polygon id="Path" fill="#2A3B56" points="0 495.087 131.37 571.566 131.37 153.065 0 76.582"></polygon>
                <polygon id="Path" fill="#3A5070" points="0 76.582 131.37 153.065 264.44 76.482 132.97 0"></polygon>
                <path d="M106.076,171.76 L106.076,182.758 L118.373,190.356 L118.373,179.358 L106.076,171.76 Z M83.681,169.861 L95.978,177.459 L95.978,166.461 L83.681,158.963 L83.681,169.861 Z M16.296,131.069 L28.593,138.668 L28.593,127.67 L16.296,120.172 L16.296,131.069 Z M38.791,143.966 L51.088,151.565 L51.088,140.567 L38.791,133.069 L38.791,143.966 Z M61.186,156.864 L73.483,164.462 L73.483,153.464 L61.186,145.966 L61.186,156.864 Z" id="Shape" fill="#3A5070"></path>
                <path d="M106.076,198.154 L106.076,209.152 L118.373,216.75 L118.373,205.752 L106.076,198.154 Z M83.681,196.255 L95.978,203.853 L95.978,192.855 L83.681,185.357 L83.681,196.255 Z M16.296,157.463 L28.593,165.062 L28.593,154.064 L16.296,146.566 L16.296,157.463 Z M38.791,170.36 L51.088,177.959 L51.088,166.961 L38.791,159.463 L38.791,170.36 Z M61.186,183.258 L73.483,190.856 L73.483,179.858 L61.186,172.36 L61.186,183.258 Z" id="Shape" fill="#3A5070"></path>
                <polygon id="Path" fill="#3A5070" points="106.076 224.548 106.076 235.546 118.373 243.144 118.373 232.146"></polygon>
                <polygon id="Path" fill="#3A5070" points="83.681 222.648 95.978 230.247 95.978 219.249 83.681 211.751"></polygon>
                <polygon id="Path" fill="#3A5070" points="16.296 183.857 28.593 191.456 28.593 180.458 16.296 172.96"></polygon>
                <polygon id="Path" fill="#3A5070" points="38.791 196.754 51.088 204.353 51.088 193.355 38.791 185.857"></polygon>
                <motion.polygon variants={lightsAnimation(getRandomDuration, getRandomDelay)} className="window" id="Path" fill="#3A5070" points="61.186 209.651 73.483 217.25 73.483 206.252 61.186 198.754"></motion.polygon>
                <path d="M106.076,250.942 L106.076,261.94 L118.373,269.538 L118.373,258.54 L106.076,250.942 Z M83.681,249.043 L95.978,256.641 L95.978,245.643 L83.681,238.145 L83.681,249.043 Z M16.296,210.251 L28.593,217.85 L28.593,206.852 L16.296,199.354 L16.296,210.251 Z M38.791,223.148 L51.088,230.747 L51.088,219.749 L38.791,212.251 L38.791,223.148 Z M61.186,236.046 L73.483,243.644 L73.483,232.646 L61.186,225.148 L61.186,236.046 Z" id="Shape" fill="#3A5070"></path>
                <polygon id="Path" fill="#3A5070" points="106.076 277.336 106.076 288.333 118.373 295.932 118.373 284.934"></polygon>
                <motion.polygon variants={lightsAnimation(getRandomDuration, getRandomDelay)} className="window" id="Path" fill="#3A5070" points="83.681 275.436 95.978 283.035 95.978 272.037 83.681 264.539"></motion.polygon>
                <polygon id="Path" fill="#3A5070" points="16.296 236.645 28.593 244.243 28.593 233.246 16.296 225.748"></polygon>
                <polygon id="Path" fill="#3A5070" points="38.791 249.542 51.088 257.141 51.088 246.143 38.791 238.645"></polygon>
                <polygon id="Path" fill="#3A5070" points="61.186 262.439 73.483 270.038 73.483 259.04 61.186 251.542"></polygon>
                <path d="M106.076,303.73 L106.076,314.728 L118.373,322.326 L118.373,311.328 L106.076,303.73 Z M83.681,301.831 L95.978,309.429 L95.978,298.431 L83.681,290.933 L83.681,301.831 Z M16.296,263.039 L28.593,270.638 L28.593,259.64 L16.296,252.142 L16.296,263.039 Z M38.791,275.936 L51.088,283.535 L51.088,272.537 L38.791,265.039 L38.791,275.936 Z M61.186,288.833 L73.483,296.432 L73.483,285.434 L61.186,277.936 L61.186,288.833 Z" id="Shape" fill="#3A5070"></path>
                <path d="M106.076,330.124 L106.076,341.121 L118.373,348.72 L118.373,337.722 L106.076,330.124 Z M83.681,328.224 L95.978,335.823 L95.978,324.825 L83.681,317.327 L83.681,328.224 Z M16.296,289.433 L28.593,297.031 L28.593,286.034 L16.296,278.536 L16.296,289.433 Z M38.791,302.33 L51.088,309.928 L51.088,298.931 L38.791,291.433 L38.791,302.33 Z M61.186,315.227 L73.483,322.826 L73.483,311.828 L61.186,304.33 L61.186,315.227 Z" id="Shape" fill="#3A5070"></path>
                <polygon id="Path" fill="#3A5070" points="106.076 356.518 106.076 367.515 118.373 375.114 118.373 364.116"></polygon>
                <polygon id="Path" fill="#3A5070" points="83.681 354.618 95.978 362.217 95.978 351.219 83.681 343.721"></polygon>
                <polygon id="Path" fill="#3A5070" points="16.296 315.827 28.593 323.425 28.593 312.428 16.296 304.929"></polygon>
                <motion.polygon variants={lightsAnimation(getRandomDuration, getRandomDelay)} className="window" id="Path" fill="#3A5070" points="38.791 328.724 51.088 336.322 51.088 325.325 38.791 317.827"></motion.polygon>
                <polygon id="Path" fill="#3A5070" points="61.186 341.621 73.483 349.219 73.483 338.222 61.186 330.724"></polygon>
                <path d="M106.076,382.912 L106.076,393.909 L118.373,401.508 L118.373,390.51 L106.076,382.912 Z M83.681,381.012 L95.978,388.611 L95.978,377.613 L83.681,370.115 L83.681,381.012 Z M16.296,342.221 L28.593,349.82 L28.593,338.822 L16.296,331.324 L16.296,342.221 Z M38.791,355.118 L51.088,362.717 L51.088,351.719 L38.791,344.221 L38.791,355.118 Z M61.186,368.015 L73.483,375.614 L73.483,364.616 L61.186,357.118 L61.186,368.015 Z" id="Shape" fill="#3A5070"></path>
                <path d="M106.076,409.306 L106.076,420.303 L118.373,427.902 L118.373,416.904 L106.076,409.306 Z M83.681,407.406 L95.978,415.004 L95.978,404.007 L83.681,396.509 L83.681,407.406 Z M16.296,368.615 L28.593,376.213 L28.593,365.216 L16.296,357.718 L16.296,368.615 Z M38.791,381.512 L51.088,389.11 L51.088,378.113 L38.791,370.615 L38.791,381.512 Z M61.186,394.409 L73.483,402.007 L73.483,391.01 L61.186,383.512 L61.186,394.409 Z" id="Shape" fill="#3A5070"></path>
                <polygon id="Path" fill="#3A5070" points="106.076 435.7 106.076 446.697 118.373 454.296 118.373 443.298"></polygon>
                <motion.polygon variants={lightsAnimation(getRandomDuration, getRandomDelay)} className="window" id="Path" fill="#3A5070" points="83.681 433.8 95.978 441.399 95.978 430.401 83.681 422.903"></motion.polygon>
                <polygon id="Path" fill="#3A5070" points="16.296 395.009 28.593 402.607 28.593 391.61 16.296 384.112"></polygon>
                <polygon id="Path" fill="#3A5070" points="38.791 407.906 51.088 415.504 51.088 404.507 38.791 397.009"></polygon>
                <polygon id="Path" fill="#3A5070" points="61.186 420.803 73.483 428.401 73.483 417.404 61.186 409.906"></polygon>
                <path d="M106.076,462.094 L106.076,473.091 L118.373,480.69 L118.373,469.692 L106.076,462.094 Z M83.681,460.194 L95.978,467.793 L95.978,456.795 L83.681,449.297 L83.681,460.194 Z M16.296,421.303 L28.593,428.901 L28.593,417.904 L16.296,410.406 L16.296,421.303 Z M38.791,434.3 L51.088,441.898 L51.088,430.901 L38.791,423.403 L38.791,434.3 Z M61.186,447.197 L73.483,454.795 L73.483,443.798 L61.186,436.3 L61.186,447.197 Z" id="Shape" fill="#3A5070"></path>
                <path d="M106.076,488.488 L106.076,499.486 L118.373,507.086 L118.373,496.086 L106.076,488.488 Z M83.681,486.588 L95.978,494.187 L95.978,483.189 L83.681,475.691 L83.681,486.588 Z M16.296,447.697 L28.593,455.296 L28.593,444.298 L16.296,436.8 L16.296,447.697 Z M38.791,460.694 L51.088,468.293 L51.088,457.295 L38.791,449.797 L38.791,460.694 Z M61.186,473.591 L73.483,481.19 L73.483,470.192 L61.186,462.694 L61.186,473.591 Z" id="Shape" fill="#3A5070"></path>
                <path d="M106.076,514.886 L106.076,525.876 L118.373,533.476 L118.373,522.476 L106.076,514.886 Z M83.681,512.986 L95.978,520.576 L95.978,509.586 L83.681,502.086 L83.681,512.986 Z M16.296,474.091 L28.593,481.689 L28.593,470.692 L16.296,463.194 L16.296,474.091 Z M38.791,487.088 L51.088,494.686 L51.088,483.689 L38.791,476.191 L38.791,487.088 Z M61.186,499.986 L73.483,507.586 L73.483,496.586 L61.186,489.088 L61.186,499.986 Z" id="Shape" fill="#3A5070"></path>
                <path d="M246.644,120.072 L234.347,127.57 L234.347,138.568 L246.644,130.97 L246.644,120.072 Z M224.249,132.969 L211.952,140.467 L211.952,151.465 L224.249,143.867 L224.249,132.969 Z M201.754,145.966 L189.457,153.464 L189.457,164.462 L201.754,156.864 L201.754,145.966 Z M144.567,179.358 L144.567,190.356 L156.864,182.758 L156.864,171.76 L144.567,179.358 Z M179.359,158.863 L167.062,166.361 L167.062,177.359 L179.359,169.761 L179.359,158.863 Z" id="Shape" fill="#192945"></path>
                <path d="M246.644,146.466 L234.347,153.964 L234.347,164.962 L246.644,157.363 L246.644,146.466 Z M224.249,159.363 L211.952,166.861 L211.952,177.859 L224.249,170.26 L224.249,159.363 Z M201.754,172.36 L189.457,179.858 L189.457,190.856 L201.754,183.258 L201.754,172.36 Z M144.567,205.752 L144.567,216.75 L156.864,209.152 L156.864,198.154 L144.567,205.752 Z M179.359,185.257 L167.062,192.755 L167.062,203.753 L179.359,196.155 L179.359,185.257 Z" id="Shape" fill="#192945"></path>
                <path d="M246.644,172.86 L234.347,180.358 L234.347,191.356 L246.644,183.758 L246.644,172.86 Z M224.249,185.757 L211.952,193.255 L211.952,204.253 L224.249,196.655 L224.249,185.757 Z M201.754,198.754 L189.457,206.253 L189.457,217.25 L201.754,209.652 L201.754,198.754 Z M144.567,232.147 L144.567,243.144 L156.864,235.546 L156.864,224.548 L144.567,232.147 Z M179.359,211.651 L167.062,219.15 L167.062,230.147 L179.359,222.549 L179.359,211.651 Z" id="Shape" fill="#192945"></path>
                <path d="M246.644,199.254 L234.347,206.752 L234.347,217.75 L246.644,210.151 L246.644,199.254 Z M224.249,212.151 L211.952,219.649 L211.952,230.647 L224.249,223.048 L224.249,212.151 Z M201.754,225.148 L189.457,232.646 L189.457,243.644 L201.754,236.045 L201.754,225.148 Z M144.567,258.54 L144.567,269.538 L156.864,261.939 L156.864,250.942 L144.567,258.54 Z M179.359,238.045 L167.062,245.543 L167.062,256.541 L179.359,248.942 L179.359,238.045 Z" id="Shape" fill="#192945"></path>
                <path d="M246.644,225.648 L234.347,233.146 L234.347,244.144 L246.644,236.545 L246.644,225.648 Z M224.249,238.545 L211.952,246.043 L211.952,257.041 L224.249,249.442 L224.249,238.545 Z M201.754,251.542 L189.457,259.04 L189.457,270.038 L201.754,262.439 L201.754,251.542 Z M144.567,284.934 L144.567,295.932 L156.864,288.334 L156.864,277.336 L144.567,284.934 Z M179.359,264.439 L167.062,271.937 L167.062,282.935 L179.359,275.337 L179.359,264.439 Z" id="Shape" fill="#192945"></path>
                <path d="M246.644,252.042 L234.347,259.54 L234.347,270.538 L246.644,262.939 L246.644,252.042 Z M224.249,264.939 L211.952,272.437 L211.952,283.435 L224.249,275.837 L224.249,264.939 Z M201.754,277.936 L189.457,285.434 L189.457,296.432 L201.754,288.834 L201.754,277.936 Z M144.567,311.328 L144.567,322.326 L156.864,314.728 L156.864,303.73 L144.567,311.328 Z M179.359,290.833 L167.062,298.331 L167.062,309.329 L179.359,301.731 L179.359,290.833 Z" id="Shape" fill="#192945"></path>
                <path d="M246.644,278.436 L234.347,285.934 L234.347,296.931 L246.644,289.333 L246.644,278.436 Z M224.249,291.333 L211.952,298.831 L211.952,309.829 L224.249,302.23 L224.249,291.333 Z M201.754,304.33 L189.457,311.828 L189.457,322.826 L201.754,315.227 L201.754,304.33 Z M144.567,337.722 L144.567,348.72 L156.864,341.121 L156.864,330.124 L144.567,337.722 Z M179.359,317.227 L167.062,324.725 L167.062,335.723 L179.359,328.124 L179.359,317.227 Z" id="Shape" fill="#192945"></path>
                <path d="M246.644,304.83 L234.347,312.328 L234.347,323.326 L246.644,315.728 L246.644,304.83 Z M224.249,317.727 L211.952,325.225 L211.952,336.223 L224.249,328.625 L224.249,317.727 Z M201.754,330.724 L189.457,338.222 L189.457,349.22 L201.754,341.622 L201.754,330.724 Z M144.567,364.116 L144.567,375.114 L156.864,367.516 L156.864,356.518 L144.567,364.116 Z M179.359,343.621 L167.062,351.119 L167.062,362.117 L179.359,354.519 L179.359,343.621 Z" id="Shape" fill="#192945"></path>
                <path d="M246.644,331.224 L234.347,338.722 L234.347,349.719 L246.644,342.121 L246.644,331.224 Z M224.249,344.121 L211.952,351.619 L211.952,362.616 L224.249,355.018 L224.249,344.121 Z M201.754,357.118 L189.457,364.616 L189.457,375.614 L201.754,368.015 L201.754,357.118 Z M144.567,390.51 L144.567,401.508 L156.864,393.909 L156.864,382.912 L144.567,390.51 Z M179.359,370.015 L167.062,377.513 L167.062,388.511 L179.359,380.912 L179.359,370.015 Z" id="Shape" fill="#192945"></path>
                <path d="M246.644,357.618 L234.347,365.116 L234.347,376.114 L246.644,368.515 L246.644,357.618 Z M224.249,370.515 L211.952,378.013 L211.952,389.011 L224.249,381.412 L224.249,370.515 Z M201.754,383.512 L189.457,391.01 L189.457,402.008 L201.754,394.409 L201.754,383.512 Z M144.567,416.904 L144.567,427.902 L156.864,420.303 L156.864,409.306 L144.567,416.904 Z M179.359,396.409 L167.062,403.907 L167.062,414.905 L179.359,407.306 L179.359,396.409 Z" id="Shape" fill="#192945"></path>
                <path d="M246.644,384.012 L234.347,391.51 L234.347,402.508 L246.644,394.909 L246.644,384.012 Z M224.249,396.909 L211.952,404.407 L211.952,415.405 L224.249,407.806 L224.249,396.909 Z M201.754,409.906 L189.457,417.404 L189.457,428.402 L201.754,420.803 L201.754,409.906 Z M144.567,443.298 L144.567,454.296 L156.864,446.698 L156.864,435.7 L144.567,443.298 Z M179.359,422.803 L167.062,430.301 L167.062,441.299 L179.359,433.7 L179.359,422.803 Z" id="Shape" fill="#192945"></path>
                <path d="M246.644,410.406 L234.347,417.904 L234.347,428.901 L246.644,421.303 L246.644,410.406 Z M224.249,423.303 L211.952,430.801 L211.952,441.798 L224.249,434.2 L224.249,423.303 Z M201.754,436.3 L189.457,443.798 L189.457,454.795 L201.754,447.197 L201.754,436.3 Z M144.567,469.692 L144.567,480.69 L156.864,473.091 L156.864,462.094 L144.567,469.692 Z M179.359,449.197 L167.062,456.695 L167.062,467.693 L179.359,460.094 L179.359,449.197 Z" id="Shape" fill="#192945"></path>
                <path d="M246.644,436.8 L234.347,444.298 L234.347,455.296 L246.644,447.697 L246.644,436.8 Z M224.249,449.697 L211.952,457.195 L211.952,468.193 L224.249,460.594 L224.249,449.697 Z M201.754,462.694 L189.457,470.192 L189.457,481.19 L201.754,473.591 L201.754,462.694 Z M144.567,496.086 L144.567,507.086 L156.864,499.486 L156.864,488.488 L144.567,496.086 Z M179.359,475.591 L167.062,483.089 L167.062,494.087 L179.359,486.489 L179.359,475.591 Z" id="Shape" fill="#192945"></path>
                <path d="M246.644,463.194 L234.347,470.692 L234.347,481.689 L246.644,474.091 L246.644,463.194 Z M224.249,476.091 L211.952,483.589 L211.952,494.586 L224.249,486.988 L224.249,476.091 Z M201.754,488.988 L189.457,496.486 L189.457,507.486 L201.754,499.886 L201.754,488.988 Z M144.567,522.476 L144.567,533.476 L156.864,525.876 L156.864,514.886 L144.567,522.476 Z M179.359,501.986 L167.062,509.486 L167.062,520.476 L179.359,512.886 L179.359,501.986 Z" id="Shape" fill="#192945"></path>
                <polygon id="Path" fill="#192945" points="131.57 117.473 131.57 134.869 232.047 77.082 232.047 59.686"></polygon>
                <polygon id="Path" fill="#2A3B56" points="131.67 117.473 131.67 134.869 32.592 77.082 32.592 59.786"></polygon>
                <polygon id="Path" fill="#202D43" opacity="0.5" points="131.57 117.473 131.57 121.772 232.047 63.985 232.047 59.686"></polygon>
                <polygon id="Path" fill="#3A5070" opacity="0.5" points="131.67 117.473 131.67 121.772 32.592 63.985 32.592 59.786"></polygon>
                <polygon id="Path" fill="#3A5070" points="32.592 59.786 131.67 117.473 232.047 59.686 132.87 1.899"></polygon>
            </g>
        </motion.g>
        <motion.g variants={getSlideTopAnimation(Math.floor(Math.random() * 2) + 1.1)} id="block-8" mask="url(#mask-2)" fill-rule="nonzero">
            <g transform="translate(1116.033400, 386.310000)" id="Path">
                <polygon fill="#12233D" points="139.37 662.85 280.74 581.567 280.74 137.068 139.37 218.35"></polygon>
                <polygon fill="url(#linearGradient-8)" points="1.13686838e-13 581.567 139.47 662.85 139.47 218.35 1.13686838e-13 137.169"></polygon>
                <polygon fill="#2A3B56" points="1.13686838e-13 581.567 139.47 662.85 139.47 218.35 1.13686838e-13 137.169"></polygon>
                <polygon fill="#3A5070" points="0 137.168 139.47 218.35 280.73 137.068 141.17 55.687"></polygon>
                <polygon fill="#12233D" points="139.57 200.854 248.84 138.068 248.84 104.976 139.57 167.862"></polygon>
                <polygon fill="#2A3B56" points="31.89 138.068 139.67 200.854 139.67 167.861 31.89 105.076"></polygon>
                <polygon fill="#3A5070" points="31.89 105.076 139.67 167.862 248.84 104.976 140.97 42.19"></polygon>
                <polygon fill="#12233D" points="139.77 150.066 216.65 105.876 216.65 73.883 139.77 118.073"></polygon>
                <polygon fill="#2A3B56" points="64.08 105.876 139.87 150.066 139.87 118.073 64.08 73.983"></polygon>
                <polygon fill="#3A5070" points="64.08 73.983 139.87 118.073 216.65 73.883 140.77 29.793"></polygon>
                <polygon fill="#12233D" points="140.07 100.677 188.06 73.083 188.06 41.39 140.07 69.084"></polygon>
                <polygon fill="#2A3B56" points="92.68 73.083 140.07 100.677 140.07 69.084 92.68 41.491"></polygon>
                <polygon fill="#3A5070" points="92.68 41.491 140.07 69.084 188.06 41.391 140.67 13.797"></polygon>
                <polygon fill="#12233D" points="80.78 660.45 107.47 645.75 107.47 239.445 80.78 254.142"></polygon>
                <polygon fill="#2A3B56" points="12.9 620.86 80.88 660.45 80.88 254.142 12.9 214.551"></polygon>
                <polygon fill="#3A5070" points="12.9 214.551 80.88 254.142 107.47 239.445 39.49 199.854"></polygon>
                <polygon fill="#2A3B56" points="199.95 660.45 173.26 645.75 173.26 239.445 199.95 254.142"></polygon>
                <polygon fill="#12233D" points="267.84 620.86 199.85 660.45 199.85 254.142 267.84 214.551"></polygon>
                <polygon fill="#3A5070" points="267.84 214.551 199.85 254.142 173.26 239.445 241.24 199.854"></polygon>
                <polygon fill="#1D2F52" points="250.14 240.845 259.34 235.546 259.34 625.56 250.14 630.86"></polygon>
                <polygon fill="#1D2F52" points="230.75 252.342 239.84 247.044 239.84 636.95 230.75 642.35"></polygon>
                <polygon fill="#1D2F52" points="211.25 263.74 220.45 258.441 220.45 648.45 211.25 653.75"></polygon>
                <polygon fill="#3A5070" points="30.59 240.845 21.49 235.546 21.49 625.56 30.59 630.86"></polygon>
                <polygon fill="#3A5070" points="50.09 252.342 40.89 247.044 40.89 636.95 50.09 642.35"></polygon>
                <polygon fill="#3A5070" points="69.48 263.74 60.38 258.441 60.38 648.45 69.48 653.75"></polygon>
                <polygon fill="#12233D" points="140.07 -2.84217094e-14 140.07 50.088 155.66 41.09"></polygon>
                <polygon fill="#2A3B56" points="140.07 -2.84217094e-14 140.07 50.088 124.77 41.09"></polygon>
            </g>
        </motion.g>
        <motion.g variants={getSlideTopAnimation(Math.floor(Math.random() * 2) + 1.1)} id="block-3" mask="url(#mask-2)" fill-rule="nonzero">
            <g transform="translate(716.923400, 593.263000)">
                <polygon id="Path" fill="#12233D" points="152.565 777.327 307.43 688.247 307.43 132.77 152.565 221.849"></polygon>
                <polygon id="Path" fill="#2A3B56" points="1.13686838e-13 688.247 152.665 777.327 152.665 221.849 1.13686838e-13 132.869"></polygon>
                <polygon id="Path" fill="#3A5070" points="-5.68434189e-14 132.87 152.665 221.849 307.43 132.77 154.565 43.79"></polygon>
                <polygon id="Path" fill="#1D2F52" points="186.957 247.343 177.959 252.542 177.959 242.144 186.957 236.946"></polygon>
                <polygon id="Path" fill="#1D2F52" points="203.953 237.546 194.955 242.744 194.955 232.347 203.953 227.148"></polygon>
                <polygon id="Path" fill="#1D2F52" points="220.949 227.748 211.951 232.947 211.951 222.549 220.949 217.35"></polygon>
                <polygon id="Path" fill="#1D2F52" points="237.946 217.95 228.948 223.149 228.948 212.751 237.946 207.552"></polygon>
                <polygon id="Path" fill="#1D2F52" points="254.942 208.152 245.844 213.351 245.844 202.953 254.942 197.755"></polygon>
                <polygon id="Path" fill="#1D2F52" points="271.938 198.255 262.84 203.553 262.84 193.156 271.938 187.957"></polygon>
                <polygon id="Path" fill="#1D2F52" points="288.83 188.457 279.836 193.756 279.836 183.358 288.83 178.159"></polygon>
                <polygon id="Path" fill="#1D2F52" points="186.957 269.238 177.959 274.537 177.959 264.139 186.957 258.941"></polygon>
                <polygon id="Path" fill="#1D2F52" points="203.953 259.441 194.955 264.739 194.955 254.342 203.953 249.143"></polygon>
                <polygon id="Path" fill="#1D2F52" points="220.949 249.643 211.951 254.842 211.951 244.544 220.949 239.245"></polygon>
                <polygon id="Path" fill="#1D2F52" points="237.946 239.845 228.948 245.044 228.948 234.746 237.946 229.447"></polygon>
                <polygon id="Path" fill="#1D2F52" points="254.942 230.047 245.844 235.246 245.844 224.948 254.942 219.65"></polygon>
                <polygon id="Path" fill="#1D2F52" points="271.938 220.249 262.84 225.448 262.84 215.151 271.938 209.852"></polygon>
                <polygon id="Path" fill="#1D2F52" points="288.83 210.452 279.836 215.651 279.836 205.253 288.83 200.054"></polygon>
                <polygon id="Path" fill="#1D2F52" points="186.957 291.233 177.959 296.432 177.959 286.034 186.957 280.836"></polygon>
                <polygon id="Path" fill="#1D2F52" points="203.953 281.435 194.955 286.634 194.955 276.237 203.953 271.038"></polygon>
                <polygon id="Path" fill="#1D2F52" points="220.949 271.638 211.951 276.836 211.951 266.439 220.949 261.24"></polygon>
                <polygon id="Path" fill="#1D2F52" points="237.946 261.84 228.948 267.039 228.948 256.641 237.946 251.442"></polygon>
                <polygon id="Path" fill="#1D2F52" points="254.942 252.042 245.844 257.241 245.844 246.843 254.942 241.645"></polygon>
                <polygon id="Path" fill="#1D2F52" points="271.938 242.244 262.84 247.443 262.84 237.046 271.938 231.847"></polygon>
                <polygon id="Path" fill="#1D2F52" points="288.83 232.447 279.836 237.645 279.836 227.248 288.83 222.049"></polygon>
                <polygon id="Path" fill="#1D2F52" points="186.957 313.228 177.959 318.427 177.959 308.03 186.957 302.831"></polygon>
                <polygon id="Path" fill="#1D2F52" points="203.953 303.431 194.955 308.629 194.955 298.232 203.953 293.033"></polygon>
                <polygon id="Path" fill="#1D2F52" points="220.949 293.533 211.951 298.832 211.951 288.434 220.949 283.235"></polygon>
                <polygon id="Path" fill="#1D2F52" points="237.946 283.735 228.948 289.034 228.948 278.636 237.946 273.437"></polygon>
                <polygon id="Path" fill="#1D2F52" points="254.942 273.937 245.844 279.236 245.844 268.838 254.942 263.64"></polygon>
                <polygon id="Path" fill="#1D2F52" points="271.938 264.14 262.84 269.338 262.84 259.041 271.938 253.842"></polygon>
                <polygon id="Path" fill="#1D2F52" points="288.83 254.342 279.836 259.541 279.836 249.243 288.83 243.944"></polygon>
                <polygon id="Path" fill="#1D2F52" points="186.957 335.123 177.959 340.322 177.959 330.025 186.957 324.726"></polygon>
                <polygon id="Path" fill="#1D2F52" points="203.953 325.326 194.955 330.524 194.955 320.227 203.953 314.928"></polygon>
                <polygon id="Path" fill="#1D2F52" points="220.949 315.528 211.951 320.727 211.951 310.429 220.949 305.13"></polygon>
                <polygon id="Path" fill="#1D2F52" points="237.946 305.73 228.948 310.929 228.948 300.531 237.946 295.333"></polygon>
                <polygon id="Path" fill="#1D2F52" points="254.942 295.932 245.844 301.131 245.844 290.733 254.942 285.535"></polygon>
                <polygon id="Path" fill="#1D2F52" points="271.938 286.135 262.84 291.333 262.84 280.936 271.938 275.737"></polygon>
                <polygon id="Path" fill="#1D2F52" points="288.83 276.337 279.836 281.536 279.836 271.138 288.83 265.939"></polygon>
                <polygon id="Path" fill="#1D2F52" points="186.957 357.118 177.959 362.317 177.959 351.919 186.957 346.721"></polygon>
                <polygon id="Path" fill="#1D2F52" points="203.953 347.321 194.955 352.519 194.955 342.122 203.953 336.923"></polygon>
                <polygon id="Path" fill="#1D2F52" points="220.949 337.523 211.951 342.721 211.951 332.324 220.949 327.125"></polygon>
                <polygon id="Path" fill="#1D2F52" points="237.946 327.725 228.948 332.924 228.948 322.526 237.946 317.327"></polygon>
                <polygon id="Path" fill="#1D2F52" points="254.942 317.927 245.844 323.126 245.844 312.728 254.942 307.529"></polygon>
                <polygon id="Path" fill="#1D2F52" points="271.938 308.029 262.84 313.328 262.84 302.931 271.938 297.732"></polygon>
                <polygon id="Path" fill="#1D2F52" points="288.83 298.232 279.836 303.53 279.836 293.133 288.83 287.934"></polygon>
                <polygon id="Path" fill="#1D2F52" points="186.957 379.013 177.959 384.312 177.959 373.914 186.957 368.715"></polygon>
                <polygon id="Path" fill="#1D2F52" points="203.953 369.215 194.955 374.514 194.955 364.116 203.953 358.918"></polygon>
                <polygon id="Path" fill="#1D2F52" points="220.949 359.418 211.951 364.617 211.951 354.319 220.949 349.12"></polygon>
                <polygon id="Path" fill="#1D2F52" points="237.946 349.62 228.948 354.818 228.948 344.521 237.946 339.222"></polygon>
                <polygon id="Path" fill="#1D2F52" points="254.942 339.822 245.844 345.021 245.844 334.723 254.942 329.424"></polygon>
                <polygon id="Path" fill="#1D2F52" points="271.938 330.024 262.84 335.223 262.84 324.926 271.938 319.627"></polygon>
                <polygon id="Path" fill="#1D2F52" points="288.83 320.226 279.836 325.425 279.836 315.028 288.83 309.829"></polygon>
                <polygon id="Path" fill="#1D2F52" points="186.957 401.008 177.959 406.207 177.959 395.809 186.957 390.611"></polygon>
                <polygon id="Path" fill="#1D2F52" points="203.953 391.21 194.955 396.409 194.955 386.011 203.953 380.813"></polygon>
                <polygon id="Path" fill="#1D2F52" points="220.949 381.412 211.951 386.611 211.951 376.214 220.949 371.015"></polygon>
                <polygon id="Path" fill="#1D2F52" points="237.946 371.615 228.948 376.814 228.948 366.416 237.946 361.217"></polygon>
                <polygon id="Path" fill="#1D2F52" points="254.942 361.817 245.844 367.016 245.844 356.618 254.942 351.419"></polygon>
                <polygon id="Path" fill="#1D2F52" points="271.938 352.019 262.84 357.218 262.84 346.82 271.938 341.622"></polygon>
                <polygon id="Path" fill="#1D2F52" points="288.83 342.222 279.836 347.42 279.836 337.023 288.83 331.824"></polygon>
                <polygon id="Path" fill="#1D2F52" points="186.957 423.007 177.959 428.197 177.959 417.807 186.957 412.607"></polygon>
                <polygon id="Path" fill="#1D2F52" points="203.953 413.207 194.955 418.407 194.955 408.007 203.953 402.808"></polygon>
                <polygon id="Path" fill="#1D2F52" points="220.949 403.308 211.951 408.607 211.951 398.209 220.949 393.01"></polygon>
                <polygon id="Path" fill="#1D2F52" points="237.946 393.51 228.948 398.809 228.948 388.411 237.946 383.212"></polygon>
                <polygon id="Path" fill="#1D2F52" points="254.942 383.712 245.844 389.011 245.844 378.613 254.942 373.414"></polygon>
                <polygon id="Path" fill="#1D2F52" points="271.938 373.914 262.84 379.213 262.84 368.815 271.938 363.617"></polygon>
                <polygon id="Path" fill="#1D2F52" points="288.83 364.117 279.836 369.315 279.836 359.018 288.83 353.719"></polygon>
                <polygon id="Path" fill="#1D2F52" points="186.957 444.897 177.959 450.097 177.959 439.797 186.957 434.497"></polygon>
                <polygon id="Path" fill="#1D2F52" points="203.953 435.097 194.955 440.297 194.955 429.997 203.953 424.707"></polygon>
                <polygon id="Path" fill="#1D2F52" points="220.949 425.307 211.951 430.497 211.951 420.207 220.949 414.907"></polygon>
                <polygon id="Path" fill="#1D2F52" points="237.946 415.507 228.948 420.707 228.948 410.307 237.946 405.107"></polygon>
                <polygon id="Path" fill="#1D2F52" points="254.942 405.707 245.844 410.907 245.844 400.508 254.942 395.31"></polygon>
                <polygon id="Path" fill="#1D2F52" points="271.938 395.909 262.84 401.108 262.84 390.711 271.938 385.512"></polygon>
                <polygon id="Path" fill="#1D2F52" points="288.83 386.112 279.836 391.311 279.836 380.913 288.83 375.714"></polygon>
                <polygon id="Path" fill="#1D2F52" points="186.957 466.897 177.959 472.087 177.959 461.697 186.957 456.497"></polygon>
                <polygon id="Path" fill="#1D2F52" points="203.953 457.097 194.955 462.297 194.955 451.897 203.953 446.697"></polygon>
                <polygon id="Path" fill="#1D2F52" points="220.949 447.297 211.951 452.497 211.951 442.097 220.949 436.897"></polygon>
                <polygon id="Path" fill="#1D2F52" points="237.946 437.497 228.948 442.697 228.948 432.297 237.946 427.107"></polygon>
                <polygon id="Path" fill="#1D2F52" points="254.942 427.707 245.844 432.897 245.844 422.507 254.942 417.307"></polygon>
                <polygon id="Path" fill="#1D2F52" points="271.938 417.907 262.84 423.107 262.84 412.707 271.938 407.507"></polygon>
                <polygon id="Path" fill="#1D2F52" points="288.83 408.007 279.836 413.307 279.836 402.908 288.83 397.709"></polygon>
                <polygon id="Path" fill="#1D2F52" points="186.957 488.787 177.959 494.087 177.959 483.687 186.957 478.487"></polygon>
                <polygon id="Path" fill="#1D2F52" points="203.953 478.987 194.955 484.287 194.955 473.887 203.953 468.697"></polygon>
                <polygon id="Path" fill="#1D2F52" points="220.949 469.197 211.951 474.487 211.951 464.097 220.949 458.897"></polygon>
                <polygon id="Path" fill="#1D2F52" points="237.946 459.397 228.948 464.597 228.948 454.297 237.946 449.097"></polygon>
                <polygon id="Path" fill="#1D2F52" points="254.942 449.597 245.844 454.797 245.844 444.497 254.942 439.197"></polygon>
                <polygon id="Path" fill="#1D2F52" points="271.938 439.797 262.84 444.997 262.84 434.697 271.938 429.397"></polygon>
                <polygon id="Path" fill="#1D2F52" points="288.83 429.997 279.836 435.197 279.836 424.907 288.83 419.607"></polygon>
                <polygon id="Path" fill="#1D2F52" points="220.949 491.187 211.951 496.387 211.951 485.987 220.949 480.787"></polygon>
                <polygon id="Path" fill="#1D2F52" points="237.946 481.387 228.948 486.587 228.948 476.187 237.946 470.997"></polygon>
                <polygon id="Path" fill="#1D2F52" points="254.942 471.597 245.844 476.787 245.844 466.397 254.942 461.197"></polygon>
                <polygon id="Path" fill="#1D2F52" points="271.938 461.797 262.84 466.997 262.84 456.597 271.938 451.397"></polygon>
                <polygon id="Path" fill="#1D2F52" points="288.83 451.997 279.836 457.197 279.836 446.797 288.83 441.597"></polygon>
                <polygon id="Path" fill="#1D2F52" points="254.942 493.487 245.844 498.787 245.844 488.387 254.942 483.187"></polygon>
                <polygon id="Path" fill="#1D2F52" points="271.938 483.687 262.84 488.987 262.84 478.587 271.938 473.387"></polygon>
                <polygon id="Path" fill="#1D2F52" points="288.83 473.887 279.836 479.087 279.836 468.797 288.83 463.597"></polygon>
                <polygon id="Path" fill="#1D2F52" points="288.83 495.887 279.836 501.087 279.836 490.687 288.83 485.487"></polygon>
                <polygon id="Path" fill="#12233D" points="154.364 187.757 250.543 132.47 250.543 92.479 154.364 147.766"></polygon>
                <polygon id="Path" fill="#2A3B56" points="59.586 132.47 154.464 187.757 154.464 147.766 59.586 92.479"></polygon>
                <polygon id="Path" fill="#3A5070" points="59.586 92.479 154.465 147.766 250.543 92.479 155.564 37.191"></polygon>
                <polygon id="Path" fill="#12233D" points="154.664 125.971 214.751 91.379 214.751 51.788 154.664 86.38"></polygon>
                <polygon id="Path" fill="#2A3B56" points="95.378 91.379 154.664 125.971 154.664 86.38 95.378 51.888"></polygon>
                <polygon id="Path" fill="#3A5070" points="95.378 51.888 154.664 86.38 214.751 51.788 155.364 17.196"></polygon>
                <polygon id="Path" fill="#12233D" points="154.764 0 154.764 62.586 174.26 51.388"></polygon>
                <polygon id="Path" fill="#2A3B56" points="154.764 0 154.764 62.586 135.569 51.388"></polygon>
                <polygon id="Path" fill="#3A5070" points="113.074 235.846 113.074 246.843 125.371 254.442 125.371 243.444"></polygon>
                <polygon id="Path" fill="#3A5070" points="90.679 233.946 102.976 241.545 102.976 230.547 90.679 223.049"></polygon>
                <polygon id="Path" fill="#3A5070" points="23.294 195.155 35.592 202.753 35.592 191.756 23.294 184.258"></polygon>
                <polygon id="Path" fill="#3A5070" points="45.789 208.052 58.087 215.651 58.087 204.653 45.789 197.155"></polygon>
                <polygon id="Path" fill="#3A5070" points="68.184 220.949 80.481 228.548 80.481 217.55 68.184 210.052"></polygon>
                <path d="M113.074,262.24 L113.074,273.238 L125.371,280.836 L125.371,269.838 L113.074,262.24 Z M90.679,260.34 L102.976,267.939 L102.976,256.941 L90.679,249.443 L90.679,260.34 Z M23.294,221.549 L35.592,229.148 L35.592,218.15 L23.294,210.652 L23.294,221.549 Z M45.789,234.446 L58.087,242.045 L58.087,231.047 L45.789,223.549 L45.789,234.446 Z M68.184,247.343 L80.481,254.942 L80.481,243.944 L68.184,236.446 L68.184,247.343 Z" id="Shape" fill="#3A5070"></path>
                <path d="M113.074,288.634 L113.074,299.632 L125.371,307.23 L125.371,296.232 L113.074,288.634 Z M90.679,286.735 L102.976,294.333 L102.976,283.335 L90.679,275.837 L90.679,286.735 Z M23.294,247.943 L35.592,255.542 L35.592,244.544 L23.294,237.046 L23.294,247.943 Z M45.789,260.84 L58.087,268.439 L58.087,257.441 L45.789,249.943 L45.789,260.84 Z M68.184,273.737 L80.481,281.336 L80.481,270.338 L68.184,262.84 L68.184,273.737 Z" id="Shape" fill="#3A5070"></path>
                <path d="M113.074,315.028 L113.074,326.025 L125.371,333.624 L125.371,322.626 L113.074,315.028 Z M90.679,313.128 L102.976,320.727 L102.976,309.729 L90.679,302.231 L90.679,313.128 Z M23.294,274.337 L35.592,281.935 L35.592,270.938 L23.294,263.44 L23.294,274.337 Z M45.789,287.234 L58.087,294.832 L58.087,283.835 L45.789,276.337 L45.789,287.234 Z M68.184,300.131 L80.481,307.729 L80.481,296.732 L68.184,289.234 L68.184,300.131 Z" id="Shape" fill="#3A5070"></path>
                <path d="M113.074,341.422 L113.074,352.42 L125.371,360.018 L125.371,349.02 L113.074,341.422 Z M90.679,339.522 L102.976,347.121 L102.976,336.123 L90.679,328.625 L90.679,339.522 Z M23.294,300.731 L35.592,308.33 L35.592,297.332 L23.294,289.834 L23.294,300.731 Z M45.789,313.628 L58.087,321.227 L58.087,310.229 L45.789,302.731 L45.789,313.628 Z M68.184,326.525 L80.481,334.124 L80.481,323.126 L68.184,315.628 L68.184,326.525 Z" id="Shape" fill="#3A5070"></path>
                <path d="M113.074,367.816 L113.074,378.813 L125.371,386.412 L125.371,375.414 L113.074,367.816 Z M90.679,365.916 L102.976,373.515 L102.976,362.517 L90.679,355.019 L90.679,365.916 Z M23.294,327.125 L35.592,334.723 L35.592,323.726 L23.294,316.228 L23.294,327.125 Z M45.789,340.022 L58.087,347.62 L58.087,336.623 L45.789,329.125 L45.789,340.022 Z M68.184,352.919 L80.481,360.517 L80.481,349.52 L68.184,342.022 L68.184,352.919 Z" id="Shape" fill="#3A5070"></path>
                <path d="M113.074,394.21 L113.074,405.207 L125.371,412.807 L125.371,401.808 L113.074,394.21 Z M90.679,392.31 L102.976,399.909 L102.976,388.911 L90.679,381.413 L90.679,392.31 Z M23.294,353.519 L35.592,361.117 L35.592,350.12 L23.294,342.622 L23.294,353.519 Z M45.789,366.416 L58.087,374.014 L58.087,363.017 L45.789,355.519 L45.789,366.416 Z M68.184,379.313 L80.481,386.912 L80.481,375.914 L68.184,368.416 L68.184,379.313 Z" id="Shape" fill="#3A5070"></path>
                <path d="M113.074,420.607 L113.074,431.597 L125.371,439.197 L125.371,428.207 L113.074,420.607 Z M90.679,418.707 L102.976,426.307 L102.976,415.307 L90.679,407.807 L90.679,418.707 Z M23.294,379.913 L35.592,387.512 L35.592,376.514 L23.294,369.016 L23.294,379.913 Z M45.789,392.81 L58.087,400.409 L58.087,389.411 L45.789,381.913 L45.789,392.81 Z M68.184,405.707 L80.481,413.307 L80.481,402.308 L68.184,394.81 L68.184,405.707 Z" id="Shape" fill="#3A5070"></path>
                <path d="M113.074,446.997 L113.074,457.997 L125.371,465.597 L125.371,454.597 L113.074,446.997 Z M90.679,445.097 L102.976,452.697 L102.976,441.697 L90.679,434.197 L90.679,445.097 Z M23.294,406.307 L35.592,413.907 L35.592,402.908 L23.294,395.41 L23.294,406.307 Z M45.789,419.207 L58.087,426.807 L58.087,415.807 L45.789,408.307 L45.789,419.207 Z M68.184,432.097 L80.481,439.697 L80.481,428.697 L68.184,421.207 L68.184,432.097 Z" id="Shape" fill="#3A5070"></path>
                <path d="M113.074,473.387 L113.074,484.387 L125.371,491.987 L125.371,480.987 L113.074,473.387 Z M90.679,471.497 L102.976,479.087 L102.976,468.097 L90.679,460.597 L90.679,471.497 Z M23.294,432.697 L35.592,440.297 L35.592,429.307 L23.294,421.807 L23.294,432.697 Z M45.789,445.597 L58.087,453.197 L58.087,442.197 L45.789,434.697 L45.789,445.597 Z M68.184,458.497 L80.481,466.097 L80.481,455.097 L68.184,447.597 L68.184,458.497 Z" id="Shape" fill="#3A5070"></path>
                <path d="M113.074,499.787 L113.074,510.787 L125.371,518.377 L125.371,507.387 L113.074,499.787 Z M90.679,497.887 L102.976,505.487 L102.976,494.487 L90.679,486.987 L90.679,497.887 Z M23.294,459.097 L35.592,466.697 L35.592,455.697 L23.294,448.197 L23.294,459.097 Z M45.789,471.997 L58.087,479.587 L58.087,468.597 L45.789,461.097 L45.789,471.997 Z M68.184,484.887 L80.481,492.487 L80.481,481.487 L68.184,473.987 L68.184,484.887 Z" id="Shape" fill="#3A5070"></path>
                <path d="M113.074,526.177 L113.074,537.177 L125.371,544.777 L125.371,533.777 L113.074,526.177 Z M90.679,524.277 L102.976,531.877 L102.976,520.877 L90.679,513.387 L90.679,524.277 Z M23.294,485.387 L35.592,492.987 L35.592,481.987 L23.294,474.487 L23.294,485.387 Z M45.789,498.387 L58.087,505.987 L58.087,494.987 L45.789,487.487 L45.789,498.387 Z M68.184,511.287 L80.481,518.877 L80.481,507.887 L68.184,500.387 L68.184,511.287 Z" id="Shape" fill="#3A5070"></path>
            </g>
        </motion.g>
        <motion.g variants={getSlideTopAnimation(Math.floor(Math.random() * 2) + 1.1)} id="block-17" mask="url(#mask-2)" fill-rule="nonzero">
            <g transform="translate(1217.513400, 968.277000)" id="Path">
                <polygon fill="#12233D" points="135.06 403.013 272.23 324.123 272.23 78.783 135.06 157.663"></polygon>
                <polygon fill="#2A3B56" points="-1.13686838e-13 324.123 135.16 403.013 135.16 157.663 -1.13686838e-13 78.883"></polygon>
                <polygon fill="#3A5070" points="0 78.883 135.16 157.663 272.23 78.783 136.86 0"></polygon>
            </g>
        </motion.g>
        <motion.g variants={getSlideTopAnimation(Math.floor(Math.random() * 2) + 1.1)} id="block-18" mask="url(#mask-2)" fill-rule="nonzero">
            <g transform="translate(1783.383400, 934.285000)" id="Path">
                <polygon fill="#12233D" points="135.07 403.005 272.23 324.125 272.23 78.785 135.07 157.665"></polygon>
                <polygon fill="#2A3B56" points="1.13686838e-13 324.125 135.17 403.005 135.17 157.665 1.13686838e-13 78.885"></polygon>
                <polygon fill="#3A5070" points="3.41060513e-13 78.885 135.17 157.665 272.23 78.785 136.87 -5.68434189e-14"></polygon>
            </g>
        </motion.g>
        <motion.g variants={fadeIn()} id="trees" mask="url(#mask-2)" fill="#12233D" fill-rule="nonzero">
        <path d="M1175.6234,1058.56 C1189.3634,1058.56 1200.5134,1045.44 1200.5134,1029.26 C1200.5134,1013.08 1189.3634,999.97 1175.6234,999.97 C1161.8734,999.97 1150.7234,1013.08 1150.7234,1029.26 C1150.7234,1045.44 1161.8734,1058.56 1175.6234,1058.56 Z" id="Path" fill="#12233D" fill-rule="nonzero" mask="url(#mask-2)"></path>
        <path d="M1126.7334,1029.56 C1140.4834,1029.56 1151.6234,1016.45 1151.6234,1000.27 C1151.6234,984.091 1140.4834,970.976 1126.7334,970.976 C1112.9834,970.976 1101.8334,984.091 1101.8334,1000.27 C1101.8334,1016.45 1112.9834,1029.56 1126.7334,1029.56 Z" id="Path" fill="#12233D" fill-rule="nonzero" mask="url(#mask-2)"></path>
        </motion.g>
        <motion.g variants={getSlideTopAnimation(Math.floor(Math.random() * 2) + 1.1)} id="block-10" mask="url(#mask-2)" fill-rule="nonzero">
            <g transform="translate(1370.073400, 729.232000)" id="Path">
                <polygon fill="#12233D" points="135.07 589.468 272.14 510.688 272.14 78.782 135.07 157.664"></polygon>
                <polygon fill="#2A3B56" points="1.13686838e-13 510.688 135.17 589.468 135.17 157.664 1.13686838e-13 78.882"></polygon>
                <polygon fill="#3A5070" points="0 78.882 135.17 157.664 272.14 78.782 136.87 0"></polygon>
                <polygon fill="#3A5070" points="118.07 169.661 15.7 110.075 15.7 120.072 118.07 179.659"></polygon>
                <polygon fill="#3A5070" points="118.07 195.055 15.7 135.369 15.7 145.367 118.07 204.953"></polygon>
                <polygon fill="#3A5070" points="118.07 220.349 15.7 160.763 15.7 170.661 118.07 230.347"></polygon>
                <polygon fill="#3A5070" points="118.07 245.744 15.7 186.057 15.7 196.055 118.07 255.641"></polygon>
                <polygon fill="#3A5070" points="118.07 271.038 15.7 211.451 15.7 221.349 118.07 281.038"></polygon>
                <polygon fill="#3A5070" points="118.07 296.328 15.7 236.746 15.7 246.743 118.07 306.328"></polygon>
                <polygon fill="#3A5070" points="118.07 321.728 15.7 262.14 15.7 272.038 118.07 331.628"></polygon>
                <polygon fill="#3A5070" points="118.07 347.018 15.7 287.438 15.7 297.428 118.07 357.018"></polygon>
                <polygon fill="#3A5070" points="118.07 372.418 15.7 312.728 15.7 322.728 118.07 382.308"></polygon>
                <polygon fill="#3A5070" points="118.07 397.708 15.7 338.118 15.7 348.018 118.07 407.708"></polygon>
                <polygon fill="#1D2F52" points="153.77 169.661 256.14 110.075 256.14 120.072 153.77 179.659"></polygon>
                <polygon fill="#1D2F52" points="153.77 195.055 256.14 135.369 256.14 145.367 153.77 204.953"></polygon>
                <polygon fill="#1D2F52" points="153.77 220.349 256.14 160.763 256.14 170.661 153.77 230.347"></polygon>
                <polygon fill="#1D2F52" points="153.77 245.744 256.14 186.057 256.14 196.055 153.77 255.641"></polygon>
                <polygon fill="#1D2F52" points="153.77 271.038 256.14 211.451 256.14 221.349 153.77 281.038"></polygon>
                <motion.polygon variants={lightsAnimation(getRandomDuration, getRandomDelay)} className="window" fill="#3A5070" points="153.77 296.328 256.14 236.746 256.14 246.743 153.77 306.328"></motion.polygon>
                <polygon fill="#1D2F52" points="153.77 321.728 256.14 262.14 256.14 272.038 153.77 331.628"></polygon>
                <polygon fill="#1D2F52" points="153.77 347.018 256.14 287.438 256.14 297.428 153.77 357.018"></polygon>
                <polygon fill="#1D2F52" points="153.77 372.418 256.14 312.728 256.14 322.728 153.77 382.308"></polygon>
                <polygon fill="#1D2F52" points="153.77 397.708 256.14 338.118 256.14 348.018 153.77 407.708"></polygon>
                <polygon fill="#12233D" points="229.45 78.982 136.77 24.994 43.99 78.482 136.77 132.469"></polygon>
                <polygon fill="#1D2F52" points="136.87 40.99 136.87 25.094 136.77 24.994 43.99 78.482 57.79 86.48"></polygon>
                <polygon fill="#12233D" points="136.77 40.99 215.65 86.88 229.45 78.982 136.77 24.994"></polygon>
                <polygon fill="#3A5070" points="58.39 86.08 136.17 131.469 215.05 86.08 137.17 40.69"></polygon>
            </g>
        </motion.g>
        <motion.g variants={getSlideTopAnimation(Math.floor(Math.random() * 2) + 1.1)} id="block-16" mask="url(#mask-2)" fill-rule="nonzero">
            <g transform="translate(400.695400, 968.277000)" id="Path">
                <polygon fill="#12233D" points="135.069 403.013 272.238 324.123 272.238 78.783 135.069 157.663"></polygon>
                <polygon fill="#2A3B56" points="2.84217094e-14 324.123 135.169 403.013 135.169 157.663 2.84217094e-14 78.883"></polygon>
                <polygon fill="#3A5070" points="2.84217094e-14 78.883 135.169 157.663 272.238 78.783 136.869 0"></polygon>
            </g>
        </motion.g>
    </motion.g>
      </motion.svg>
  )
}

export default Background;